//Departments and their projects
export const DEPT = {
    tcss: {
        name: 'Technical Support System',
        project: [
            {
                title: 'Telemetry Management',
                value: 'tlmm',
            },
            {
                title: 'Telemetry User Interface',
                value: 'tlui',
            },
            {
                title: 'BWSC Optimisation',
                value: 'bwsc',
            },
            {
                title: 'Performance Optimisation',
                value: 'pfop',
            },
            {
                title: 'Internal Tools',
                value: 'ittl',
            },
        ],
    },
    ebbs: {
        name: 'Embedded System',
        project: [
            {
                title: 'Embedded System',
                value: 'ebbs',
            },
        ],
    },
    egsy: {
        name: 'Energy System',
        project: [
            {
                title: 'Energy Management',
                value: 'egmg',
            },
            {
                title: 'Energy Source',
                value: 'egsc',
            },
            {
                title: 'Energy Storage',
                value: 'egst',
            },
        ],
    },
    intr: {
        name: 'Interior',
        project: [
            {
                title: 'Seats',
                value: 'seat',
            },
            {
                title: 'Steering Wheel',
                value: 'stwl',
            },
            {
                title: 'Pedals',
                value: 'pdls',
            },
            {
                title: 'Interior Fit Out',
                value: 'itfo',
            },
            {
                title: 'User Facing Software System',
                value: 'ufss',
            },
        ],
    },
    pwtr: {
        name: 'Power Train',
        project: [
            {
                title: 'Motors',
                value: 'mtor',
            },
            {
                title: 'Power Electronics',
                value: 'pwel',
            },
            {
                title: 'Transmission',
                value: 'trms',
            },
        ],
    },
    vhdy: {
        name: 'Vehicle Dynamics',
        project: [
            {
                title: 'Suspension',
                value: 'susp',
            },
            {
                title: 'Brakes',
                value: 'brke',
            },
            {
                title: 'Steering',
                value: 'strg',
            },
            {
                title: 'Wheels',
                value: 'whls',
            },
        ],
    },
    chbw: {
        name: 'Chassis And Bodywork',
        project: [
            {
                title: 'Chassis',
                value: 'chss',
            },
            {
                title: 'Bodywork',
                value: 'bdwk',
            },
            {
                title: 'Thermal Management',
                value: 'thmg',
            },
            {
                title: 'Exterior Features',
                value: 'exft',
            },
        ],
    },
    rmdr: {
        name: 'Remote Driving',
        project: [
        ],
    },
    othr: {
        name: 'Other',
        project: [
            {
                title: 'Other',
                value: 'othr',
            },
        ],
    },
}

//Academic Discipline
export const DISC = {
    MECH: { title: 'Mechanical' },
    ELEC: { title: 'Electrical' },
    SOLA: { title: 'Photovoltaics' },
    COMP: { title: 'Computer Science' },
    SYSE: { title: 'System Engineering' },
}

//All the known suppliers that Sunswift use
export const knownSuppliers = [
    { label: '3M' },
    { label: 'JayCar' },
    { label: '247Industries' },
]

export const REQUIREMENT_TYPE = {
    FUNC_REQ: {
        name: "Functional Requirement",
        options: [
            {code: "norm_op", title: "Normal Operation"},
            {code: "abnorm_op", title: "Abnormal Operation"},
            {code: "diagn_op", title: "Testing/Maintenance/Fault Diagnostic Operation"}
        ]
    },
    PERF_REQ: {
        name: "Performance Requirement",
        options: [
            {code: "team_spec", title: "Teamwide Specification"},
            {code: "tech_spec", title: "Technical Specification"},
            {code: "safe_spec", title: "Safety Specification"}
        ]
    },
    INTF_REQ: {
        name: "Interface Requirement",
        options: [
            {code: "sys_intf", title: "Project/System Interface"},
            {code: "mount_intf", title: "Mounting Points/Areas/Routing"}
        ]
    },
    RELB_REQ: {
        name: "Reliability Requirement",
        options: [
            {code: "relb_req", title: "Reliability Requirement"},
        ]
    },
    EXT_REQ: {
        name: "External Requirement",
        options: [
            {code: "adr_req", title: "ADRs"},
            {code: "iso_req", title: "ISOs"},
            {code: "unece_req", title: "UNECEs"},
            {code: "bwsc_reg", title: "BWSC Regulations"},
        ]
    }
}