import './App.css'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Vehicles from './Routes/Vehicles'
import Registration from './Routes/Registration'
import Login from './Routes/Login'
import PartManager from './Routes/PartManager'
import Finances from './Routes/Finances'
import Profile from './Routes/Profile'
import ProtectedRoute from './Components/ProtectedRoute'
import PartAssignment from './Routes/PartAssignment'
import {PartProcurement} from "./Routes/PartProcurement";
import {DepartmentProjects} from "./Routes/DepartmentProjects";
import {ProjectRequirement} from "./Routes/ProjectRequirement";
import {Requirements} from "./Routes/Requirements";
import { OrderManager } from './Routes/OrderManager'
import PatchNotes from "./Routes/PatchNotes";
import { ThemeProvider } from '@emotion/react'
import SunswiftTheme from './Theme/SunswiftTheme'

function App() {
  return (
    <ThemeProvider theme={SunswiftTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/Registration" element={<Registration />} />

          <Route
              path="/Profile"
              element={
                  <ProtectedRoute>
                      <Profile />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/Vehicles"
              element={
                  <ProtectedRoute>
                      <Vehicles />
                  </ProtectedRoute>
              }
          />

          <Route
              path="/Finances/:vehicle_id"
              element={
                  <ProtectedRoute>
                      <Finances />
                  </ProtectedRoute>
              }
          />

          <Route
            path="/DepartmentProjects/:vehicle_id"
            element={
                <ProtectedRoute>
                    <DepartmentProjects />
                </ProtectedRoute>
            }
          />
          <Route
              path="/PartManager/:vehicle_id"
              element={
                  <ProtectedRoute>
                      <PartManager />
                  </ProtectedRoute>
              }
          />
          <Route
              path="/PartAssignment/:vehicle_id"
              element={
                  <ProtectedRoute>
                      <PartAssignment />
                  </ProtectedRoute>
              }
          />
          <Route
            path="/PartProcurement/:vehicle_id"
            element={
              <ProtectedRoute>
                <PartProcurement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/OrderManager/:vehicle_id"
            element={
              <ProtectedRoute>
                <OrderManager />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ProjectRequirement/:vehicle_id/:project_id"
            element={
              <ProtectedRoute>
                <ProjectRequirement />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Requirements/:vehicle_id/:project_id/:requirement_id"
            element={
              <ProtectedRoute>
                <Requirements />
              </ProtectedRoute>
            }
          />

          <Route 
            path="/PatchNotes"
            element={
              <ProtectedRoute>
                <PatchNotes />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<Navigate to="Login" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
