import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import configData from "../../config.js"
import {
  Autocomplete,
  Button,
  Dialog, DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel, ListItemIcon, MenuItem, Select,
  TextField, Typography
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {verification_types} from "../../Assets/ProjectRequirementData";
// import {axiosInstance} from "../../Utility";


export function EditVerificationModal({verification, handleClose}) {
  const [open, setOpen] = useState(false)
  const [allMembers, setAllMembers] = useState(null)
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })

  const validationSchema = yup.object({
    type: yup
      .string('Verification type')
      .required('Verification type required'),
    verifying_engineer: yup.string('Enter verifying engineer zid').required('Verifying engineer required').matches(/^[0-9]+$/, "Enter without leading 'z'")
      .min(7, 'Must be exactly 7 digits')
      .max(7, 'Must be exactly 7 digits'),
    desc: yup
      .string('Verification description')
      .required('Verification description required'),
  })

  const handleUpdateVerification = async (values) => {
    console.log('editing verification:', verification)
    console.log("requirement id: ", verification.id)
    const verification_edit = {
      id: verification.id,
      type: values.type,
      engineer_id: values.verifying_engineer,
      description: values.desc,
    }
    // TODO do cleanup checks
    console.log("submitting req: ", verification_edit)
    axiosInstance
      .patch(`${configData.BACKEND_ROOT}/verification/${verification.id}`, verification_edit)
      .then((res) => {
        console.log("verification updated!")
        handleClose(true)
        setOpen(!open)
      })
      .catch((err) => {
        // TODO On error, set error from backend response
        console.log(err.response.data.detail)
      })
  }

  // TODO
  const formik = useFormik({
    initialValues: {type: verification.type, verifying_engineer: verification.engineer_id, desc: verification.description},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("submitting...")
      handleUpdateVerification(values)
    },
  })

  const getAllMembers = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/members`)
      .then(res => {
        const members = res.data.map(m => {
          return {label: m.firstname + ' ' + m.lastname + ' (z' + m.id + ')', id: m.id}
        })
        setAllMembers(members)
      })
  }

  useEffect(() => {
    if (open) {
      getAllMembers();
    }
  }, [open])



  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Typography variant="h6">Requirement</Typography>
              </Grid>
              <Grid item xs={12} >
                <Typography paddingTop="1rem" variant="h6">Requirement Verification</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Requirement Verification Type</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    label="Verification Type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.type &&
                      Boolean(formik.errors.type)
                    }
                    helperText={
                      formik.touched.type &&
                      formik.errors.type
                    }>
                    {verification_types.map(v=> {
                      return <MenuItem value={v}>{v}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                { allMembers &&
                  <Autocomplete
                    id="verifying_engineer"
                    name="verifying_engineer"
                    label="Verifying Engineer"
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id
                    }}
                    onChange={(event, value, reason, details) => formik.setFieldValue('verifying_engineer', value.id)}
                    options={allMembers}
                    defaultValue={() => {
                      return allMembers.find(m => m.id === verification.engineer_id)
                    }}
                    renderInput={(params) => <TextField {...params} label="Select a member"/>}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="desc"
                  name="desc"
                  label="Verification description"
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.desc &&
                    Boolean(formik.errors.desc)
                  }
                  helperText={
                    formik.touched.desc &&
                    formik.errors.desc
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Update verification
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <MenuItem onClick={() => {
        setOpen(!open)
      }}>
        <ListItemIcon>
          <Edit/>
        </ListItemIcon>
        Edit verification
    </MenuItem>
    </>
  )

}
