import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemIcon,
  MenuItem, TextField
} from "@mui/material";
import {Edit} from "@mui/icons-material";
import React, {useState} from "react";
import axios from "axios";
import configData from "../../config.js";
import * as yup from "yup";
import {useFormik} from "formik";
import {useParams} from "react-router-dom";
// import {axiosInstance} from "../../Utility";

export function EditKeyComponentModal({projectComponents, component, setUpdateProject, handleClose}) {
  const [open, setOpen] = useState(false)

  const validationSchema = yup.object({
    name: yup.string('Enter key component name')
      .required('Key component name required')
      .test('unique', 'Key component name must be unique',
        function(value) {
          return !projectComponents.find(p=>p.name === value) || value === component.name
        }),
  })
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  const formik = useFormik({
    initialValues: {name: component.name, proj_id: component.proj_id},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEditKeyComponent(values)
    },
  })
  const handleEditKeyComponent = (values) => {
    console.log('editing keyy comp with values: ', projectComponents)
    axiosInstance.patch(`${configData.BACKEND_ROOT}/keyComponent/${component.id}`, values)
      .then(res => {
        setUpdateProject(true)
        handleClose()
      })
      .catch(err => {
        console.log('Error while renaming key component: ', err)
      })
  }
  return (
    <>
      <Dialog open={open} onClose={()=> setOpen(!open)}>
      <DialogTitle>
        Rename key component
      </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Key Component Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={
                formik.touched.name &&
                Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name &&
                formik.errors.name
              }
            />
          </Grid>
          <DialogActions>
            <Button variant="contained" type="submit">
              Save changes
            </Button>
            <Button variant="contained" onClick={() => {
              setOpen(!open)
              handleClose()
            }}>
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
        </form>
      </Dialog>
      <MenuItem disabled={component.name === 'Overall'} onClick={() => {
        setOpen(!open)
      }}>
        <ListItemIcon>
          <Edit/>
        </ListItemIcon>
        Rename key component
      </MenuItem>
    </>
  )
}