import React from 'react'
import { Box } from '@mui/system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardHeader, Avatar, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import defaultProfilePic from '../Assets/profile.png'
import configData from "../config.js";
import axios from "axios";
import { DEPT, DISC } from '../Constants.jsx'
import BadgeIcon from '@mui/icons-material/Badge';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const MemberField = ({ label, text, children }) => {
  return (
    <ListItem className='!p-0 my-[16px]'>
      <ListItemIcon className='!min-w-fit mr-[24px]'>
        {children}
      </ListItemIcon>
      <Box>
        <Typography className='!text-[0.85rem] !text-neutral-500'>
          {label}
        </Typography>
        <Typography className='!text-[1.25rem]'>
          {text}
        </Typography>
      </Box>
    </ListItem>
  )
}

const MemberCard = ({ label, zid }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [memberName, setMemberName] = React.useState();
  const [memberDpt, setMemberDpt] = React.useState();
  const [memberDisc, setMemberDisc] = React.useState();
  const [memberStart, setMemberStart] = React.useState();
  const [memberEmail, setMemberEmail] = React.useState();
  const [engineerData, setEngineerData] = React.useState()
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getEngineerDetails = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/members/${zid}`).then(res => {
      setEngineerData(res.data)
      setFields(res.data)
      console.log(res.data)
    }).catch(err=> console.log('Error getting engineer data: ', err))
  }

  function setFields(data) {
    setMemberName(`${data.firstname} ${data.lastname}`)
    setMemberDpt(DEPT[data.dept] ? DEPT[data.dept].name : data.dept)
    setMemberDisc(DISC[data.acadDisc] ? DISC[data.acadDisc].title : data.acadDisc)
    setMemberEmail(data.email)
    setMemberStart(`${data.startYear} T${data.startTerm}`)
  }

  React.useEffect(() => {
    getEngineerDetails()
  }, [zid])

  return (
    <Box className='flex items-center gap-2'>
      <Typography className='max-w-fit'>{label}</Typography>
      <InfoOutlinedIcon className="cursor-pointer"
        onClick={() => console.log(zid)}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Card
            className='cursor-pointer min-w-[350px] max-w-[345px]'
        >
          <CardHeader 
            sx={{ backgroundColor: "#ffd500"}}
            titleTypographyProps={{color: 'black', fontSize: '1.25rem'}}
            title={memberName}
            subheader={memberEmail}
            avatar={<Avatar alt="default profile" src={defaultProfilePic}/>}
          />
          <CardContent className='!py-[5px] !px-[20px]'>
            <Box>
              <MemberField label='Department' text={memberDpt}>
                <WorkIcon className='!w-[30px] !h-[30px]'/>
              </MemberField>
              <MemberField label='Discipline' text={memberDisc}>
                <SchoolIcon className='!w-[30px] !h-[30px]'/>
              </MemberField>
              <MemberField label='Start Date' text={memberStart}>
                <CalendarTodayIcon className='!w-[30px] !h-[30px]'/>
              </MemberField>
            </Box>
          </CardContent>
        </Card>
      </Popover>
    </Box>
  )
}

export default MemberCard