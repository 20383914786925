import PageDrawer from '../Components/PageDrawer'
import CenterContainer from '../Components/CenterContainer'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    Grid,
    Menu,
    Paper,
    TextField,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import configData from '../config.js'
import { CreateRequirementModal } from '../Components/ProjectRequirementModals/CreateRequirementModal'
import { projectRequirementColumns } from '../Assets/ProjectRequirementData'
import { CreateKeyComponentModal } from '../Components/ProjectRequirementModals/CreateKeyComponentModal'
import { ArchiveProjectModal } from '../Components/ProjectRequirementModals/ArchiveProjectModal'
import { DeleteProjectModal } from '../Components/ProjectRequirementModals/DeleteProjectModal'
import { EditProjectModal } from '../Components/ProjectRequirementModals/EditProjectModal'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {Inventory, KeyboardArrowDown} from '@mui/icons-material'
import { EditKeyComponentModal } from '../Components/ProjectRequirementModals/EditKeyComponentModal'
import { checkPerms, editAccess, deleteAccess, projectAccess } from '../Utility'

const ProjectActions = ({
    anchorEl,
    setAnchorEl,
    project,
    setUpdateProject,
}) => {
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <Menu
            id='action-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <ArchiveProjectModal
                project={project}
                handleClose={handleClose}
                setUpdateProject={setUpdateProject}
            />
            {(checkPerms(editAccess.concat(projectAccess)) && !project.archived) && (
                <EditProjectModal
                    project={project}
                    handleClose={handleClose}
                    setUpdateProject={setUpdateProject}
                />
            )}
            {(checkPerms(deleteAccess) && !project.archived)  && (
                <DeleteProjectModal
                    proj_name={project.name}
                    proj_id={project.id}
                    handleClose={handleClose}
                />
            )}
        </Menu>
    )
}

const KeyComponentActions = ({
    anchorEl,
    setAnchorEl,
    projectComponents,
    component,
    setUpdateProject,
}) => {
    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <Menu
            id='kc-action-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <EditKeyComponentModal
                projectComponents={projectComponents}
                component={component}
                setUpdateProject={setUpdateProject}
                handleClose={handleClose}
            />
        </Menu>
    )
}

export function ProjectRequirement () {
    const navigate = useNavigate()
    const { vehicle_id, project_id } = useParams()
    const [projectData, setProjectData] = useState(null)
    const [projectComponents, setProjectComponents] = useState([])
    const [projectRequirements, setProjectRequirements] = useState(null)
    const [filteredRequirements, setFilteredRequirements] = useState(null)
    const [actionMenuAnchor, setActionMenuAnchor] = useState(null)
    const [keyCompActionMenuAnchor, setKeyCompActionMenuAnchor] = useState(null)
    const [currentKeyComp, setCurrentKeyComp] = useState({})
    const [updateProject, setUpdateProject] = useState(false)
    const [updateRequirements, setUpdateRequirements] = useState(false)
    const [keyComponentFilter, setKeyComponentFilter] = useState(false)
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const getDepartmentDetails = () => {
        axiosInstance
            .get(`${configData.BACKEND_ROOT}/department/${projectData.dept_id}`)
            .then(res => {
                setProjectData({ ...projectData, department: res.data.name })
            })
            .catch(err => {
                console.log('Error getting project department: ', err)
            })
    }
    const getProjectDetails = () => {
        axiosInstance
            .get(`${configData.BACKEND_ROOT}/projects/${project_id}`)
            .then(res => {
                console.log('updating with: ', res.data)
                setProjectData(res.data)
            })
            .catch(err => {
                console.log('Error while getting proj details: ', err)
            })
    }
    const getProjectComponents = () => {
        axiosInstance
            .get(
                `${configData.BACKEND_ROOT}/keyComponents/project/${project_id}`
            )
            .then(res => {
                // TODO check if we need mapping
                setProjectComponents(res.data)
            })
            .catch(err => {
                // TODO set up error alert
                console.log('Error getting proj components: ', err)
            })
    }

    const getProjectRequirements = async () => {
        try {
          const res = await axiosInstance.get(`${configData.BACKEND_ROOT}/requirements/project/${project_id}`);
          let pname = null
          const data = res.data.map((req, idx) => {
            const project_components = req.keyComponents.map(kc => {
              if (pname == null) {
                const proj = req.projects.find(p => p.id === kc.proj_id);
                pname = proj ? proj.name : null
              }
              return {
                proj_name: pname ? pname : 'Undefined',
                proj_id: kc.proj_id,
                component: kc.name,
              };
            });
            const pcomp = project_components.find(pc => pc.proj_id === project_id)

            return {
              id: idx,
              name: req.name,
              number: req.id,
              type: req.type,
              project_components: project_components,
              current_project: project_id,
              current_component: pcomp ? pcomp.component : 'Undefined',
              projects: req.projects,
              status: req.verification.status,
            };
          });
          
          console.log('Mapped project requirements: ', data);
          setProjectRequirements(data);
        } catch (err) {
          // TODO: Set up error alert
          console.log('Error getting requirements: ', err);
        }
      };


    useEffect(() => {
        getProjectDetails()
        getProjectComponents()
        getProjectRequirements()
    }, [])

    useEffect(() => {
        if (projectData && updateProject) {
            getDepartmentDetails()
            setUpdateProject(false)
        } else if (projectData) {
            if (!('department' in projectData)) {
                getDepartmentDetails()
            }
        }
    }, [projectData])

    useEffect(() => {
        if (updateProject) {
            getProjectDetails()
            getProjectComponents()
            setUpdateProject(false)
        }
        if (updateRequirements) {
            console.log('Refreshing project requirements...')
            getProjectRequirements()
            setUpdateRequirements(false)
        }
    }, [updateProject, updateRequirements])

    useEffect(() => {
      if (!keyComponentFilter) {
        setCurrentKeyComp({})
      }
    }, [keyComponentFilter])

    useEffect(() => {
      if ("name" in currentKeyComp){
          console.log("rf:wegtb")
          setFilteredRequirements(projectRequirements.filter(req => req.current_component === currentKeyComp.name))
        }
      else {
        setFilteredRequirements(projectRequirements)
      }
    }, [currentKeyComp, projectRequirements])

    const handleProjectActions = event => {
        setActionMenuAnchor(event.currentTarget)
    }

    const handleKeyCompActions = event => {
        setKeyCompActionMenuAnchor(event.currentTarget)
    }

    const handleRowClick = params => {
        console.log('clicked on: ', params.row.number)
        navigate(
            `/Requirements/${vehicle_id}/${project_id}/${params.row.number}`
        )
    }

    return (
        <>
            {projectData && filteredRequirements ? (
                <PageDrawer
                    page={
                        projectData.department +
                        ' >> ' +
                        projectData.name +
                        ' (' +
                        projectData.id +
                        ')'
                    }
                    opTool
                    opLogin
                    id={vehicle_id}
                >
                    <CenterContainer size='xl'>
                        <Paper
                            elevation={8}
                            sx={{ width: '100%', margin: '1em auto' }}
                        >
                            <Box padding='1rem'>
                                <Grid
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    flexDirection='row'
                                    sx={{ fontSize: '12px' }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Grid container item flexGrow={1} flexDirection="column" spacing={2}>
                                            <Grid item container flexDirection="row" alignItems="center">
                                            {projectData.archived && <Grid item paddingRight="1em">
                                                <Inventory/>
                                            </Grid>}
                                            <Grid item>
                                                <Typography variant="h5" align="left">
                                                {projectData.name + ' (' + projectData.id + ')'}
                                                </Typography>
                                            </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2" align="left">
                                                {projectData.description}
                                                </Typography>
                                            </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            columnGap={2}
                                            xs={6}
                                            justifyContent='flex-end'
                                        >
                                            <Grid>
                                                <Button
                                                    variant='contained'
                                                    onClick={handleProjectActions}
                                                    endIcon={<KeyboardArrowDown />}
                                                >
                                                    Actions
                                                </Button>
                                            </Grid>
                                            <ProjectActions
                                                anchorEl={actionMenuAnchor}
                                                setAnchorEl={setActionMenuAnchor}
                                                project={projectData}
                                                projectComponents={
                                                    projectComponents
                                                }
                                                setUpdateProject={setUpdateProject}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
        
                            <Box padding='1rem'>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent='space-between'
                                    alignItems='center'
                                    flexDirection='row'
                                    sx={{ fontSize: '12px' }}
                                >
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            options={projectRequirements.map(
                                                req =>
                                                    req.name +
                                                    ' (' +
                                                    req.number +
                                                    ')'
                                            )}
                                            fullWidth
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label='Find requirement'
                                                    defaultValue=''
                                                />
                                            )}
                                            onChange={(_, value) => {
                                                const regexp = /\((.*?)\)/
                                                const req_id =
                                                    value.match(regexp)[1]
                                                navigate(
                                                    `/Requirements/${vehicle_id}/${project_id}/${req_id}`
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        columnGap={2}
                                        justifyContent='flex-end'
                                    >
                                        <Grid>
                                            <CreateKeyComponentModal
                                                proj_name={projectData.name}
                                                proj_id={projectData.id}
                                                keyComponents={
                                                    projectComponents
                                                }
                                                update={() => {
                                                    getProjectComponents()
                                                }}
                                                disabled={projectData.archived}
                                            />
                                        </Grid>
                                        <Grid>
                                            <CreateRequirementModal
                                                projectData={projectData}
                                                setUpdateRequirements={
                                                    setUpdateRequirements
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems='center'
                                    columnGap={2}
                                    paddingTop='1em'
                                >
                                    Project components:{' '}
                                    {projectComponents &&
                                        projectComponents.map(pc => {
                                            return (
                                                <Chip
                                                color={currentKeyComp === pc ? "primary" :  "default"}
                                                    label={pc.name}
                                                    onClick={event => {
                                                    if (currentKeyComp === pc) {
                                                        setKeyComponentFilter(false)
                                                        setCurrentKeyComp({})
                                                    } else {
                                                        setKeyComponentFilter(true)
                                                        setCurrentKeyComp(pc)
                                                    }
                                                    }}
                                                    onContextMenu={event => {
                                                    event.preventDefault()
                                                        setCurrentKeyComp(pc)
                                                        handleKeyCompActions(
                                                            event
                                                        )
                                                    }}
                                                />
                                            )
                                        })}
                                </Grid>
                                {checkPerms(
                                    editAccess.concat(projectAccess)
                                ) && (
                                    <KeyComponentActions
                                        anchorEl={keyCompActionMenuAnchor}
                                        setAnchorEl={setKeyCompActionMenuAnchor}
                                        projectComponents={projectComponents}
                                        setUpdateProject={setUpdateProject}
                                        component={currentKeyComp}
                                    />
                                )}
                            </Box>
                            <DataGrid
                                onRowClick={handleRowClick}
                                rows={filteredRequirements}
                                columns={projectRequirementColumns}
                                autoHeight
                                getRowHeight={() => 'auto'}
                                density='compact'
                                disableSelectionOnClick
                                disableColumnMenu
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                hideFooterSelectedRowCount
                                disableExtendRowFullWidth
                                className='datagrid cursor-pointer'
                                classes={{
                                    cell: 'datagrid-cell',
                                }}
                                initialState={{
                                    sorting: {
                                        sortModel: [
                                            {
                                                field: 'component',
                                                sort: 'desc',
                                            },
                                        ],
                                    },
                                }}
                            />
                        </Paper>
                    </CenterContainer>
                </PageDrawer>
            ) : (
                <CenterContainer>
                    <Box
                        sx={{ display: 'flex' }}
                        alignSelf='center'
                        justifySelf='center'
                    >
                        <CircularProgress />
                    </Box>
                </CenterContainer>
            )}
        </>
    )
}
