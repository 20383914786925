/**
 * CreateProcurementModals.jsx
 *
 * Modal for creating a new part procurment process
 *
 * Parameters:
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */


// this file not needed (anish)

import React, { useState } from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    MenuItem,
    Alert,
    Grid,
    Divider,
    FormControl,
    InputLabel,
    Select,
} from '@mui/material'

export const CreateProcurementModal = ({
    open,
    closeFunc,
    vehicle_id,
    state,
    stateUpdate,
    setError,
    existPartId,
}) => {
    const validationSchema = yup.object({
        part_name: yup.string('Enter part name').required('* Required Field'),
        part_number: yup
            .string('Enter part number')
            .required('* Required Field'),
        part_desc: yup
            .string('Enter part description')
            .required('* Required Field'),
        bom_type: yup.string('Enter BOM Type').required('* Required Field'),
        qty_to_order: yup
            .number('Enter quantity to order')
            .required('* Required Field')
            .integer('Must be a valid positive integer')
            .min(1, 'Must be a valid positive integer'),
        total_cost: yup
            .number('Enter Total Cost')
            .required('* Required Field')
            .min(0.01, 'Must exceed $0'),
        qty_type: yup
            .string('Enter quantity type')
            .required('* Required Field'),
        unit_per_pack: yup.number('Total units per pack'),
        proc_comments: yup
            .string('Enter brief explanation of what procurement is for')
            .required('* Required Field'),
    })
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    //Calculates the cost per unit for submission, written to be extendable
    const get_cost_per_unit = () => {
        if (formik.values.qty_type === 'Single Unit') {
            return formik.values.total_cost / formik.values.qty_to_order
        } else if (formik.values.qty_type === 'Packs') {
            return (
                formik.values.total_cost /
                (formik.values.qty_to_order * formik.values.unit_per_pack)
            )
        }
    }


    // Check if part number is already in the system to avoid creating duplicate copy
    const checkPartNumber = async (partNumber) => {
        await axiosInstance.get(`${configData.BACKEND_ROOT}/procurement`)
          .then(res => {
            //   console.log('res from get all is: ', res)
            //   console.log('res data: ', res.data)
          })
          .catch(err => {
              setError(err.response.data.detail)
              console.log('error caught while checking part number before new insert: ', err)
          })
    }

    const get_total_unit = () => {
        if (formik.values.qty_type === 'Single Unit') {
            return formik.values.qty_to_order
        } else if (formik.values.qty_type === 'Packs') {
            return (
                (formik.values.qty_to_order * formik.values.unit_per_pack)
            )
        }
    }


    //Handle adding procurement request
    const handleCreateProcurement = async (values) => {

        const part_details = {
                name: values.part_name,
                desc: values.part_desc,
                bom_type: values.bom_type,
                source: 'External',
                drawing: 'None',
                revision: 0,
                stock_qty: get_total_unit(),
                loc_id: 'None',
                matr_id: 'None',
                supp_id: 'None',
                vehicle_id: vehicle_id,
                cost_per_unit: get_cost_per_unit(),
                id: values.part_number,
            }

        const new_proc = {
            requester_zid: parseInt(sessionStorage.getItem('curr_zid')),
            qty_requested: get_total_unit(),
            date_created: new Date().toUTCString(),
            date_changed: new Date().toUTCString(),
            status: 'To Order',
            comments: values.proc_comments,
            part_details: part_details,
        }

        // Check whether part with same number is in system
        await checkPartNumber(values.part_number)

        axiosInstance
            .post(`${configData.BACKEND_ROOT}/procurement`, new_proc)
            .then((res) => {
                stateUpdate([...state, res.data])
                setError('')
                closeFunc()
            })
            .catch((err) => {
                //On error, set error from backend response
                setError(err.response.data.detail)
            })
        closeFunc();



    }

    const[initData] = useState({
      part_name: '',
      part_number: '',
      part_desc: '',
      bom_type: 'Installation',
      qty_to_order: 0,
      total_cost: 0,
      qty_type: 'Single Unit',
      unit_per_pack: 0,
      proc_comments: '',
      order_id: null
    })

    const formik = useFormik({
        initialValues: initData,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleCreateProcurement(values)
        },
    })


    return (
        <>
            <Dialog
                open={open}
                onClose={closeFunc}
                fullWidth
                sx={{ padding: '1em' }}
            >

                <DialogTitle>New Part Procurement Request </DialogTitle>
                <Divider />
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please enter part details:
                        </DialogContentText>
                        <Grid container spacing={2} sx={{paddingTop: '32px'}}>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="part_name"
                                    name="part_name"
                                    label="Part Name"
                                    value={formik.values.part_name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.part_name &&
                                        Boolean(formik.errors.part_name)
                                    }
                                    helperText={
                                        formik.touched.part_name &&
                                        formik.errors.part_name
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="part_number"
                                    name="part_number"
                                    label="Part Number"
                                    value={formik.values.part_number}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.part_number &&
                                        Boolean(formik.errors.part_number)
                                    }
                                    helperText={
                                        formik.touched.part_number &&
                                        formik.errors.part_number
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="part_desc"
                                    name="part_desc"
                                    label="Part Description"
                                    value={formik.values.part_desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.part_desc &&
                                        Boolean(formik.errors.part_desc)
                                    }
                                    helperText={
                                        formik.touched.part_desc &&
                                        formik.errors.part_desc
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>BOM Tree</InputLabel>
                                    <Select
                                        id="bom_type"
                                        name="bom_type"
                                        label="BOM Type"
                                        value={formik.values.bom_type}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.bom_type &&
                                            Boolean(formik.errors.bom_type)
                                        }
                                        helperText={
                                            formik.touched.bom_type &&
                                            formik.errors.bom_type
                                        }
                                    >
                                        <MenuItem value={'Installation'}>
                                            Installation
                                        </MenuItem>
                                        <MenuItem value={'Assembly'}>
                                            Assembly
                                        </MenuItem>
                                        <MenuItem value={'Component'}>
                                            Component
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                                <DialogContentText sx={{paddingTop: '16px'}}>
                                    Please enter procurement details:
                                </DialogContentText>
                            </Grid>
                            <Grid item spacing = {12}>
                                <Alert severity="info">Known bug: If you cannot edit the following fields, just leave the form and come back to it.</Alert>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="qty_to_order"
                                    name="qty_to_order"
                                    label="Quantity to order"
                                    type="number"
                                    value={formik.values.qty_to_order}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.qty_to_order &&
                                        Boolean(formik.errors.qty_to_order)
                                    }
                                    helperText={
                                        formik.touched.qty_to_order &&
                                        formik.errors.qty_to_order
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="total_cost"
                                    name="total_cost"
                                    type="number"
                                    label={formik.values.qty_type === "Single Unit" ? "Individual Unit Cost ($)" : "Batch Unit Cost ($)"}
                                    value={formik.values.total_cost}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.qty &&
                                        Boolean(formik.errors.total_cost)
                                    }
                                    helperText={
                                        formik.touched.total_cost &&
                                        formik.errors.total_cost
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Quantity Type</InputLabel>
                                    <Select
                                        id="qty_type"
                                        name="qty_type"
                                        label="Quantity Type"
                                        value={formik.values.qty_type}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.qty_type &&
                                            Boolean(formik.errors.qty_type)
                                        }
                                        helperText={
                                            formik.touched.qty_type &&
                                            formik.errors.qty_type
                                        }
                                    >
                                        <MenuItem value={'Single Unit'}>
                                            Single Unit
                                        </MenuItem>
                                        <MenuItem value={'Packs'}>
                                            Packs
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    disabled={
                                        formik.values.qty_type === 'Single Unit'
                                    }
                                    id="unit_per_pack"
                                    name="unit_per_pack"
                                    label="Units per pack"
                                    type="number"
                                    value={
                                        formik.values.qty_type === 'Packs'
                                            ? formik.values.unit_per_pack
                                            : 0
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.qty &&
                                        Boolean(formik.errors.unit_per_pack)
                                    }
                                    helperText={
                                        formik.touched.unit_per_pack &&
                                        formik.errors.unit_per_pack
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={10}
                                    id="proc_comments"
                                    name="proc_comments"
                                    label="Procurement Comments"
                                    value={formik.values.proc_comments}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.proc_comments &&
                                        Boolean(formik.errors.proc_comments)
                                    }
                                    helperText={
                                        formik.touched.proc_comments &&
                                        formik.errors.proc_comments
                                    }
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={closeFunc}>
                            Submit new request
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
