/**
 * CreateOrderModals.jsx
 *
 * Modal for creating a new procurement order process
 *
 * Parameters:
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    MenuItem,
    Grid,
    Select,
    Switch,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableFooter,
    IconButton,
    FormControlLabel,
} from '@mui/material'

import { BiHide } from 'react-icons/bi'
import { IoIosRemove } from 'react-icons/io'
import DeleteIcon from '@mui/icons-material/Delete'

export const CreateOrderModal = ({
    open,
    closeFunc,
    vehicle_id,
    state,
    setSelectedDisplayData,
    setError,
    existPartId,
}) => {
    const validationSchema = yup.object({
        order_name: yup
            .string('Enter order name')
            .required('Order name required'),
        order_number: yup
            .string('Enter order number')
            .required('Order number required'),
        est_delivery: yup.string('Enter estimated date of delivery'),
        parts: yup
            .array()
            .of(
                yup.object().shape({
                    part_name: yup
                        .string('Enter part name')
                        .required('* Required Field'),
                    part_number: yup
                        .string('Enter part number')
                        .required('* Required Field'),
                    part_desc: yup
                        .string('Enter part description')
                        .required('* Required Field'),
                    bom_type: yup
                        .string('Enter BOM Type')
                        .required('BOM Type required'),
                    qty_to_order: yup
                        .number('Enter quantity to order')
                        .required('* Required Field')
                        .integer('Must be a valid positive integer')
                        .min(1, 'Must be a valid positive integer'),
                    total_cost: yup
                        .number('Enter Total Cost')
                        .required('* Required Field')
                        .min(0.01, 'Must exceed $0'),
                    qty_type: yup
                        .string('Enter quantity type')
                        .required('* Required Field'),
                    unit_per_pack: yup.number('Total units per pack'),
                    proc_comments: yup
                        .string(
                            'Enter brief explanation of what procurement is for'
                        )
                        .required('* Required Field'),
                })
            )
            .min(1, 'At least one procurement must be added'),
    })
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    // //Calculates the cost per unit for submission, written to be extendable
    // const get_cost_per_unit = (
    //     qty_type,
    //     total_cost,
    //     qty_to_order,
    //     unit_per_pack
    // ) => {
    //     if (qty_type === 'Single Unit') {
    //         return total_cost / qty_to_order
    //     } else if (qty_type === 'Packs') {
    //         return total_cost / (qty_to_order * unit_per_pack)
    //     }
    // }

    const [totalCost, setTotalCost] = useState(0)

    //Calculates the total order cost, written to be extendable
    const get_total_cost = () => {
        console.log("PASS")
        let tc = 0
        for (const part of formik.values.parts) {
            if (
                parseInt(part.qty_to_order) === 0 ||
                parseFloat(part.total_cost) === 0
            )
                continue
            tc += parseFloat(part.total_cost)
        }
        return parseFloat(tc).toFixed(2)
    }

    // const get_total_unit = (qty_type, qty_to_order, unit_per_pack) => {
    //     if (qty_type === 'Single Unit') {
    //         return qty_to_order
    //     } else if (qty_type === 'Packs') {
    //         return qty_to_order * unit_per_pack
    //     }
    // }

    //Handle adding procurement request
    const handleCreateOrder = async (values) => {
        try {
            // update all selected procurements "On order"
            const proc_ids = []
            for (const procurement of state) {
                await axiosInstance.patch(
                    `${configData.BACKEND_ROOT}/procurement/status/${procurement.id}`,
                    { status: 'On Order' }
                )
                proc_ids.push(procurement.id)
            }
            // associate each procuremnt with an orderid
            const new_order = {
                number: values.order_number,
                name: values.order_name,
                cost: get_total_cost(),
                shipping_cost: get_total_cost(),
                est_delivery: values.est_delivery,
            }

            console.log(new_order)
            let orderResponse = await axiosInstance.post(
                `${configData.BACKEND_ROOT}/orders`,
                new_order
            )
            console.log(orderResponse)
            const order_proc_details = {
                order_id: orderResponse.data.id,
                proc_ids: proc_ids,
            }

            await axiosInstance.put(
                `${configData.BACKEND_ROOT}/order/add/procurements`,
                order_proc_details
            )
            setSelectedDisplayData([])
            closeFunc()
        } catch (err) {
            console.log(err)
            setError(err.response.data.detail)
        }
    }

    const [initData] = useState({
        order_name: '',
        order_number: '',
        est_delivery: new Date().toISOString().split('T')[0],
        parts: [state.map(item => item.part_details)],
    })

    const removePart = (index) => {
        if (formik.values.parts.length === 1) {
            removeAllParts()
            return
        }
        const updatedParts = [...formik.values.parts]
        updatedParts.splice(index, 1)
        formik.setFieldValue('parts', updatedParts)
    }

    const removeAllParts = (index) => {
        formik.setFieldValue('parts', [])
    }

    const handlePartChange = (e, index) => {
        const { name, value } = e.target
        formik.setFieldValue(`parts[${index}].${name}`, value)
    }

    const formik = useFormik({
        initialValues: initData,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleCreateOrder(values)
        },
    })

    const [dense, setDense] = React.useState(false)
    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const handleCancelOrder = () => {
        closeFunc()
        formik.resetForm()
    }

    useEffect(() => {
        if (open === true) {
            const parts = [];
            for (const procurement of state) {
                const detail = procurement.part_details;
                const new_part = {
                    part_name: detail.name,
                    part_number: detail.id,
                    part_desc: detail.desc,
                    bom_type: detail.bom_type,
                    qty_to_order: procurement.qty_requested,
                    total_cost: detail.cost_per_unit * procurement.qty_requested,
                    qty_type: null,
                    unit_per_pack: 0,
                    proc_comments: procurement.comments,
                    order_placed: true,
                    procurement_id: procurement.id,
                };
                parts.push(new_part);
            }
            formik.setFieldValue('parts', parts);

            // get total cost
            let tc = 0
            for (const part of parts) {
                if (
                    parseInt(part.qty_to_order) === 0 ||
                    parseFloat(part.total_cost) === 0
                )
                    continue
                tc += parseFloat(part.total_cost)
            }
            setTotalCost(parseFloat(tc).toFixed(2))


        }
    }, [open])


    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullScreen>
                <DialogTitle>
                    New Procurement Order Request
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={closeFunc}
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: '20px',
                            top: '10px',
                        }}
                    >
                        <BiHide />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please enter order details:
                        </DialogContentText>
                        <Grid container spacing={2} sx={{ paddingTop: '32px' }}>
                            <Grid item xs={6} sm={2}>
                                <TextField
                                    fullWidth
                                    id="order_name"
                                    name="order_name"
                                    label="Order Name"
                                    value={formik.values.order_name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.order_name &&
                                        Boolean(formik.errors.order_name)
                                    }
                                    helperText={
                                        formik.touched.order_name &&
                                        formik.errors.order_name
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField
                                    fullWidth
                                    id="order_number"
                                    name="order_number"
                                    label="Order Number"
                                    value={formik.values.order_number}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.order_number &&
                                        Boolean(formik.errors.order_number)
                                    }
                                    helperText={
                                        formik.touched.order_number &&
                                        formik.errors.order_number
                                    }
                                />
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField
                                    fullWidth
                                    id="est_delivery"
                                    name="est_delivery"
                                    label="Estimated Delivery Date"
                                    type="date"
                                    value={formik.values.est_delivery}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.est_delivery &&
                                        Boolean(formik.errors.est_delivery)
                                    }
                                    helperText={
                                        formik.touched.est_delivery &&
                                        formik.errors.est_delivery
                                    }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ paddingTop: '32px' }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table
                                        size={dense ? 'small' : 'medium'}
                                        style={{ borderCollapse: 'collapse' }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Name
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Number
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Description
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    BOM Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Quantity to Order
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Total Cost ($)
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Quantity Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Units per Pack
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Procurement Comments
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    {formik.values.parts
                                                        .length > 0 && (
                                                        <IconButton
                                                            onClick={() =>
                                                                removeAllParts()
                                                            }
                                                            aria-label="delete"
                                                            color="primary"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formik.values.parts.map(
                                                (part, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`part_name`}
                                                                name={`part_name`}
                                                                value={
                                                                    part.part_name
                                                                }
                                                                placeholder="Part Name"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_name
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]?.part_name
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`part_number`}
                                                                name={`part_number`}
                                                                value={
                                                                    part.part_number
                                                                }
                                                                placeholder="Part Number"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_number
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.part_number
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                multiline
                                                                id={`part_desc`}
                                                                name={`part_desc`}
                                                                value={
                                                                    part.part_desc
                                                                }
                                                                placeholder="Part Description"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_desc
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]?.part_desc
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                multiline
                                                                id={`bom_type`}
                                                                name={`bom_type`}
                                                                value={
                                                                    part.bom_type
                                                                }
                                                                placeholder="BOM type"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.bom_type
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]?.bom_type
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`qty_to_order`}
                                                                name={`qty_to_order`}
                                                                type="number"
                                                                value={
                                                                    part.qty_to_order
                                                                }
                                                                placeholder="Quantity to Order"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.qty_to_order
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.qty_to_order
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`total_cost`}
                                                                name={`total_cost`}
                                                                type="number"
                                                                value={
                                                                    part.total_cost
                                                                }
                                                                placeholder={
                                                                    part.qty_type ===
                                                                    'Single Unit'
                                                                        ? 'Unit Cost ($)'
                                                                        : 'Batch Cost ($)'
                                                                }
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                onBlur={() =>
                                                                    setTotalCost(
                                                                        get_total_cost()
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.total_cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.total_cost
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                            >
                                                                <Select
                                                                    id={`qty_type`}
                                                                    name={`qty_type`}
                                                                    value={
                                                                        part.qty_type
                                                                    }
                                                                    placeholder="Quantity Type"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handlePartChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    error={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        Boolean(
                                                                            formik
                                                                                .errors
                                                                                .parts &&
                                                                                formik
                                                                                    .errors
                                                                                    .parts[
                                                                                    index
                                                                                ]
                                                                                    ?.qty_type
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                        formik
                                                                            .errors
                                                                            .parts[
                                                                            index
                                                                        ]
                                                                            ?.qty_type
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            '120px',
                                                                        // pointerEvents:
                                                                        //     part.order_placed
                                                                        //         ? 'auto'
                                                                        //         : 'auto',
                                                                        // color: part.order_placed
                                                                        //     ? '#000'
                                                                        //     : '#000', // Set desired text color for disabled state
                                                                    }}
                                                                >
                                                                    <MenuItem value="Single Unit">
                                                                        Single
                                                                        Unit
                                                                    </MenuItem>
                                                                    <MenuItem value="Packs">
                                                                        Packs
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                disabled={
                                                                    part.qty_type ===
                                                                    'Single Unit'
                                                                }
                                                                type="number"
                                                                id={`unit_per_pack`}
                                                                name={`unit_per_pack`}
                                                                value={
                                                                    part.unit_per_pack
                                                                }
                                                                placeholder="Units per pack"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.unit_per_pack
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.unit_per_pack
                                                                }
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    readOnly:
                                                                        part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                disabled
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`proc_comments`}
                                                                name={`proc_comments`}
                                                                value={
                                                                    part.proc_comments
                                                                }
                                                                placeholder="Comments"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.proc_comments
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.proc_comments
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={() =>
                                                                    removePart(
                                                                        index
                                                                    )
                                                                }
                                                                aria-label="delete"
                                                            >
                                                                <IoIosRemove />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={1}
                                                    align="left"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={dense}
                                                                onChange={
                                                                    handleChangeDense
                                                                }
                                                            />
                                                        }
                                                        label="Dense padding"
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    colSpan={12}
                                                    align="right"
                                                >
                                                    <b>
                                                        Total
                                                        Cost:&nbsp;&nbsp;&nbsp;$
                                                        {totalCost}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions
                        sx={{ marginLeft: 'auto', marginRight: '16px' }}
                    >
                        <Button variant="contained" type="submit">
                            Submit new request
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCancelOrder}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
