import PageDrawer from "../Components/PageDrawer";
import CenterContainer from "../Components/CenterContainer";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Divider, Grid, Menu, Paper, Tab, Tabs, Typography} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import axios from "axios";
import configData from "../config.js";
import PropTypes from "prop-types";
import {RequirementDetails} from "../Components/RequirementDetails";
import {RequirementVerification} from "../Components/RequirementVerification";
import {RequirementHistory} from "../Components/RequirementHistory";
import {DeleteRequirementModal} from "../Components/ProjectRequirementModals/DeleteRequirementModal";
import {EditRequirementModal} from "../Components/ProjectRequirementModals/EditRequirementModal";
import {EditVerificationModal} from "../Components/ProjectRequirementModals/EditVerificationModal";
import {checkPerms, deleteAccess, editAccess, projectAccess} from "../Utility";

const RequirementActions = ({anchorEl, setAnchorEl, requirement, projectData, setUpdate}) => {
  const handleClose = (update) => {
    if (update) {
      setUpdate(true)
    }
    setAnchorEl(null)
  }
  const canEdit = checkPerms(editAccess.concat(projectAccess))
  const canDelete = checkPerms(deleteAccess)
  return (
    <Menu
      id="action-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      { canEdit &&
        <><EditRequirementModal req={requirement} projectData={projectData} handleClose={handleClose}/>
        <EditVerificationModal verification={requirement.verification} handleClose={handleClose}/></>
      }
      { canDelete && <DeleteRequirementModal req_name={requirement.name} handleClose={handleClose}/>}
    </Menu>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box padding="1rem">{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export function Requirements () {
  const {vehicle_id, project_id, requirement_id} = useParams()
  const [requirementData, setRequirementData] = useState(null);
  const [projectData, setProjectData] = useState(null)
  const [tabValue, setTabValue] = useState(0);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [update, setUpdate] = useState(false)
  const [updateVerification, setUpdateVerification] = useState(false)
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })

  const getProjectDetails = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects/${project_id}`)
      .then(res => {
        console.log('updating......:', res.data)
        setProjectData(res.data)
      })
      .catch(err => {
        console.log("getting proj details: ", err)
      })
  }
  const getRequirement = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/requirement/${requirement_id}`).then(res => {
      console.log('retrieved requiremennt: ', res.data)
       setRequirementData(res.data)
    }).catch(err => {
      console.log("Error getting requirement: ", err)
    })
  }

  useEffect(() => {
    setTabValue(1)
    console.log('Initiating req page...')
    getRequirement()
    getProjectDetails()
  }, []);

  useEffect(() => {
    if (update) {
      getRequirement()
      setUpdate(false)
    }
  }, [update])

  useEffect(() => {
    console.log('requirement updated! values are: ', requirementData)
    setUpdateVerification(true)
  }, [requirementData])

  const handleTabChange = (event, value) => {
    console.log("tab change: ", value)
    setTabValue(value)
  }

  const handleRequirementActions = event => {
    setActionMenuAnchor(event.currentTarget)
  }

  return (
    <>
      {(requirementData && projectData) ?
        <PageDrawer
          page={projectData.name + " (" + project_id + ")" + " >> " + requirementData.name + " (" + requirement_id + ")"}
          opTool opLogin id={vehicle_id}>
          <CenterContainer>
            <Paper>
              <Box padding="1rem">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                  sx={{fontSize: '12px'}}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="h5" align="left">
                            {requirementData.name + " - " + requirementData.id}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body2" align="left">
                            {"Description: " + requirementData.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" columnGap={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleRequirementActions}
                          endIcon={<KeyboardArrowDown />}
                          disabled={projectData.archived}
                        >
                          Actions
                        </Button>
                      </Grid>
                      <RequirementActions
                        anchorEl={actionMenuAnchor}
                        setAnchorEl={setActionMenuAnchor}
                        requirement={requirementData}
                        projectData={projectData}
                        setUpdate={setUpdate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{width: '100%'}}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value={1} label="Requirement Details"/>
                  <Tab value={2} label="Requirement Verification"/>
                  <Tab value={3} label="History"/>
                </Tabs>
                <Divider/>
                <TabPanel children={<RequirementDetails active={tabValue}
                                                        project_id={project_id}
                                                        requirement_id={requirement_id}
                                                        requirement={requirementData}/>} value={tabValue} index={1}>
                </TabPanel>
                <TabPanel children={<RequirementVerification active={tabValue}
                                                              verification={requirementData.verification}
                                                              setUpdate={setUpdate}
                                                              updateVerification={updateVerification}
                                                              setUpdateVerification={setUpdateVerification}
                                                              archived={projectData.archived} />} value={tabValue} index={2}>
      
                </TabPanel>
                <TabPanel children={<RequirementHistory/>} active={tabValue} value={tabValue} index={3}>
                </TabPanel>
              </Box>
            </Paper>
          </CenterContainer>
        </PageDrawer> :
        <CenterContainer>
          <Box sx={{display: 'flex'}} alignSelf="center" justifySelf="center">
            <CircularProgress/>
          </Box>
        </CenterContainer>
      }
    </>
  )
}
