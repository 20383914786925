/**
 * VehicleCard
 *
 * The Card to display all the SR vehicles which have been created
 *
 * Parameters:
 * - id: Id of the vehicle
 * - title: Name of the vehicle
 * - desc: Description of the vehicle
 * - img: Image of the vehicle
 */

import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const VehicleCard = ({ id, title, desc, img }) => {
    const navigate = useNavigate()

    const navigatePartManager = () => {
        navigate(`/PartManager/${id}`)
    }
    return (
        <Card
            onClick={navigatePartManager}
            className='cursor-pointer min-w-[250px] max-w-[345px] min-h-[250px]'
        >
            <CardMedia component="img"  image={img} alt={title} />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {desc}
                </Typography>
            </CardContent>
            {/* <CardActions>
                <Button
                    size="small"
                    variant="contained"
                    onClick={navigatePartManager}
                >
                    View
                </Button>
                <IconButton
                    onClick={() => {
                        alert(1)
                    }}
                >
                </IconButton>
            </CardActions> */}
        </Card>
    )
}

export default VehicleCard
