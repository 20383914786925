import React, { useState, useEffect } from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Divider
} from '@mui/material'
import {Add} from "@mui/icons-material";
// import {axiosInstance} from "../../Utility";


export function CreateKeyComponentModal({name, proj_id, keyComponents, update, disabled}) {
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  const [open, setOpen] = useState(false)

  const validationSchema = yup.object({
    name: yup.string('Enter key component name')
      .required('Key Component name required')
      .test('unique', 'Key Component name must be unique',
      function(value) {
        return !keyComponents.find(o=>o.name === value)
    }),
    proj_id: yup.string(),
    })

  const handleCreateComponent = (values) => {
    //TODO send post request for project creation
    axiosInstance.post(`${configData.BACKEND_ROOT}/keyComponent`, values).then(res => {
      console.log("key component created!")
      update()
      setOpen(!open)
    }).catch( err => {
      console.log("Error creating key component: ", err )
    })
  }

  const formik = useFormik({
    initialValues: {name: '', proj_id: proj_id},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleCreateComponent(values)
    },
  })

  return (
    <>
      <Dialog open={open}
              onClose={()=> setOpen(!open)}
              fullWidth
              sx={{ padding: '1em' }}>
        <DialogTitle>
          Create new Key Component for {name ? `${name} - ${proj_id}` : `${proj_id}`}
        </DialogTitle>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Key Component Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Create Key Component
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <Button variant="contained" size="small" onClick={() => setOpen(!open)}  startIcon={<Add />} disabled={disabled}>
        New Key Component
      </Button>
    </>
  )
}
