import React, {useEffect, useState} from 'react'
import axios from "axios";
import configData from "../../config.js";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

export const DeleteProcurementModal = ({open, closeFunc, state, stateUpdate, targetId, setError}) => {
  const [authError, setAuthError] = useState(false)
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const deleteProcurement =async () => {
    await axiosInstance.delete(`${configData.BACKEND_ROOT}/procurement/${targetId}`)
      .then(()=> {
        const newData = state.filter(item => item.id !== targetId)
        stateUpdate(newData)
        closeFunc()
      })
      .catch(err => {
        console.log("Error removing procurement: ", err.response.status)
        if (err.response.status === 403) {
          setAuthError(true)
        }
        setError(err.response.data.detail)
      })
  }

  useEffect(() => {
   if (authError) {
     setTimeout(() => {setAuthError(false)}, 5000)
   }
  }, [authError])

return (
  <Dialog open={open}>
    <DialogTitle>Confirm delete</DialogTitle>
    <DialogContent>Are you sure you would like to delete this procurement request?</DialogContent>
    {authError && <Alert severity="error">Only the creator of this procurement can remove it, please check procurement info for owner details</Alert>}
    <DialogActions>
      <Button onClick={deleteProcurement} variant="contained">Confirm</Button>
      <Button onClick={closeFunc}>Cancel</Button>
    </DialogActions>
  </Dialog>
)

}