// MUI Theme with the Sunswift Colors

import { createTheme } from '@mui/material/styles'
const SunswiftTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#ffd501',
        },
        secondary: {
            main: '#fcfcfc',
        },
        neutral: {
            main: '#000000',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
    },
})
export default SunswiftTheme
