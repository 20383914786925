/**
 * DeleteModal.jsx
 *
 * Modal for deleting item from the DB. Since it's standardised via deleting through id, one modal is needed
 *
 * Parameters:
 * - route: Route for the according delete API call
 * - currTable: Name of the current table/item set being deleted from
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */

import React from 'react'
import axios from 'axios'
import configData from '../../config.js'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    Alert,
} from '@mui/material'
const AssignModal = ({ open, closeFunc, vehicle_id }) => {
    const [parentId, setParentId] = React.useState('')
    const [childId, setChildId] = React.useState('')
    const [assignQty, setAssignQty] = React.useState(0)
    const [error, setError] = React.useState('')

    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const handleAssignPart = async () => {
        const new_assignment = {
            parent_id: parentId,
            child_id: childId,
            assign_qty: assignQty,
            vehicle_id: vehicle_id,
        }
        try {
            const res = await axiosInstance.post(
                `${configData.BACKEND_ROOT}/parts/assign`,
                new_assignment
            )
            setError('')
            closeFunc()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Assign Parts</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please input the child part ID to be assigned to parent
                        part ID
                    </DialogContentText>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: '1em' }}
                    >
                        <TextField
                            fullWidth
                            required
                            label="Parent ID"
                            onChange={(e) => {
                                setParentId(e.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            required
                            label="Child ID"
                            onChange={(e) => {
                                setChildId(e.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            required
                            label="How many to assign"
                            onChange={(e) => {
                                setAssignQty(e.target.value)
                            }}
                            type="number"
                        />
                        {error === '' ? (
                            <></>
                        ) : (
                            <Alert severity="error">ERROR — {error}</Alert>
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleAssignPart}>
                        Assign
                    </Button>
                    <Button variant="contained" onClick={closeFunc}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssignModal
