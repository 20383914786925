/**
 * Login page
 *
 * Login page for logged out members
 */

import React, { useEffect, useState } from 'react'
import {
    Typography,
    Box,
    IconButton,
    Toolbar,
} from '@mui/material'
import { keyframes } from '@emotion/react'
import Typewriter from 'typewriter-effect'
import axios from 'axios'
import configData from '../config.js'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CognitoJwtVerifier } from 'aws-jwt-verify'
import { SiAmazonaws } from 'react-icons/si'

const bounceAnimation = keyframes`
0% {
transform: translateY(0);
}
50% {
transform: translateY(-10px);
}
100% {
transform: translateY(0);
}
`

const Login = () => {
    const [error, setError] = React.useState('')
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [zID, setZID] = useState('')

    const authoriseUser = () => {
        const uri = encodeURIComponent(configData.REDIRECT_URL)
        window.location.replace(
            `https://sunswift.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=1n3sj0o831lh0pr4p6qfe77r21&response_type=code&scope=email+openid+phone&redirect_uri=${uri}`
        )
    }

    // FOR JWT TOKENS
    const getVerifier = ver_type => {
        return CognitoJwtVerifier.create({
            userPoolId: configData.AUTH_USER_POOL,
            tokenUse: ver_type,
            clientId: configData.AUTH_CLIENT_ID,
        })
    }

    const getMemberInfo = async access_token => {
        const headers = {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        }
        return await axios
            .get(
                'https://sunswift.auth.ap-southeast-2.amazoncognito.com/oauth2/userInfo',
                headers
            )
            .then(res => {
                console.log('res user info: ', res)
                sessionStorage.setItem('temp_email', res.data.email)
                return res.data
            })
            .catch(err => console.log('err getting uer info: ', err))
    }

    const decode = async access_token => {
        const verifier = getVerifier('access')
        return await verifier
            .verify(access_token)
            .then(payload => {
                console.log('token response: ', payload)

                const zid = payload['username'].replace(/\D/g, '')
                setZID(zid)

                sessionStorage.setItem('curr_zid', zid)
                sessionStorage.setItem(
                    'user_groups',
                    JSON.stringify(payload['cognito:groups'])
                )
            })
            .catch(err =>
                console.log('Error while decoding access token: ', err)
            )
    }

    // Exchanges an authorization code for an tokens
    const getToken = async code => {
        const uri = encodeURIComponent(configData.REDIRECT_URL)
        const body = `grant_type=authorization_code&client_id=${configData.AUTH_CLIENT_ID}&code=${code}&redirect_uri=${uri}`
        const headers = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
        return await axios
            .post(
                'https://sunswift.auth.ap-southeast-2.amazoncognito.com/oauth2/token',
                body,
                headers
            )
            .then(res => {
                sessionStorage.setItem('id_token', res.data.id_token)
                sessionStorage.setItem('access_token', res.data.access_token)
                console.log('response from getToken call: ', res)
                return res.data.access_token
            })
            .catch(err =>
                console.log('Error from getToken: ', err.response.data.error)
            )
    }

    useEffect(() => {
        const code = searchParams.get('code')

        // if code is not null, awsiam has authenticated user
        // exchange auth code for access token
        if (code != null) {
            sessionStorage.setItem('curr_code', code)
            // const verified =
            getToken(code).then(access_token => {
                return decode(access_token)
            })
        }
    }, [searchParams])

    const verifyUser = axiosInstance => {
        axiosInstance
            .get(`${configData.BACKEND_ROOT}/members/${zID}`)
            .then(res => {
                if (res.status !== 400) {
                    navigate('/Vehicles')
                }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    getMemberInfo(sessionStorage.getItem('access_token')).then(
                        () => {
                            navigate('/Registration')
                        }
                    )
                }
            })

    }

    useEffect(() => {
        if (zID.length > 0 && sessionStorage.getItem('access_token')) {
            const axiosInstance = axios.create({
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        'access_token'
                    )}`,
                },
            })
            verifyUser(axiosInstance)
        }
    }, [zID])

    return (
        <>
            <Toolbar
                sx={{
                    backgroundColor: '#fcfcfc',
                    borderBottom: '1px solid #e0e0e0',
                    display: 'flex',
                    justifyContent: 'space-between',
                    zIndex: 1,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <a href='https://www.sunswift.com/'>
                        <img
                            src='sunswift.png'
                            alt='Sunswift Logo'
                            style={{
                                width: '40px',
                                marginRight: '10px',
                            }}
                        />
                    </a>
                </Box>
            </Toolbar>
            <Box
                sx={{
                    backgroundImage: 'url(/background2.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: -1,
                }}
            >
                <Typography
                    variant='h4'
                    align='center'
                    sx={{ color: '#ffffff', zIndex: 1, marginBottom: 'rem' }}
                >
                    <Typewriter
                        options={{
                            strings: [
                                'Welcome to the Sunswift Enterprise Planning System!',
                            ],
                            autoStart: true,
                            deleteSpeed: Infinity
                        }}
                    />
                </Typography>
                <Box
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity and color as needed
                        position: 'fixed',
                        inset: 0,
                        zIndex: 0,
                    }}
                />
                <IconButton
                    id='LoginButton'
                    onClick={authoriseUser}
                    sx={{
                        backgroundColor: '#FF9900',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: '#FF8000',
                            animation: `${bounceAnimation} 0.5s ease-in-out`,
                        },
                        width: '10rem',
                        height: '10rem',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: '2rem',
                    }}
                >
                    <SiAmazonaws style={{ fontSize: '5rem' }} />
                </IconButton>
                <Typography
                    variant='h6'
                    align='center'
                    sx={{ color: '#ffffff', zIndex: 0 }}
                >
                    <strong>Login with AWS</strong>
                </Typography>
            </Box>
        </>
    )
}

export default Login
