import React, {useEffect, useState} from 'react'
import axios from "axios";
import * as yup from "yup";
import {useFormik} from "formik";
import configData from "../../config.js";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle, Divider,
  FormControl,
  Grid,
  InputLabel, ListItemIcon, MenuItem, Select, TextField,
} from "@mui/material";
import {useParams} from "react-router-dom";

export const EditProcurementModal = ({open, closeFunc, updateFunc, targetId, setError}) => {
  const { vehicle_id } = useParams()
  const [initData, setInitData] = useState({})
  const [initPartDetails, setInitPartDetails] = useState({})
  const axiosInstance = axios.create({
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });



  const validationSchema = yup.object({
    part_name: yup.string('Enter part name').required('Part name required'),
    part_number: yup
      .string('Enter part number')
      .required('Part number required'),
    part_desc: yup
      .string('Enter part description')
      .required('Part description is required'),
    bom_type: yup.string('Enter BOM Type').required('BOM Type required'),
    qty_to_order: yup
      .number('Enter quantity to order')
      .required('Quantity to order required')
      .min(1, 'Cannot order none of something'),
    total_cost: yup
      .number('Enter Total Cost')
      .required('Total Cost of procurement required')
      .min(0.01, 'Enter a valid cost'),
    proc_comments: yup
      .string('Enter brief explanation of what procurement is for')
      .required('Brief comment required'),
  })



  // TODO
  const handleEditProcurement = async (values) => {
    const part_details = {
      name: values.part_name,
      desc: values.part_desc,
      bom_type: values.bom_type,
      source: 'External',
      drawing: 'None',
      revision: 0,
      stock_qty: initPartDetails.stock_qty,
      loc_id: 'None',
      matr_id: 'None',
      supp_id: 'None',
      vehicle_id: vehicle_id,
      cost_per_unit: values.total_cost / values.qty_to_order,
      id: values.part_number,
    }

    const new_proc = {
      qty_requested: values.qty_to_order,
      date_changed: new Date().toUTCString(),
      comments: values.proc_comments,
      part_details: part_details,
    }
    console.log("NEW PROC FOR EDIT IS: ", new_proc)
    axiosInstance.patch(`${configData.BACKEND_ROOT}/procurement/${targetId}`, new_proc)
      .then(value=> {
        console.log('edited values are: ', values.data)
        updateFunc(value.data)
      })
      .catch(err => console.log(err))
  }

  const formik = useFormik({
    initialValues: initData,
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEditProcurement(values)
    },
  })

  const getProcurementInfo = async () => {
    console.log("info get has RUN AGAIN")
    await axiosInstance.get(`${configData.BACKEND_ROOT}/procurement/${targetId}`)
      .then((res) => {
        console.log('rs from info is: ', res.data)
        setInitData({
          part_name: res.data.part_details.name,
          part_number: res.data.part_details.id,
          part_desc: res.data.part_details.desc,
          bom_type: res.data.part_details.bom_type,
          qty_to_order: res.data.qty_requested,
          total_cost: res.data.part_details.cost_per_unit * res.data.qty_requested,
          qty_type: res.data.qty_type,
          unit_per_pack: res.data.unit_per_pack,
          proc_comments: res.data.comments,
        })
        setInitPartDetails(res.data.part_details)
      })
      .catch(err => setError(err.response.data.detail))
  }

  // Retrieve full data for specified order
  useEffect(()=>{
    if (open && targetId) getProcurementInfo()
  },[open])

  useEffect(()=>{
    console.log('init data uodated with: ', initData)
  }, [initData])


  return (
    <>
      <Dialog open={open} onClose={closeFunc}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Please enter part details:
            </DialogContentText>
            <Grid container spacing={2} sx={{paddingTop: '32px'}}>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="part_name"
                  name="part_name"
                  label="Part Name"
                  value={formik.values.part_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.part_name &&
                    Boolean(formik.errors.part_name)
                  }
                  helperText={
                    formik.touched.part_name &&
                    formik.errors.part_name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="part_number"
                  name="part_number"
                  label="Part Number"
                  value={formik.values.part_number}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.part_number &&
                    Boolean(formik.errors.part_number)
                  }
                  helperText={
                    formik.touched.part_number &&
                    formik.errors.part_number
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="part_desc"
                  name="part_desc"
                  label="Part Description"
                  value={formik.values.part_desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.part_desc &&
                    Boolean(formik.errors.part_desc)
                  }
                  helperText={
                    formik.touched.part_desc &&
                    formik.errors.part_desc
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>BOM Tree</InputLabel>
                  <Select
                    id="bom_type"
                    name="bom_type"
                    label="BOM Type"
                    value={formik.values.bom_type}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.bom_type &&
                      Boolean(formik.errors.bom_type)
                    }
                    helperText={
                      formik.touched.bom_type &&
                      formik.errors.bom_type
                    }
                  >
                    <MenuItem value={'Installation'}>
                      Installation
                    </MenuItem>
                    <MenuItem value={'Assembly'}>
                      Assembly
                    </MenuItem>
                    <MenuItem value={'Component'}>
                      Component
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <DialogContentText sx={{paddingTop: '16px'}}>
                  Please enter procurement details:
                </DialogContentText>
              </Grid>
              <Grid item xs={6}>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="proc_comments"
                  name="proc_comments"
                  label="Procurement Comments"
                  value={formik.values.proc_comments}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.proc_comments &&
                    Boolean(formik.errors.proc_comments)
                  }
                  helperText={
                    formik.touched.proc_comments &&
                    formik.errors.proc_comments
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained">Confirm</Button>
            <Button onClick={closeFunc}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )


}
