import axios from "axios";


// Utility function and data

// Permissions
export const deleteAccess = [
  'SLT',
  'Dev'
]
export const editAccess = ['ap-southeast-2_G9Fio5nl2_AWSIAM']
export const projectAccess = [
  'SystemsEngineer'
]

// Takes in a list of groups and returns whether current user is in any of the provided groups
export const checkPerms = (allowed) => {
  const groups = JSON.parse(sessionStorage.getItem('user_groups'))
  return allowed.some(g=> groups.includes(g))
}
// export const axiosInstance = axios.create( {
//   headers: {
//     Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
//   }
// });
