import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import configData from "../../config.js"
import {
  Autocomplete,
  Button,
  Dialog, DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  InputLabel, MenuItem, Select,
  TextField, Typography,
  Divider
} from "@mui/material";
import {Add} from "@mui/icons-material";
import axios from "axios";
import {req_types, verification_types} from "../../Assets/ProjectRequirementData";
import {AddProjectComponent} from "./AddProjectComponent";


export function CreateRequirementModal({projectData, setUpdateRequirements}) {
  const [open, setOpen] = useState(false)

  const [projects, setProjects] = useState([])
  const [allMembers, setAllMembers] = useState([])
  const [addedProjects, setAddedProjects] = useState([{project: '', component: ''}])
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const validationSchema = yup.object({
    name: yup.string('Enter requirement name').required('Requirement name required'),
    priority: yup.string('Requirement priority').required('Priority required'),
    type: yup
      .string('Requirement type')
      .required('Requirement type required'),
    projects: yup.array('Add project').of(yup.object()).required('Project required').when("type", {
      is: "Interfacing",
      then: yup.array('Add project').of(yup.object()).min(2, "Must have at least two projects for interfacing requirement").required("required"),
      otherwise: yup.array('Add project').of(yup.object()).max(1, "Non interfacing requirements can only be assigned to one project").required("required"),
    })
    .test('all projects valid', 'Project components selected',
      function(projects) {
        return projects.every(p=> p.project !== "" && p.component !== "")
      }),
    desc: yup.string('Enter requirement description').required('Requirement description required'),
    notes: yup.string('Enter any additional notes'),
    verification_type: yup
      .string('Verification type')
      .required('Verification type required'),
    verifying_engineer: yup.string('Enter verifying engineer zid').required('Verifying engineer required').matches(/^[0-9]+$/, "Enter without leading 'z'")
      .min(7, 'Must be exactly 7 digits')
      .max(7, 'Must be exactly 7 digits'),
    verification_desc: yup
      .string('Verification description')
      .required('Verification description required'),
  })

  const generateReqId = async (type) => {
    return axiosInstance.get(`${configData.BACKEND_ROOT}/requirement/latest/${type}`)
      .then(res => {
        let id_num = parseInt(res.data.id.replace(/\D/g, '')) + 1
        id_num = id_num.toString().padStart(3, '0')
        console.log("id num: ", id_num)
        return type === "Interfacing" ? "INT-" + id_num : "RQ" + id_num
      })
      .catch(err => {
        console.log("Error retrieving latest requirement: ", err)
      })
  }

  const createVerification = (values) => {
    const verification = {
      type: values.verification_type, // given
      date: new Date(), // generated
      status: 'Not Verified', // generated
      creator_id: parseInt(sessionStorage.getItem('curr_zid')), //sessionStorage.getItem('curr_zid'),
      engineer_id: parseInt(values.verifying_engineer),
      description: values.verification_desc, // given
    }
    console.log('creating verification with: ', verification)
    return axiosInstance
      .post(`${configData.BACKEND_ROOT}/verification`, verification).then(res => {
        console.log('verification created')
        return res.data.id
      })
      .catch(err=>{
        console.log("error ", err)
        return -1
      })
  }

  const assignProjects = async (reqId, projects) => {
    return await axios.all(projects.map(p => {
      return axiosInstance.put(`${configData.BACKEND_ROOT}/project/add/requirement`, {proj_id: p.project,req_id: reqId})
    }))
  }
  const assignComponents = async (reqId, projects) => {
    return await axios.all(projects.map(p => {
      return axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/add/component`, {req_id: reqId, kc_id: p.component})
    }))
  }

  const handleCreateRequirement = async (values) => {
    const req_id = await generateReqId(values.type)
    const ver_id = await createVerification(values)
    if (ver_id < 0) {
      console.log('Error creating verification')
      //TODO make alert
      return;
    }
    console.log("requirement id: ", req_id)
    const req = {
      id: req_id,
      type: values.type,
      name: values.name,
      priority: values.priority,
      description: values.desc,
      notes: values.notes,
      verification_id: ver_id
    }

    // TODO do cleanup checks
    console.log("submitting req: ", req)
    axiosInstance
      .post(`${configData.BACKEND_ROOT}/requirement`, req)
      .then((res) => {
        console.log("req created!")

        // assign projects and components
        assignProjects(req_id, values.projects).then(() => {
          setUpdateRequirements(true)
        })
        assignComponents(req_id, values.projects)
        setOpen(!open)
      })
      .catch((err) => {
        // TODO On error, set error from backend response
        console.log(err.response.data.detail)
        // Remove verification, since requirement was not created
        axiosInstance.delete(`${configData.BACKEND_ROOT}/verification/${ver_id}`)
      })
  }

  // TODO
  const formik = useFormik({
    initialValues: {name: '', priority: 'Medium', type: 'Functional', projects: [], desc: '', notes: '', verification_type: 'Track day', verifying_engineer: '',verification_desc: ''},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("submitting...")
      handleCreateRequirement(values)
    },
  })

  const getProjectNames = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res => {
        console.log('data from get request is: ', res.data)
        const data = res.data.map(p => {
          return {label: p.name, id: p.id, department: p.department}
        })
        setProjects(data)
      })
      .catch(err=>console.log('Err: ', err))
  }

  const getAllMembers = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/members`)
      .then(res => {
        const members = res.data.map(m => {
          return {label: m.firstname + ' ' + m.lastname + ' (z' + m.id + ')', id: m.id}
        })
        setAllMembers(members)
      })
  }

  useEffect(() => {
    if (open) {
      getProjectNames();
      getAllMembers();
    }
  }, [open])


  const handleAddProject = () => {
    setAddedProjects(addedProjects => [...addedProjects, {project: '', component: ''}])
  }
  const handleRemoveProject = (idx) => {
    console.log("removing ", idx, "...")
    const newAddedProjects = addedProjects.filter((p, ridx) => idx !== ridx)
    setAddedProjects(newAddedProjects)
  }

  // {project: id, component: id}
  const handleAddProjectComponent = (projectComponent, idx) => {
    const newAddedProjects = addedProjects.map((projComp, sidx) => {
      if (idx !== sidx) return projComp;
      return projectComponent;
    });
    setAddedProjects(newAddedProjects);
  }

  useEffect(() => {
    console.log('Added  projects: ', addedProjects)
    formik.setFieldValue('projects', addedProjects)
  }, [addedProjects])

  return (
    <>
      <Dialog open={open} onClose={()=> setOpen(!open)}>
        <DialogTitle>
          Create new requirement        
        </DialogTitle>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Requirement Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    id="priority"
                    name="priority"
                    label="Priority"
                    value={formik.values.priority}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.priority &&
                      Boolean(formik.errors.priority)
                    }
                    helperText={
                      formik.touched.priority &&
                      formik.errors.priority
                    }
                  >
                    <MenuItem value={'High'}>
                      High
                    </MenuItem>
                    <MenuItem value={'Medium'}>
                      Medium
                    </MenuItem>
                    <MenuItem value={'Low'}>
                      Low
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Requirement Type</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    label="Requirement Type"
                    value={formik.values.type}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value === "Interfacing") {
                        handleAddProject()
                      } else if (addedProjects.length > 1) {
                        setAddedProjects(addedProjects => [addedProjects[0]])
                      }
                    }}
                    error={
                      formik.touched.type &&
                      Boolean(formik.errors.type)
                    }
                    helperText={
                      formik.touched.type &&
                      formik.errors.type
                    }
                  >
                    {req_types.map(r=> {
                      return <MenuItem value={r}>{r}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {
                addedProjects.map((p, idx) => {
                  if (idx === 0) {
                    return (
                      <AddProjectComponent allProjects={projects} projectData={projectData} idx={0}
                                           addProjectComponent={handleAddProjectComponent}
                      />
                    )
                  } else if (idx === 1 && formik.values.type === "Interfacing"){
                    return (
                      <AddProjectComponent allProjects={projects.filter(p=> {
                          return !addedProjects.some(e => e.project === p.id)
                        })} idx={1} addProjectComponent={handleAddProjectComponent}/>
                    )
                  } else if (idx !== 1){
                    return (
                        <AddProjectComponent
                          allProjects={projects.filter(p=> {
                          return !addedProjects.some(e => e.project === p.id)
                        })}
                          idx={idx}
                          handleRemove={handleRemoveProject}
                          addProjectComponent={handleAddProjectComponent}/>
                      )
                  }
                })
              }
              {
                formik.values.type === "Interfacing" &&
                <Grid item xs={6}><Button
                  variant="contained"
                  size="small"
                  startIcon={<Add/>}
                  onClick={handleAddProject}
                >Add project</Button></Grid>
              }

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="desc"
                  name="desc"
                  label="Requirement Description"
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.desc &&
                    Boolean(formik.errors.desc)
                  }
                  helperText={
                    formik.touched.desc &&
                    formik.errors.desc
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="notes"
                  name="notes"
                  label="Additional Notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.notes &&
                    Boolean(formik.errors.notes)
                  }
                  helperText={
                    formik.touched.notes &&
                    formik.errors.notes
                  }
                />
              </Grid>
              <Grid item xs={12} >
                <Typography paddingTop="1rem" variant="h6">Requirement Verification</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Requirement Verification Type</InputLabel>
                  <Select
                    id="verification_type"
                    name="verification_type"
                    label="Requirement Verification Type"
                    value={formik.values.verification_type}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.verification_type &&
                      Boolean(formik.errors.verification_type)
                    }
                    helperText={
                      formik.touched.verification_type &&
                      formik.errors.verification_type
                    }>
                    {verification_types.map(v=> {
                      return <MenuItem value={v}>{v}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="verifying_engineer"
                  name="verifying_engineer"
                  label="Verifying Engineer"
                  isOptionEqualToValue={(option, value)=> {return option.id === value.id}}
                  onChange={(event, value, reason, details) => formik.setFieldValue('verifying_engineer', value.id)}
                  options={allMembers}
                  renderInput={(params) => <TextField {...params} label="Select a member" />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="verification_desc"
                  name="verification_desc"
                  label="Verification Description"
                  value={formik.values.verification_desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.verification_desc &&
                    Boolean(formik.errors.verification_desc)
                  }
                  helperText={
                    formik.touched.verification_desc &&
                    formik.errors.verification_desc
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Create requirement
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <Button variant="contained" size="small" onClick={() => setOpen(!open)}  startIcon={<Add />} disabled={projectData.archived}>
        New Requirement
      </Button>
    </>
  )

}