import React, {useEffect, useState} from "react";
import * as yup from "yup";
import {useFormik} from "formik";
import configData from "../../config.js"
import {
  Autocomplete,
  FormControl,
  Grid, IconButton,
  TextField
} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import axios from "axios";
// import {axiosInstance} from "../../Utility";


export function AddProjectComponent({isEdit, isNew, update, editData, projectData, allProjects, addProjectComponent, handleRemove, idx, req_id}) {
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  const [allComponents, setAllComponents] = useState([])
  const [project, setProject] = useState(projectData ? {label: projectData.name, id: projectData.id} : null)

  const getProjectValue = () => {
    if (isEdit && !isNew) {
      const data = editData[idx]
      // console.log('hello, getting edit vsalL: ', data)
      return {label: data.proj_name, id: data.proj_id}
    } else {
      return projectData ? {label: projectData.name, id: projectData.id, department: projectData.department}: ''
    }
  }

  const getComponentValue = () => {
      if (isEdit && !isNew){
        const data = editData[idx]
        return {label: data.kc_name, id: data.kc_id}
      }
  }
  const [keyComponent, setKeyComponent] = useState(isEdit ? getComponentValue() : {label: "Overall", id: 1})

  const getComponentNames = (id) => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/keyComponents/project/${id}`)
      .then(res => {
        const data = res.data.map(kc => {
          return {label: kc.name, id: kc.id}
        })
        setAllComponents(data)
      })
      .catch(err=>console.log('Err: ', err))
  }

  // Removes old key component from requirement and assigns new key component
  // iff we are editing the requirement
  const handleEditComponent = async (old_kc_id, new_kc_id) => {
    console.log('Updating requirement ', req_id, ' component with ', old_kc_id, ' -> ', new_kc_id)
    axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/remove/component`, {req_id: req_id, kc_id: old_kc_id})
      .then(() => {
        console.log('removing kc success')
        return axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/add/component`, {req_id: req_id, kc_id: new_kc_id})
      })
      .catch(err => {
        console.log('Error while removing/adding old project component from requirement: ', err)
      })
  }

  const handleAddNewProjectComponent = (kc_id, kc_name) => {
    console.log('Adding interfacing requirement to new project + component')
    axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/add/component`, {req_id: req_id, kc_id: kc_id})
      .then(res => {
        return axiosInstance.put(`${configData.BACKEND_ROOT}/project/add/requirement`, {proj_id: project.id, req_id: req_id})
          .then(() => {
            console.log('successfully added new project component to requirement')
            update({kc_id: kc_id, kc_name: kc_name, proj_id: project.id, proj_name: project.label})
          })
          .catch(err => {
            // could not add requirement to project, remove kc req relationship
            console.log('Could not add requirement to project: ', err)
            return axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/remove/component`, {req_id: req_id, kc_id: kc_id})
          })
      })
      .catch(err => console.log('Error adding new project component', err))
  }

  const handleUpdateProject = (value) => {
    getComponentNames(value.id)
    setProject(value)
  }

  const handleUpdateComponent = (value) => {
    // console.log("key component update: ", value)
    if (isEdit && keyComponent && !isNew) {
      handleEditComponent(keyComponent.id, value.id)
    } else if (isEdit && isNew) {
      handleAddNewProjectComponent(value.id, value.label)
    }
    setKeyComponent(value)
  }

  useEffect(() => {
     console.log("all projects", allProjects)
    if (projectData) getComponentNames(projectData.id)
    if (isEdit && !isNew) getComponentNames(editData[idx].proj_id)
    if (projectData) {
      addProjectComponent({project: projectData.id, component: keyComponent.id}, 0)
    }
  }, [])

  useEffect(() => {
    if (project && keyComponent && !isNew) {
      addProjectComponent({project: project.id, component: keyComponent.id}, idx)
    }
  }, [keyComponent, project])

  return (
    <>
      <Grid item xs={(idx === 0 || idx === 1) ? 6 : 5}>
        <FormControl fullWidth>
          <Autocomplete
            id="project"
            name="project"
            label="Project"
            disabled={isEdit && !isNew}
            defaultValue={getProjectValue()}
            readOnly={Boolean(projectData)}
            onChange={(event, value) =>{handleUpdateProject(value)}}
            // options={allProjects.sort((a, b) => -b.department.localeCompare(a.department))}
            options={allProjects}
            isOptionEqualToValue={(option, value)=> {return option.id === value.id}}
            // groupBy={(option) => option.department}
            renderInput={(params) => <TextField {...params} label={projectData ? "Current project" : "Project"} />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={(idx === 0 || idx === 1) ? 6 : 5}>
        <Autocomplete
          id="keyComponent"
          name="keyComponent"
          label="Key component"
          defaultValue={getComponentValue()}
          isOptionEqualToValue={(option, value)=> {return option.id === value.id}}
          onChange={(event, value) =>{handleUpdateComponent(value)}}
          options={allComponents}
          renderInput={(params) => <TextField {...params} label="Key components" />}
        />
      </Grid>
      {(idx !== 0 && idx !== 1) && <Grid item xs={2} alignSelf="center"><IconButton onClick={() => {
        handleRemove(idx)
      }}>
        <Delete/>
      </IconButton></Grid>}
    </>
  )
}
