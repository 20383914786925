import React from "react";
import { Grid, ListItem, ListItemIcon} from "@mui/material";
import {WarningAmberOutlined} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";

export function RequirementHistory() {
  return (
    <Grid container justifyContent="center" alignItems="center" flexDirection="row" sx={{ fontSize: '12px', width:'820px' }}>
      <Grid>
        <ListItem>
          <ListItemIcon>
            <WarningAmberOutlined/>
          </ListItemIcon>
          <ListItemText primary="Future release feature"/>
        </ListItem>
      </Grid>
    </Grid>
  )

}