export const PartColumns = [
    {
        field: 'id',
        headerName: 'ID',
        hide: true,
    },
    {
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        editable: true,
        flex: 0.5,
    },
    {
        field: 'desc',
        headerName: 'Description',
        minWidth: 200,
        editable: true,
        flex: 0.5,
    },
    {
        field: 'bom_type',
        headerName: 'BOM Tree',
        minWidth: 100,
        width: 150,
    },

    {
        field: 'drawing',
        headerName: 'DWG',
        type: 'boolean',
        minWidth: 50,
        width: 110,
    },
    {
        field: 'revision',
        headerName: 'REV',
        type: 'number',
        minWidth: 50,
        width: 110,
    },
    {
        field: 'stock_qty',
        headerName: 'Total Stock',
        type: 'number',
        minWidth: 50,
        width: 130,
    },
    {
        field: 'source',
        headerName: 'Source',
        minWidth: 50,
        width: 130,
    },
    {
        field: 'loc_id',
        headerName: 'Location ID',
        type: 'number',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'supp_id',
        headerName: 'Supplier ID',
        type: 'number',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'matr_id',
        headerName: 'Material',
        minWidth: 50,
        width: 130,
        type: 'number',
    },
]

export const SupplierColumns = [
    {
        field: 'id',
        headerName: 'Supplier ID',
        type: 'number',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'name',
        headerName: 'Supplier Name',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'contact_num',
        headerName: 'Contact Info',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'supp_email',
        headerName: 'Email Address',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'comments',
        headerName: 'Comments',
        type: 'string',
        minWidth: 50,
        flex: 1,
    },
]

export const MaterialColumns = [
    {
        field: 'id',
        headerName: 'Material ID',
        type: 'number',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'name',
        headerName: 'Material Name',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'desc',
        headerName: 'Properties',
        type: 'string',
        minWidth: 50,
        flex: 1,
    },
]

export const LocationColumns = [
    {
        field: 'id',
        headerName: 'Location ID',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        minWidth: 50,
        width: 150,
    },
    {
        field: 'desc',
        headerName: 'Description',
        type: 'string',
        minWidth: 50,
        flex: 1,
    },
]
