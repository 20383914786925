import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  MenuItem,
  TextField
} from "@mui/material";
import {Delete} from "@mui/icons-material";

import axios from "axios";
import configData from '../../config.js'
import {useNavigate, useParams} from "react-router-dom";
// import {axiosInstance} from "../../Utility";


export function DeleteRequirementModal({req_name, handleClose}) {
  const [open, setOpen] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const navigate = useNavigate()
  const {vehicle_id, project_id, requirement_id} = useParams()
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  // TODO
  const handleDeleteRequirement = () => {
    axiosInstance.delete(`${configData.BACKEND_ROOT}/requirement/${requirement_id}`).then(res=>{
      console.log('deleted')
      navigate(`/ProjectRequirement/${vehicle_id}/${project_id}`)
      handleClose()
    }).catch(err => {
      console.log('Error deleting requirement: ', err)
    });
  }

  return (
    <>
      <Dialog open={open}
              onClose={()=> setOpen(!open)}
              fullWidth
              sx={{ padding: '1em' }}>
        <DialogTitle>Confirm delete {req_name}</DialogTitle>
        <DialogContent>
          Are you sure you wish to delete this requirement? Deleting a requirement is permanet and cannot be undone.
          <br/>
          Type <i>'confirm delete {req_name}'</i> into the textbox below if you are certain you want to proceed with deletion.
          <br/>
          <br/>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={"confirm delete " + req_name}
            onChange={e=> {
              if (e.target.value === "confirm delete " + req_name) setConfirmed(true);
              else (setConfirmed(false));
            }}></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteRequirement} variant="contained" disabled={!confirmed}>Confirm</Button>
          <Button onClick={() => {
            setOpen(!open)
            handleClose()
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <MenuItem onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Delete/>
        </ListItemIcon>
        Delete requirement
      </MenuItem>
    </>
)
}