import React, { useEffect, useState } from 'react'
import axios from 'axios'
import configData from '../../config.js'
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//Modal for checking existing parts
export const ExistingModal = ({
    open,
    closeFunc,
    nextModalOpen,
    setState,
    vehicle_id
}) => {

  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  // Get all the part names
    const [partsData, setPartsData] = useState([])
    const getPartNames = () => {
      axiosInstance.get(`${configData.BACKEND_ROOT}/parts/${vehicle_id}/vehicles`)
        .then(res => {
        //   console.log('data from get request is: ', res.data)
          const data = res.data.filter(part => part.source !== "Internal").map(part => {
            return {label: part.name, id: part.id}
          })
          setPartsData(data)
        })
        .catch(err=>console.log('Err: ', err))
    }

    const [existPartId, setExistPartId] = useState('')

    useEffect(() => {
        getPartNames()
        // eslint-disable-next-line
    }, [])

    //This passing of state surely cna be done better?
    const handleExistingProcurement = async () => {
        setState(existPartId)
        setExistPartId('')
        closeFunc()
        nextModalOpen()
    }

  return (
        <Dialog open={open} onClose={closeFunc}>
            <DialogTitle>Procurement</DialogTitle>
            <DialogContent>
            <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>Please select the part you would want to re-procure</DialogContentText>
                    </Grid>
                    <FormControl sx={{ mt: 2, width: '100%', paddingLeft: "15px" }}>
                        <InputLabel sx={{paddingLeft: "20px"}}>Part</InputLabel>
                        <Select
                            autoFocus
                            onChange={(e) => setExistPartId(e.target.value)}
                            label="Part"
                            value={existPartId}
                        >
                           {partsData.map((part, index) => (
                                <MenuItem key={part.id} value={part.id}>{part.label}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <Grid item xs={12}>
                        <Button fullWidth variant="contained" onClick={handleExistingProcurement} disabled={existPartId === ""}>Add</Button>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog>
    )
}