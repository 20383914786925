import React from "react";
import {
  Grid,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListItemText from "@mui/material/ListItemText";
import MemberCard from './MemberCard'

export const TabularItem = ({ icon, label, value, zid, multiline, onClick }) => (
    <Grid container justifyContent="flex" alignItems="center">
      <Grid item xs={6}>
        <ListItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      </Grid>
      {
        (label === 'Creator' || label === 'Verifying Engineer' && zid )
          ? 
          <MemberCard label={value} zid={zid}/>
          :  
          <Grid item xs={6} textAlign="left">
            <ListItemText primary={value} multiline={multiline} onClick={onClick}/>
          </Grid>
      }
      
    </Grid>
  );