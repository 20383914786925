/**
 * DrawerItem
 *
 * Wrapper for MUI component required for PageDrawer.jsx list item
 *
 * Parameter:
 * - text: Text displayed in the drawer item
 * - func: The onClick function (typically a navigation function)
 * - children: Encapsulates the button icon that this drawer item uses
 */
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'

const DrawerItem = ({ text, func, children }) => {
    return (
        <ListItemButton
            sx={{
                '&:hover': {
                    color: 'black',
                    '& .MuiListItemIcon-root': {
                        color: 'secondary.main',
                    },
                },
                padding: '12px'
            }}
            onClick={func}
        >
            {
                children &&
                <ListItemIcon>
                    {React.cloneElement(children, {
                        sx: { color: 'black' },
                    })}
                </ListItemIcon>
            }
            <ListItemText primary={text} />
        </ListItemButton>
    )
}

export default DrawerItem
