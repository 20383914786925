import React from 'react'
import PageDrawer from '../Components/PageDrawer'

import { Container, Box } from '@mui/material'


const PatchNotes = () => {
  return (
        <PageDrawer opLogin opTool>
            <Container maxWidth='lg' className='min-h-screen pt-[80px] font-sans '>
                <h1 className='text-[50px] leading-[50px] font-bold'>Patch Notes</h1>

                <h2 className='patchNoteHeadings mb-[5px] mt-[30px]'>💡 Upcoming Features</h2>
                <hr className='mb-[10px]'/>
                <ul className='dotPoints'>
                    <li>Part Number and Project Number prompt during creation</li>
                    <li>Profile Pages</li>
                    <li>Dark Mode</li>
                </ul>

                <Box className='patchNoteHeaderBox'>
                    <h2 className='patchNoteHeadings'>📝 V2.0.2 (Latest)</h2>
                    <p className='patchDate'>12 July 2023</p>
                </Box>
                <hr className='mb-[10px] mt-[5px]'/>
                <h3 className='patchNoteSubHeading'>New Features:</h3>
                <ul className='dotPoints'>
                    <li>Hovering over a name shows info card</li>
                </ul>
                <h3 className='patchNoteSubHeading mt-[20px]'>Bug Fixes:</h3>
                <ul className='dotPoints'>
                    <li>Added on-hover effect to some clickable items</li>
                </ul>

                <Box className='patchNoteHeaderBox'>
                    <h2 className='patchNoteHeadings'>📝 V2.0.1</h2>
                    <p className='patchDate'>3 July 2023</p>
                </Box>
                <hr className='mb-[10px] mt-[5px]'/>
                <h3 className='patchNoteSubHeading'>New Features:</h3>
                <ul className='dotPoints'>
                    <li>Supports mobile resolution</li>
                    <li>Updated Patch Notes page</li>
                </ul>
                <h3 className='patchNoteSubHeading mt-[20px]'>Bug Fixes:</h3>
                <ul className='dotPoints'>
                    <li>Fixed on-hover effect reducing readability of sidebar elements</li>
                    <li>Added ← icon to Vehicles button to avoid confusion</li>
                </ul>

                <Box className='patchNoteHeaderBox'>
                    <h2 className='patchNoteHeadings'>📝 V1.0.1</h2>
                    <p className='patchDate'>12 June 2023</p>
                </Box>
                <hr className='mb-[10px]'/>
                <h3 className='patchNoteSubHeading'>New Features:</h3>
                <ul className='dotPoints'>
                    <li>Improved UI/UX</li>
                </ul>
                <h3 className='patchNoteSubHeading mt-[20px]'>Bug Fixes:</h3>
                <ul className='dotPoints'>
                    <li>New users not being redirected to the registration form after logging in with AWS</li>
                    <li>Infinite loading when accessing project page</li>
                    <li>Mis-aligned text on requirements page</li>
                    <li>Archived projects are now read only</li>
                </ul>
            </Container>
        </PageDrawer>
  )
}

export default PatchNotes
