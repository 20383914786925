import React, { useState, useEffect } from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material'
import {useParams} from "react-router-dom";
import {Add} from "@mui/icons-material";
import {depts} from "../../Assets/ProjectRequirementData";
// import {axiosInstance} from "../../Utility";

export function CreateDepartmentModal({setUpdate}) {
  const [open, setOpen] = useState(false)
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const validationSchema = yup.object({
    name: yup.string('Enter department name').required('Department name required'),
    description: yup.string('Enter department description').required('Department description required')
  })

  const handleCreateDepartment = (values) => {
    axiosInstance.post(`${configData.BACKEND_ROOT}/department`,values)
      .then((res) => {
        console.log('res: ', res)
        setUpdate(true)
        setOpen(!open)
      })
      .catch(err=>{
      console.log("Error: ", err)
    });
  }

  const formik = useFormik({
    initialValues: {name: '', description: ''},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('values: ', values)
      handleCreateDepartment(values)
    },
  })

  return (
    <>
      <Dialog open={open} onClose={()=> setOpen(!open)}>
        <DialogTitle>Create new department</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Department Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="description"
                  name="description"
                  label="Department Description"
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description &&
                    formik.errors.description
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Create department
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <Button variant="contained" onClick={() => setOpen(!open)}  startIcon={<Add />}>
        New Department
      </Button>
    </>
  )
}
