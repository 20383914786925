import Card from "@mui/material/Card";
import {
  Autocomplete,
  Button,
  CardHeader,
  IconButton,
  ListItem,
  Paper,
  Skeleton,
  TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import axios from "axios";
import configData from "../config.js";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";

export const DepartmentProjectsCard = ({vehicle_id, dept, setUpdate, update}) => {
  const [projects, setProjects] = useState(null)
  const [colour, setColour] = useState(() => {
    return dept ? "#ffd500" : "#c2c2c2"
  })
  const navigate = useNavigate()
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })

  const getProjects = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects/dept/${dept.id}`)
      .then(res => {
        const data = res.data
        console.log('getting project data for dept ' + dept.name + ': ', data)
        setProjects(data)
      })
      .catch(err => {
        console.log("Error: ", err.response.data.detail)
      })
  }

  const getArchived = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res => {
        const data = res.data.filter(p => {
          return p.archived
        })
        console.log('archived projects ', data)
        setProjects(data)
      })
      .catch(err => {
        console.log("Error getting projects: ", err.response.data.detail)
      })
  }

  useEffect(() => {
    if (update && dept) {
      if (update.dept_id === dept.id) {
        console.log()
        getProjects()
        setUpdate({})
      }
    }
  }, [update])

  useEffect(() => {
    if (dept) {
      getProjects()
    } else {
      console.log('getting archived projects...')
      getArchived()
    }
  }, [])

  if (projects) return (
    <Card sx={{ maxWidth: 345}}>
      <CardHeader titleTypographyProps={{color: 'black', fontSize: '1rem'}} title={dept ? dept.name : "Archived"} sx={{ backgroundColor: colour}}/>
        <Paper style={{minHeight: 200, maxHeight: 200, overflow: 'auto'}}>
        <List>
          {
            projects.filter(proj => { return dept ? !proj.archived : true}).map((proj, idx) => {
              return (
                <ListItem disablePadding key={idx}>
                  <ListItemButton>
                    <ListItemText primary={proj.name}
                                  secondary={proj.id}
                                  onClick={
                                  () => {
                                    navigate(`/ProjectRequirement/${vehicle_id}/${proj.id}`)
                                  }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            })
          }
        </List>
        </Paper>
    </Card>
  )
  else return (
    <Card>
      <CardHeader titleTypographyProps={{color: 'black', fontSize: '1rem'}} title={dept ? dept.name : "Archived"} sx={{ backgroundColor: colour}}/>
      <Paper style={{maxHeight: 200, overflow: 'auto'}}>
        <Skeleton variant="rectangular" height={200} width="100%" animation="pulse"/>
      </Paper>
    </Card>
  )
}