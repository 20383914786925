import PageDrawer from "../Components/PageDrawer";
import CenterContainer from "../Components/CenterContainer";
import {useNavigate, useParams} from "react-router-dom";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Container,
  Grid, ListItemIcon,
  Stack,
  TextField,
} from "@mui/material";
import {CreateProjectModal} from "../Components/ProjectRequirementModals/CreateProjectModal";
import React, {useEffect, useState} from "react";
import axios from "axios";
import configData from "../config.js";
import {DepartmentProjectsCard} from "../Components/DepartmentProjectsCard";
import {CreateDepartmentModal} from "../Components/ProjectRequirementModals/CreateDepartmentModal";
import {checkPerms, deleteAccess} from "../Utility";
import {Inventory} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

export function DepartmentProjects () {
  const navigate = useNavigate()
  const { vehicle_id } = useParams()
  const [departments, setDepartments] = useState([])
  const [allProjects, setAllProjects] = useState()
  const [deptRows, setDeptRows] = useState()

  // with shape {dept_id: int}
  const [updateDepartmentProjects, setUpdateDepartmentProjects] = useState({})
  const [updateDepartments, setUpdateDepartments] = useState(false)
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })
  const getDepartments = async () => {
    const res = await axiosInstance.get(`${configData.BACKEND_ROOT}/departments`)
    const data = res.data
    setDepartments(data)
  }
  const getAllProjects = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res => {
        const options = res.data.map(p => {
          const dept = departments.find(dept => dept.id === p.dept_id)
          return {label: p.name + " (" + p.id + ")", id: p.id, department: dept.name, archived: p.archived}
        })
        console.log('project options: ', options)
        setAllProjects(options)
      })
      .catch(err => {
        console.log('Error getting all projects for searching: ', err)
      })
  }

  useEffect(() => {
    getDepartments()
    if (window.innerWidth < 500) {
      setDeptRows(12)
    } else {
      setDeptRows(4)
    }
  }, [])

  useEffect(()=> {
   if (departments.length > 0) {
     getAllProjects()
   }
  }, [departments])

  useEffect(() => {
   if (updateDepartments) {
     getDepartments()
     setUpdateDepartments(false)
   }
  }, [updateDepartments])

  return (
    <PageDrawer page="Project Requirements" opTool opLogin id={vehicle_id}>
      <CenterContainer size='xl'>
        <Stack direction='row' justifyContent='center' paddingY='1em' sx={{ margin: '1em' }} spacing={4}>
          {(departments.length > 0) && <CreateProjectModal setUpdate={setUpdateDepartmentProjects} deptUpdate={updateDepartments}/>}
          {checkPerms(deleteAccess) && <CreateDepartmentModal setUpdate={setUpdateDepartments}/>}
        </Stack>
        <Container maxWidth="lg">
          { allProjects &&
            <Autocomplete renderInput={(params) => <TextField {...params} label="Find project"/>}
                          renderOption={(props, option, state) => { return (
                            <ListItemButton dense key={option.id} onClick={() => navigate(`/ProjectRequirement/${vehicle_id}/${option.id}`)}>
                              <ListItemText primary={option.label} secondary={option.archived ? "Archived" : ""}/>
                              { option.archived && <ListItemIcon><Inventory/></ListItemIcon> }
                            </ListItemButton>)}}
                          groupBy={(option) => option.department}
                          options={allProjects.sort((a, b) => -b.department.localeCompare(a.department))}
                          isOptionEqualToValue={(option, value) => {return option.id === value.id}}
            />
          }

          <Box
            sx={{ margin: '3vw 0vw 3vw 0vw', display: 'block' }}
          >
            { departments ?
              <Grid container spacing={2}>
                {departments.map((dept, idx) => {
                  return (
                    <Grid item xs={deptRows} key={idx + 1}>
                      <DepartmentProjectsCard
                        vehicle_id={vehicle_id}
                        dept={dept}
                        update={updateDepartmentProjects}
                        setUpdate={setUpdateDepartmentProjects}
                      />
                    </Grid>)
                })}
                <Grid item xs={deptRows} key={0}>
                  <DepartmentProjectsCard
                    vehicle_id={vehicle_id}
                    update={updateDepartmentProjects}
                    setUpdate={setUpdateDepartmentProjects}
                  />
                </Grid>
              </Grid> :
              <Box sx={{ display: 'flex' }} alignSelf="center" justifySelf="center">
                <CircularProgress />
              </Box>
            }
          </Box>
        </Container>
      </CenterContainer>
    </PageDrawer>
  )
}
