/**
 * CenterContainer.jsx
 *
 * Container for pages that require centering on the page
 *
 * Parameters:
 * - children: JS default keyword to allow for children
 * - size: Based on MUI maxWidth props, change width size to fit on the screen. Default to md (middle)
 */

import { Box, Container } from '@mui/material'

const CenterContainer = ({ children, size = 'md' }) => {
    return (
      <Container
        maxWidth={size}
        sx={{
          marginTop: '50px',
          padding: '2vw 0px 2vw 0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {children}
      </Container>
    );
  };
export default CenterContainer
