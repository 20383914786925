import React, {useEffect, useState} from "react";
import {
  Grid
} from "@mui/material";
import axios from "axios";
import configData from "../config.js";
import {
  FolderOpen,
  Dehaze, Person
} from '@mui/icons-material';
import { TabularItem } from "./TabularItem";

export function RequirementDetails({active, requirement_id, requirement, project_id}) {
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  // const [requirement, setRequirement] = useState()
  const [engineerData, setEngineerData] = useState(null);
  const [projectData, setProjectData] = useState("");

  const getProjects = () => {
    console.log('getting projects from requirement: ', requirement)
    const curr_project = requirement.projects.find(p=>p.id === project_id)
    const rel = requirement.projects.filter(p=> p.id !== project_id).map(p=>p.name + " (" + p.id + ")")
    setProjectData(rel.length === 0 ? curr_project.name : curr_project.name + " (related: " + rel.join(',') + ")")
  }

  const getEngineerDetails = (creator_id) => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/members/${creator_id}`).then(res => {
      console.log('req creator: ', res.data)
      setEngineerData(res.data)
    }).catch(err=> console.log('Error getting engineer data: ', err))
  }

  useEffect(() => {
    if (!engineerData) {
      getEngineerDetails(requirement.verification.creator_id)
    }
  }, [active])

  useEffect(() => {
    if (projectData.length === 0 && requirement) {
      getProjects()
    }
  }, [requirement])
  return (
    <Grid container sx={{ fontSize: '12px' }}>
      <TabularItem icon={<FolderOpen />} label="Project" value={projectData} />
      <TabularItem icon={<Dehaze />} label="Type" value={requirement.type} />
      <TabularItem
        icon={<Person />}
        label="Creator"
        value={`${engineerData?.firstname} ${engineerData?.lastname} (z${requirement.verification.creator_id})`}
        zid={requirement.verification.creator_id}
      />
      <TabularItem
        icon={<Dehaze />}
        label="Requirement Notes"
        value={requirement?.notes.length ? requirement.notes : "No notes available"}
        multiline
      />
    </Grid>
  )
}