import SunswiftTheme from '../Theme/SunswiftTheme'
import {
    CheckCircle,
    Warning,
    Loop,
    MoreVert,
    Pending,
} from '@mui/icons-material'
import { ThemeProvider } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import CenterContainer from '../Components/CenterContainer.jsx'
import {
    Alert,
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
} from '@mui/material'
import axios from 'axios'
import configData from '../config.js'
import { useParams } from 'react-router-dom'
import PageDrawer from '../Components/PageDrawer'
import { DeleteOrderModal } from '../Components/OrderManagerModals/DeleteOrderModal'
import { ExistingModal } from '../Components/ProcurementModals/ExistingModal'
import { CreateOrderModal } from '../Components/OrderManagerModals/CreateOrderModal'
import { OrderInfoModal } from '../Components/OrderManagerModals/OrderInfoModal'
import CollapsibleTable from '../Components/CollapsibleTable'

export function OrderManager () {
    const { vehicle_id } = useParams()
    const [displayData, setDisplayData] = useState([])
    const [error, setError] = useState('')

    const [targetId, setToUpdateId] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    useEffect(() => {
        // console.log('targetId has changed to: ', targetId)
    }, [targetId])

    const [actionAnchorEl, setActionAnchorEl] = useState(null)
    const actionMenuOpen = Boolean(actionAnchorEl)
    const statusMenuOpen = Boolean(anchorEl)

    const [newOrderModalOpen, setNewOrderModalOpen] =
        useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [existModalOpen, setExistModalOpen] = useState(false)

    const [existPartId, setExistPartId] = useState('')

    const ActionMenu = () => {
        return (
            <Menu
                id='action-menu'
                anchorEl={actionAnchorEl}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleActionMenuClose()
                        setInfoModalOpen(!infoModalOpen)
                    }}
                >
                    Info
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleActionMenuClose()
                        setDeleteModalOpen(!deleteModalOpen)
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        )
    }

    const StatusMenu = () => {
        return (
            <Menu
                id='status-menu'
                anchorEl={anchorEl}
                open={statusMenuOpen}
                onClose={handleStatusMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        updateStatus('Declined')
                    }}
                >
                    <ListItemIcon>
                        <Warning color='error' />
                    </ListItemIcon>
                    Declined
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('On Order')
                    }}
                >
                    <ListItemIcon>
                        <Loop color='warning' />
                    </ListItemIcon>
                    On order
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('In Stock')
                    }}
                >
                    <ListItemIcon>
                        <CheckCircle color='success' />
                    </ListItemIcon>
                    In Stock
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('To Order')
                    }}
                >
                    <ListItemIcon>
                        <Pending color='info' />
                    </ListItemIcon>
                    To Order
                </MenuItem>
            </Menu>
        )
    }

    const getOrders = async () => {
        const axiosInstance = axios.create({
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
            },
        });

        try {
            const res = await axiosInstance.get(`${configData.BACKEND_ROOT}${'/orders'}`);
            setDisplayData(res.data);
        } catch (err) {
            if (err.response && err.response.status === 400) return;
            setError(err.response.data.detail);
        }
    };

    useEffect(() => {
        getOrders()
    }, [])
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'string',
            hide: true,
            flex: 1,
        },
        {
            field: 'number',
            headerName: 'Order Number',
            type: 'string',
            flex: 1,
            valueGetter: params => {
                return params.row.number
            },
        },
        {
            field: 'name',
            headerName: 'Order Name',
            flex: 1,
            valueGetter: params => {
                return params.row.name
            },
        },
        {
            field: 'cost',
            headerName: 'Total Cost ($)',
            type: 'number',
            flex: 1,
            valueGetter: params => {
                return parseFloat(
                    (params.row.cost).toFixed(2)
                );
            },
        },
        {
            field: 'est_delivery',
            headerName: 'Estimated Delivery Date',
            type: 'date',
            flex: 1,
            valueGetter: params => {
                return params.row.est_delivery
            },
        },
        {
            field: 'actions',
            maxWidth: 80,
            headerName: 'Actions',
            renderCell: params => {
                return (
                    <IconButton
                        aria-label='delete'
                        size='large'
                        onClick={event => {
                            setActionAnchorEl(event.currentTarget)
                            setToUpdateId(params.row.id)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                )
            },
        },
    ]

    const handleNewOrder = () => {
        setNewOrderModalOpen(!newOrderModalOpen)
    }

    const handleStatusMenuClose = () => {
        setAnchorEl(null)
    }
    const handleActionMenuClose = () => {
        setActionAnchorEl(null)
    }

    const handleDeleteOrderClose = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const handleInfoClose = () => {
        setInfoModalOpen(!infoModalOpen)
    }

    const handleExistModalClose = () => {
        setExistModalOpen(!existModalOpen)
    }

    const updateStatus = newStatus => {
        // Close status menu
        handleStatusMenuClose()

        // TODO updated date changed
        // QUESTION, does backend update date changed at all?

        const body = { status: newStatus }
        axiosInstance
            .patch(
                `${configData.BACKEND_ROOT}/procurement/status/${targetId}`,
                body
            )
            .then(() => {
                const newData = displayData.filter(item => {
                    if (item.id === targetId) item.status = newStatus
                    return item
                })
                setDisplayData(newData)
            })
            .catch(err => setError(err.response.data.detail))
    }

    return (
        <ThemeProvider theme={SunswiftTheme}>
            {/* {existPartId !== '' ? (
                <CreateOrderModal
                    open={newOrderModalOpen}
                    closeFunc={handleNewOrder}
                    state={displayData}
                    stateUpdate={setDisplayData}
                    vehicle_id={vehicle_id}
                    setError={setError}
                    existPartId={existPartId}
                />
            ) : (
                <></>
            )} */}
            <CreateOrderModal
                open={newOrderModalOpen}
                closeFunc={handleNewOrder}
                state={displayData}
                stateUpdate={setDisplayData}
                vehicle_id={vehicle_id}
                setError={setError}
                existPartId={existPartId}
            />
            <ExistingModal
                open={existModalOpen}
                closeFunc={handleExistModalClose}
                setState={setExistPartId}
                nextModalOpen={handleNewOrder}
                vehicle_id={vehicle_id}
            />
            <DeleteOrderModal
                open={deleteModalOpen}
                closeFunc={handleDeleteOrderClose}
                state={displayData}
                stateUpdate={setDisplayData}
                targetId={targetId}
                setError={setError}
            />
            <OrderInfoModal
                open={infoModalOpen}
                closeFunc={handleInfoClose}
                targetId={targetId}
                data={displayData.filter(item => item.id === targetId)}
                setError={setError}
            />
            {error === '' ? (
                <></>
            ) : (
                <Alert severity='error'>ERROR — {error}</Alert>
            )}
            <PageDrawer page='Order Manager' opTool opLogin id={vehicle_id}>
                <CenterContainer size='xl'>
                    <Paper
                        elevation={8}
                        sx={{ width: '100%', margin: '1em auto' }}
                    >
                        <Box width='100%' height='100%'>
                            <StatusMenu />
                            <ActionMenu />
                            <CollapsibleTable
                                rows={displayData}
                                columns={columns}
                                autoHeight='true'
                                density='compact'
                                disableSelectionOnClick
                                disableColumnMenu
                                components={{
                                    // Toolbar: GridToolbar,
                                }}
                            />
                        </Box>
                    </Paper>
                </CenterContainer>
            </PageDrawer>
        </ThemeProvider>
    )
}
