/**
 * DeleteModal.jsx
 *
 * Modal for deleting item from the DB. Since it's standardised via deleting through id, one modal is needed
 *
 * Parameters:
 * - route: Route for the according delete API call
 * - currTable: Name of the current table/item set being deleted from
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */

import React from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    Alert,
} from '@mui/material'

//Delete Modal, conditional based on route, and currTable
const DeleteModal = ({
    route,
    currTable,
    open,
    closeFunc,
    state,
    stateUpdate,
}) => {
    //Delete ID field
    // const [itemId, setItemId] = React.useState('')

    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    //Delete error flag
    const [error, setError] = React.useState('')

    //Handle deleting item from the database
    const handleDelete = async (values) => {
        try {
            //Deleting item from the database
            const res = await axiosInstance.delete(
                `${configData.BACKEND_ROOT}${route}/${values.id}`
            )

            //On success, remove item with id that has been deleted
            const newArray = state.filter((item) => {
                return item.id !== values.id
            })
            stateUpdate(newArray)
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    const validationSchema = yup.object({
        id: yup.string('Enter your ID to delete').required('ID is required'),
    })

    const formik = useFormik({
        initialValues: {
            id: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleDelete(values)
        },
    })

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Delete {currTable}</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please input the ID of the item to be deleted
                        </DialogContentText>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{ marginTop: '1em' }}
                        >
                            <TextField
                                fullWidth
                                id="id"
                                name="id"
                                label="Delete ID"
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id &&
                                    Boolean(formik.errors.id)
                                }
                                helperText={
                                    formik.touched.id && formik.errors.id
                                }
                            />
                            {error === '' ? (
                                <></>
                            ) : (
                                <Alert severity="error">ERROR — {error}</Alert>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            Delete
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default DeleteModal
