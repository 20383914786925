import {
  Alert,
  Button, Chip,
  Dialog, DialogActions,
  DialogContent,
  FormControl,
  Grid, IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem, Select,
  TextField,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Add, Delete, Edit} from "@mui/icons-material";
import * as yup from "yup";
import axios from "axios";
import configData from "../../config.js";
import {useFormik} from "formik";
import {AddProjectComponent} from "./AddProjectComponent";
import {req_types} from "../../Assets/ProjectRequirementData";
// import {axiosInstance} from "../../Utility";


export function EditRequirementModal({req, handleClose, projectData}) {
  const [open, setOpen] = useState(false)
  const [projects, setProjects] = useState([])
  const [addedProjects, setAddedProjects] = useState([])
  const [existingProjects, setExistingProjects] = useState([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  const validationSchema = yup.object({
    name: yup.string('Enter requirement name').required('Requirement name required'),
    priority: yup.string('Requirement priority').required('Priority required'),
    type: yup
      .string('Requirement type')
      .required('Requirement type required'),
    desc: yup.string('Enter requirement description').required('Requirement description required'),
    notes: yup.string('Enter any additional notes'),
  })

  const handleEditRequirement = async (values) => {
    console.log('editing req:', req)
    console.log("requirement id: ", req.id)
    const req_edit = {
      id: req.id,
      type: values.type,
      name: values.name,
      priority: values.priority,
      description: values.desc,
      notes: values.notes,
    }
    // TODO do cleanup checks
    console.log("submitting req: ", req_edit)
    axiosInstance
      .patch(`${configData.BACKEND_ROOT}/requirement/${req.id}`, req_edit)
      .then((res) => {
        console.log("req updated!")
        setOpen(!open)
      })
      .catch((err) => {
        // TODO On error, set error from backend response
        console.log(err.response.data.detail)
      })
  }

  const formik = useFormik({
    initialValues: {name: req.name, priority: req.priority,
      type: req.type, projects: req.projects,
      desc: req.description, notes: req.notes,
      },
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("submitting edit...")
      handleEditRequirement(values)
    },
  })

  const handleAddProject = () => {
    setAddedProjects(addedProjects => [...addedProjects, {project: '', component: ''}])
  }
  const handleRemoveProject = (idx) => {
    idx -= existingProjects.length
    console.log("removing ", idx, "...")
    const newAddedProjects = addedProjects.filter((p, ridx) => idx !== ridx)
    setAddedProjects(newAddedProjects)
  }

  // {project: id, component: id}
  const handleAddProjectComponent = (projectComponent, idx) => {
    const newAddedProjects = addedProjects.map((projComp, sidx) => {
      if (idx !== sidx) return projComp;
      return projectComponent;
    });
    setAddedProjects(newAddedProjects);
  }
  const getProjectNames = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res => {
        console.log('data from get request is: ', res.data)
        const data = res.data.map(p => {
          return {label: p.name, id: p.id, department: p.department}
        })
        setProjects(data)
      })
      .catch(err=>console.log('Err: ', err))
  }

  const handleRemoveInterfacingProject = async (proj_id, kc_id) => {
    if (existingProjects.length > 2) {
      await axiosInstance.put(`${configData.BACKEND_ROOT}/requirement/remove/component`, {
        req_id: req.id,
        kc_id: kc_id
      })
        .then(() => {
          axiosInstance.put(`${configData.BACKEND_ROOT}/project/remove/requirement`, {proj_id: proj_id, req_id: req.id})
            .then(() => {
              console.log('Successfully removed requirement from project')
              const newExistingProjects = existingProjects.filter(p => p.proj_id !== proj_id)
              setExistingProjects(newExistingProjects)
            })
            .catch(err => console.log('Error removing requirement from project ', err))
        }).catch(err => console.log('Error removing requirment from key component ', err))
    }
    else {
      setAlert(true)
      setAlertMessage("An interfacing requirement cannot be assigned to less than 2 projects. Please add a project before removing another.")
    }
  }

  const updateExistingProjects = (newProjectComponent) => {
    setExistingProjects(existingProjects => [...existingProjects, newProjectComponent])
    setAddedProjects([])
  }

  useEffect(() => {
    console.log('Added  projects: ', addedProjects)
    formik.setFieldValue('projects', addedProjects)
  }, [addedProjects])

  useEffect(() => {
    if (alert) {
      setTimeout(() => {setAlert(false)}, 20000)
    }
  }, [alert])

  useEffect(() => {
    if (open) {
      console.log('Requiremennt data is: ', req)
      const data = req.keyComponents.map(kc=> {
        const proj_name = req.projects.find(p=>p.id === kc.proj_id).name
        return {proj_id: kc.proj_id, proj_name: proj_name, kc_id: kc.id, kc_name: kc.name}
      })
      console.log('setting existig proj to: ', data)
      setExistingProjects(data)
    }
  }, [open])

  useEffect(() => {
    getProjectNames()
  }, [existingProjects])
  return (
    <>
      <Dialog open={open} onClose={() => handleClose(true)}>
        <form onSubmit={formik.handleSubmit}>
        <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Typography variant="h6">Requirement</Typography>
              </Grid>
              { alert && <Alert severity="error" onClose={() => setAlert(false)}>{alertMessage}</Alert> }
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Requirement Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    id="priority"
                    name="priority"
                    label="Priority"
                    value={formik.values.priority}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.priority &&
                      Boolean(formik.errors.priority)
                    }
                    helperText={
                      formik.touched.priority &&
                      formik.errors.priority
                    }
                  >
                    <MenuItem value={'High'}>
                      High
                    </MenuItem>
                    <MenuItem value={'Medium'}>
                      Medium
                    </MenuItem>
                    <MenuItem value={'Low'}>
                      Low
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Requirement Type</InputLabel>
                  <Select
                    id="type"
                    name="type"
                    label="Requirement Type"
                    value={formik.values.type}
                    disabled={req.type === "Interfacing"}
                    onChange={(e) => {
                      formik.handleChange(e)
                      if (e.target.value === "Interfacing") {
                        handleAddProject()
                      } else if (addedProjects.length > 1) {
                        setAddedProjects(addedProjects => [addedProjects[0]])
                      }
                    }}
                    error={
                      formik.touched.type &&
                      Boolean(formik.errors.type)
                    }
                    helperText={
                      formik.touched.type &&
                      formik.errors.type
                    }
                  >
                    {req_types.map(r=> {
                      return <MenuItem value={r}>{r}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Assigned projects
                </Typography>
              </Grid>
              { req.type === 'Interfacing' ?
                existingProjects.map((p, idx) => {
                  return (
                  <Grid item container xs={12}>
                    <Grid item xs={5}>
                      {p.proj_name}
                    </Grid>
                    <Grid item xs={5}>
                      <Chip label={p.kc_name}/>
                    </Grid>
                    <IconButton variant="contained" size="small" onClick={() => handleRemoveInterfacingProject(p.proj_id, p.kc_id)}><Delete/></IconButton>
                  </Grid>
                  )
                }) :
                existingProjects.map((p, idx) => {
                    return (
                        <AddProjectComponent
                          allProjects={[]}
                          idx={idx}
                          isEdit={true}
                          editData={existingProjects}
                          isInterfacing={req.type === 'Interfacing'}
                          // handleRemove={handleRemoveProject}
                          addProjectComponent={handleAddProjectComponent}
                          req_id={req.id}
                        />
                      )
                  })
              }
              {
                addedProjects.map((p, idx) => {
                  idx = idx + existingProjects.length
                  return (
                    <AddProjectComponent
                      allProjects={projects.filter(p=> {
                        return !existingProjects.some(e => e.proj_id === p.id)
                      })}
                      idx={idx}
                      handleRemove={handleRemoveProject}
                      addProjectComponent={handleAddProjectComponent}
                      isEdit={true}
                      isNew={true}
                      update={updateExistingProjects}
                      req_id={req.id}
                    />
                  )
                })
              }
              {
                formik.values.type === "Interfacing" &&
                <Grid item xs={6}><Button
                  variant="contained"
                  size="small"
                  startIcon={<Add/>}
                  onClick={handleAddProject}
                >Add project</Button></Grid>
              }
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="desc"
                  name="desc"
                  label="Requirement description"
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.desc &&
                    Boolean(formik.errors.desc)
                  }
                  helperText={
                    formik.touched.desc &&
                    formik.errors.desc
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="notes"
                  name="notes"
                  label="Additional notes"
                  value={formik.values.notes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.notes &&
                    Boolean(formik.errors.notes)
                  }
                  helperText={
                    formik.touched.notes &&
                    formik.errors.notes
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Update requirement
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <MenuItem onClick={() => {
        setOpen(!open)
      }}>
        <ListItemIcon>
          <Edit/>
        </ListItemIcon>
        Edit requirement
      </MenuItem>
    </>
  )
}