const dev = {
  BACKEND_ROOT: "http://127.0.0.1:8000",
  PATCH_NOTES: "https://itty.bitty.site/Sunswift-ERP-V1.0.1/#/?eNqFU11vWjEM/StuH6ZNasv62jEkJrUS0qgQrKOvIdf3kpGbpHHCx7+fHS6Uq03bWxI75xwf28MwWqLVvkVIHg4+R3C4A+8QfA0KNsZVsMiOdqZO8OgSxhANIcyROFkjzKxyzrgGFgdK2F7Bx8XjfPZpOAijYRiN3aEA1qhSjkg3sMoN1GaPBIqhg0p6zeedsRZWCM4nrJge0toQRxu86pCWCMqSB+O0zRUnKYheVa0KoBKnIyDjUSkCNs7vYLeWgAfcB9QJqhxFpmTmwBXLJaJFRUgnkuEqjrrjU6f4odyzHQ2tGc1UTDBVjnXFB/jhRbJa2WLeBjFAikpvxDrJ5Gqnih0zLPwGvnutkvGOj6qYGoI1GGk4YOAz+Cx6zbQtulQI2kJWVIcSEtmM32abTGDmIEQFkRIrJgnKG/hYoWT38cdEpnFn+K3h5sjnjqd41kFG5PKELhPbXfv4Hn3HjP6XmDvHt2yOsqkA992IF+FCx/WYCFsWWJujLYUgHOHog1tR+HJkGYj3vdb8vL/7fHcPL6Fid/sNmrQMsWW5L5PBy+t/AL7lhuBJZrGH8cyWcMmRZBo7CyJWrF/LcHKrpRsRG0Nc4El7C6rmXoP1TSM/jOOhTmsYLxdntyauNs4k5CRVSdJujQ6U1shd4WtXfpn786epoVtluWnCjfsk29Hzs5c9jnptxICTlaWPsg4RlWyWPcClLaCtIvp6/WaPJLcaZcmv/7IKMD/u2x8L8ZzbFVcufT3NQ/fEItpwXj3NEsSuy+mpTZnhBv/V898B4aL6",
  AUTH_CLIENT_ID: '1n3sj0o831lh0pr4p6qfe77r21',
  AUTH_USER_POOL: 'ap-southeast-2_G9Fio5nl2',
  AUTH_CLIENT_SECRET: '7a5rp4n4n9c4fqquairlljegg7389k0t8lccnij3a68aats0783',
  REDIRECT_URL: 'http://localhost:3000/Login',
  BUG_REPORT: 'https://forms.office.com/r/uqgAhWhEmL',
  FEEDBACK: 'https://forms.office.com/r/2fStSh5KRd'
}
const prod = {
  BACKEND_ROOT: "https://api.internaltools.sunswift.com",
  PATCH_NOTES: "https://itty.bitty.site/Sunswift-ERP-V1.0.1/#/?eNqFU11vWjEM/StuH6ZNasv62jEkJrUS0qgQrKOvIdf3kpGbpHHCx7+fHS6Uq03bWxI75xwf28MwWqLVvkVIHg4+R3C4A+8QfA0KNsZVsMiOdqZO8OgSxhANIcyROFkjzKxyzrgGFgdK2F7Bx8XjfPZpOAijYRiN3aEA1qhSjkg3sMoN1GaPBIqhg0p6zeedsRZWCM4nrJge0toQRxu86pCWCMqSB+O0zRUnKYheVa0KoBKnIyDjUSkCNs7vYLeWgAfcB9QJqhxFpmTmwBXLJaJFRUgnkuEqjrrjU6f4odyzHQ2tGc1UTDBVjnXFB/jhRbJa2WLeBjFAikpvxDrJ5Gqnih0zLPwGvnutkvGOj6qYGoI1GGk4YOAz+Cx6zbQtulQI2kJWVIcSEtmM32abTGDmIEQFkRIrJgnKG/hYoWT38cdEpnFn+K3h5sjnjqd41kFG5PKELhPbXfv4Hn3HjP6XmDvHt2yOsqkA992IF+FCx/WYCFsWWJujLYUgHOHog1tR+HJkGYj3vdb8vL/7fHcPL6Fid/sNmrQMsWW5L5PBy+t/AL7lhuBJZrGH8cyWcMmRZBo7CyJWrF/LcHKrpRsRG0Nc4El7C6rmXoP1TSM/jOOhTmsYLxdntyauNs4k5CRVSdJujQ6U1shd4WtXfpn786epoVtluWnCjfsk29Hzs5c9jnptxICTlaWPsg4RlWyWPcClLaCtIvp6/WaPJLcaZcmv/7IKMD/u2x8L8ZzbFVcufT3NQ/fEItpwXj3NEsSuy+mpTZnhBv/V898B4aL6",
  AUTH_CLIENT_ID: '1n3sj0o831lh0pr4p6qfe77r21',
  AUTH_USER_POOL: 'ap-southeast-2_G9Fio5nl2',
  AUTH_CLIENT_SECRET: '7a5rp4n4n9c4fqquairlljegg7389k0t8lccnij3a68aats0783',
  REDIRECT_URL: 'https://www.internaltools.sunswift.com/login',
  BUG_REPORT: 'https://forms.office.com/r/uqgAhWhEmL',
  FEEDBACK: 'https://forms.office.com/r/2fStSh5KRd'
}

export default process.env.NODE_ENV === 'development' ? dev : prod
