/**
 * PageDrawer.jsx
 *
 * Drawer for all pages, render conditionally based on expected state in each page (see *Options components).
 * Wraps all the logic of every other page
 *
 * Parameters:
 * - page: name of the currently displayed page
 * - width: Wiidth in pixels of the drawer
 * - opTool: Boolean to display tooling
 * - opLogin: Boolean to display logging nav links or logged out nav links
 */

import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { Typography, Box } from '@mui/material'
import DrawerItem from './DrawerItem'

import ConstructionIcon from '@mui/icons-material/Construction'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import BugReportIcon from '@mui/icons-material/BugReport'
import ChatIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NoteAltIcon from '@mui/icons-material/NoteAlt'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { useNavigate } from 'react-router-dom'
import configData from '../config.js'
import { useState, useEffect } from 'react'

//Drawer items for displaying tooling nav links
const ToolingOptions = ({ id }) => {
    const navigate = useNavigate()

    return (
        <>
            <List sx={{ padding: '0!important' }}>
                <DrawerItem
                    text='Parts Manager'
                    func={() => {
                        navigate(`/PartManager/${id}`)
                    }}
                >
                    <ConstructionIcon />
                </DrawerItem>
                <DrawerItem
                    text='Parts Assignment'
                    func={() => {
                        navigate(`/PartAssignment/${id}`)
                    }}
                >
                    <FactCheckIcon />
                </DrawerItem>
                <DrawerItem
                    text='Parts Procurement'
                    func={() => {
                        navigate(`/PartProcurement/${id}`)
                    }}
                >
                    <LocalShippingIcon />
                </DrawerItem>
                <DrawerItem
                    text='Order History'
                    func={() => {
                        navigate(`/OrderManager/${id}`)
                    }}
                >
                    <ShoppingCartIcon />
                </DrawerItem>
                {
                    <DrawerItem
                        text='Department Projects'
                        func={() => {
                            navigate(`/DepartmentProjects/${id}`)
                        }}
                    >
                        <LibraryAddCheckIcon />
                    </DrawerItem>
                }
            </List>
        </>
    )
}

//Drawer items for displaying nav links for users logged out
const LoggedOutOptions = () => {
    const navigate = useNavigate()

    return (
        <>
            <List sx={{ padding: '0!important' }}>
                <DrawerItem
                    text='Login'
                    func={() => {
                        navigate('/Login')
                    }}
                >
                    <LoginIcon />
                </DrawerItem>
                {/*<DrawerItem*/}
                {/*    text="Registration"*/}
                {/*    func={() => {*/}
                {/*        navigate('/Registration')*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <AttributionIcon />*/}
                {/*</DrawerItem>*/}
            </List>
        </>
    )
}

//Drawer items for displaying nav links for users logged in
const LoggedInOptions = () => {

    return (
        <>
            <List sx={{ padding: '0!important' }}>
                {/* <DrawerItem
                    text="Profile (In Progress)"
                    func={() => {
                        navigate('/Profile')
                    }}
                >
                    <AccountBoxIcon />
                </DrawerItem> */}
                <DrawerItem
                    text='Logout'
                    func={() => {
                        sessionStorage.removeItem('curr_zid')
                        sessionStorage.removeItem('curr_code')
                        sessionStorage.removeItem('temp_email')
                        sessionStorage.removeItem('user_groups')
                        sessionStorage.removeItem('access_token')
                        const uri = encodeURIComponent(configData.REDIRECT_URL)
                        window.location.replace(
                            `https://sunswift.auth.ap-southeast-2.amazoncognito.com/logout?response_type=code&client_id=1n3sj0o831lh0pr4p6qfe77r21&redirect_uri=${uri}`
                        )
                    }}
                >
                    <LogoutIcon />
                </DrawerItem>
            </List>
        </>
    )
}

const FeedbackOptions = () => {
    const navigate = useNavigate()
    return (
        <>
            <List sx={{ padding: '0!important' }}>
                <DrawerItem
                    text='Patch Notes'
                    func={() => {
                        // window.open(configData['PATCH_NOTES'])
                        navigate('/PatchNotes')
                    }}
                >
                    <NoteAltIcon />
                </DrawerItem>
                <DrawerItem
                    text='Bug Report'
                    func={() => {
                        window.open(configData['BUG_REPORT'])
                    }}
                >
                    <BugReportIcon />
                </DrawerItem>
                <DrawerItem
                    text='Feedback'
                    func={() => {
                        window.open(configData['FEEDBACK'])
                    }}
                >
                    <ChatIcon />
                </DrawerItem>
            </List>
        </>
    )
}

const ProjectDrawer = ({
    page = "",
    width = 300,
    opTool = false,
    opLogin = false,
    id,
    children,
}) => {
    const drawerWidth = width
    const [sidebarShow, setSidebarShow] = useState(false)
    const [drawerVariant, setDrawerVariant] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        if (window.innerWidth >= 1000) {
            setSidebarShow(true)
            setDrawerVariant('permanent')
        } else {
            setDrawerVariant('temporary')
        }
    }, [])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    backgroundImage: !opLogin
                        ? 'url(/background2.png)'
                        : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                }}
            >
                <AppBar
                    position='fixed'
                    sx={{ zIndex: theme => theme.zIndex.drawer + 1, maxWidth: '100vw' }}
                >
                    <Toolbar
                        sx={{
                            backgroundColor: '#fcfcfc',
                            borderBottom: '1px solid #e0e0e0',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <a href='https://www.sunswift.com/'>
                                <img
                                    src='/sunswift.png'
                                    alt='Sunswift Logo'
                                    className='w-[40px] md:w-[60px] mr-[10px]'
                                />
                            </a>
                        </Box>
                        <Typography variant='h6' noWrap component='div'>
                            {page !== '' ? `${page}: ${id}` : ''}
                        </Typography>
                        {
                            drawerVariant === 'temporary' &&
                            <div
                                onClick={() => {
                                    console.log("burger activated")
                                    console.log(sidebarShow)
                                    setSidebarShow(prevSidebarShow => !prevSidebarShow)
                                }}
                            >
                                {
                                    sidebarShow
                                    ? <MenuOpenIcon />
                                    : <MenuIcon />

                                }
                            </div>
                        }
                        {/* <Typography variant='h6' noWrap component='div'>
                            {page !== '' ? `${page}: ${id}` : ''}
                        </Typography> */}
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    // variant='temporary'
                    // variant='persistent'
                    variant={drawerVariant}
                    anchor='left'
                    open={sidebarShow}
                >
                    <Toolbar />
                    <Divider />
                    <List sx={{ padding: '0!important' }}>
                        <ListItemButton
                            sx={{
                                '&:hover': {
                                    color: 'black',
                                },
                                padding: '12px'
                            }}
                            onClick={() => {
                                navigate(`/Vehicles`)
                            }}
                        >
                            <ListItemIcon sx={{ color: 'black' }}>
                                <DirectionsCarIcon />
                            </ListItemIcon>
                            <ListItemText primary='Vehicles' />
                            <ListItemIcon sx={{
                                minWidth: '26px!important', color: 'black'
                            }}>
                                <ArrowBackIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </List>
                    <Divider />
                    {opTool ? <ToolingOptions id={id} /> : <></>}
                    <Divider />
                    <FeedbackOptions />
                    <Divider />
                    {opLogin ? <LoggedInOptions /> : <LoggedOutOptions />}
                    <Divider />
                    <List
                        subheader={
                            <ListSubheader component='div'>
                                Sunswift Internal Tools V2.0.2
                            </ListSubheader>
                        }
                        sx={{ padding: '0!important' }}
                    />
                </Drawer>
                <Box component='main' sx={{ flexGrow: 1, p: 3, maxWidth: '100vw' }}>
                    {children}
                </Box>
            </Box>
        </>
    )
}

export default ProjectDrawer
