/**
 * Profile page
 *
 * Display all the details of the currently logged in user
 * Conveniently contains all the information of things the user have ordered
 */

import React from 'react'
import PageDrawer from '../Components/PageDrawer'
import CenterContainer from '../Components/CenterContainer'

const Profile = () => {
    return (
        <PageDrawer page="Profile" opTool opLogin>
            <CenterContainer size="md">
                <h1>PROFILE (IN PROGRESS)</h1>
            </CenterContainer>
        </PageDrawer>
    )
}

export default Profile
