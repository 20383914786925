/**
 * PartManager Page
 *
 * Display the grid containing all the necessary information for each part in the project
 */

import React from 'react'
import CenterContainer from '../Components/CenterContainer.jsx'

import {
    PartColumns,
    LocationColumns,
    SupplierColumns,
    MaterialColumns,
} from '../Assets/PartColumns.jsx'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import {
    Button,
    Grid,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Box,
    Menu,
    ListItemIcon,
    InputLabel,
} from '@mui/material'

import {
    AddSupplierModal,
    AddLocationModal,
    AddMaterialModal,
    AddPartModal,
} from '../Components/PartManagerModals/AddModals'
import {
    EditSupplierModal,
    EditLocationModal,
    EditMaterialModal,
    EditPartModal,
} from '../Components/PartManagerModals/EditModals'
import DeleteModal from '../Components/PartManagerModals/DeleteModal'

import { useParams } from 'react-router-dom'
import PageDrawer from '../Components/PageDrawer'
import configData from '../config.js'
import axios from 'axios'
import AssignModal from '../Components/PartManagerModals/AssignModal.jsx'
import UnassignModal from '../Components/PartManagerModals/UnassignModal.jsx'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { checkPerms, deleteAccess } from '../Utility'

const PartManager = () => {
    const [partData, setPartData] = React.useState([])
    const { vehicle_id } = useParams()
    const [displayData, setDisplayData] = React.useState([])
    const [displayTable, setDisplayTable] = React.useState(PartColumns)
    const [displayRoute, setDisplayRoute] = React.useState(`/parts/${vehicle_id}/vehicles`)
    const [currTable, setCurrTable] = React.useState('Parts')

    const [addModalOpen, setAddModalOpen] = React.useState(false)
    const [editModalOpen, setEditModalOpen] = React.useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
    const [assignModalOpen, setAssignModalOpen] = React.useState(false)
    const [unassignModalOpen, setUnassignModalOpen] = React.useState(false)

    const [itemSearch, setItemSearch] = React.useState('')
    const [searchData, setSearchData] = React.useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null)

    const columnsMap = {
        Parts: PartColumns,
        Locations: LocationColumns,
        Suppliers: SupplierColumns,
        Materials: MaterialColumns,
    };

    const open = Boolean(anchorEl)
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const getPartData = async () => {
        try {
            const res = await axiosInstance.get(
                `${configData.BACKEND_ROOT}${displayRoute}`
            )
            const data = res.data
            setDisplayData(res.data)
        } catch (error) {
            console.log('Error fetching part data: ', error)
            return [] // Return an empty array in case of an error
        }
    }

    React.useEffect(() => {
        getPartData()
    }, [])

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAddModal = () => {
        setAddModalOpen(!addModalOpen)
    }
    const handleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }
    const handleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }
    const handleAssignModal = () => {
        setAssignModalOpen(!assignModalOpen)
    }
    const handleUnassignModal = () => {
        setUnassignModalOpen(!unassignModalOpen)
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleDisplayTable = async e => {
        const route = e.target.value
        const match = route.match(/^\/([^/]+)/);
        let table = match && match[1];
        table = capitalizeFirstLetter(table)

        setCurrTable(table)
        setDisplayTable(columnsMap[table])
        setDisplayRoute(route)

        const res = await axiosInstance.get(
            `${configData.BACKEND_ROOT}${route}`
        )
        const data = res.data
        setDisplayData(res.data)
    }

    const handleSearch = async () => {
        try {
            const res = await axiosInstance.get(
                `${configData.BACKEND_ROOT}${displayRoute}/${itemSearch}/part`
            )
            setSearchData(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
             {currTable === 'Parts' ? (
                 <>
                     <AddPartModal
                         open={addModalOpen}
                         closeFunc={handleAddModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                         vehicle_id={vehicle_id}
                     />
                     <EditPartModal
                         open={editModalOpen}
                         closeFunc={handleEditModal}
                         vehicle_id={vehicle_id}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                 </>
             ) : (
                 <></>
             )}
             {currTable === 'Locations' ? (
                 <>
                     <AddLocationModal
                         open={addModalOpen}
                         closeFunc={handleAddModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                     <EditLocationModal
                         open={editModalOpen}
                         closeFunc={handleEditModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                 </>
             ) : (
                 <></>
             )}
             {currTable === 'Suppliers' ? (
                 <>
                     <AddSupplierModal
                         open={addModalOpen}
                         closeFunc={handleAddModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                     <EditSupplierModal
                         open={editModalOpen}
                         closeFunc={handleEditModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                 </>
             ) : (
                 <></>
             )}
             {currTable === 'Materials' ? (
                 <>
                     <AddMaterialModal
                         open={addModalOpen}
                         closeFunc={handleAddModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                     <EditMaterialModal
                         open={editModalOpen}
                         closeFunc={handleEditModal}
                         state={displayData}
                         stateUpdate={setDisplayData}
                     />
                 </>
             ) : (
                 <></>
             )}
             <DeleteModal
                 route={displayRoute}
                 currTable={currTable}
                 open={deleteModalOpen}
                 closeFunc={handleDeleteModal}
                 state={displayData}
                 stateUpdate={setDisplayData}
             />
             <AssignModal
                 open={assignModalOpen}
                 closeFunc={handleAssignModal}
                 vehicle_id={vehicle_id}
             />
             <UnassignModal
                 open={unassignModalOpen}
                 closeFunc={handleUnassignModal}
                 vehicle_id={vehicle_id}
             />
             <PageDrawer page='Parts Manager' opTool opLogin id={vehicle_id}>
                 <CenterContainer size='xl'>
                     <Paper
                         elevation={8}
                         sx={{ width: '100%', margin: '1em auto' }}
                     >
                         <Grid
                             container
                             spacing={2}
                             sx={{ margin: '1em' }}
                             rowSpacing={1}
                             alignItems='center'
                         >
                             <Grid item xs={5} sx={{ display: 'flex-start' }}>
                                 <FormControl fullWidth>
                                     <InputLabel>Table</InputLabel>
                                     <Select
                                         value={displayRoute}
                                         label="Table"
                                         onChange={handleDisplayTable}
                                     >
                                         <MenuItem
                                             value={`/parts/${vehicle_id}/vehicles`}
                                             label="Parts"
                                         >
                                             Parts
                                         </MenuItem>
                                         <MenuItem
                                             value={'/locations'}
                                         >
                                             Locations
                                         </MenuItem>
                                         <MenuItem
                                             value={'/suppliers'}
                                         >
                                             Suppliers
                                         </MenuItem>
                                         <MenuItem
                                             value={'/materials'}
                                         >
                                             Materials
                                         </MenuItem>
                                     </Select>
                                 </FormControl>
                             </Grid>
                             <Grid item xs={2} sx={{ display: 'flex-end' }}>
                                 <Button
                                     onClick={handleClick}
                                     variant='contained'
                                     endIcon={<KeyboardArrowDownIcon />}
                                 >
                                     Actions
                                 </Button>
                                 <Menu
                                     open={open}
                                     onClose={handleClose}
                                     anchorEl={anchorEl}
                                 >
                                     <MenuItem
                                         onClick={() => {
                                             handleClose()
                                             handleAddModal()
                                         }}
                                     >
                                         <ListItemIcon>
                                             <AddIcon fontSize='small' />
                                         </ListItemIcon>
                                         Add {currTable}
                                     </MenuItem>
                                     <MenuItem
                                         onClick={() => {
                                             handleClose()
                                             handleEditModal()
                                         }}
                                     >
                                         <ListItemIcon>
                                             <EditIcon fontSize='small' />
                                         </ListItemIcon>
                                         Edit {currTable}
                                     </MenuItem>
                                     {checkPerms(deleteAccess) && (
                                         <MenuItem
                                             onClick={() => {
                                                 handleClose()
                                                 handleDeleteModal()
                                             }}
                                         >
                                             <ListItemIcon>
                                                 <DeleteIcon fontSize='small' />
                                             </ListItemIcon>
                                             Delete {currTable}
                                         </MenuItem>
                                     )}
                                     {currTable === 'Parts' ? (
                                         <>
                                             <MenuItem
                                                 onClick={() => {
                                                     handleClose()
                                                     handleAssignModal()
                                                 }}
                                             >
                                                 <ListItemIcon>
                                                     <ArrowForwardIosIcon fontSize='small' />
                                                 </ListItemIcon>
                                                 Assign {currTable}
                                             </MenuItem>
                                             <MenuItem
                                                 onClick={() => {
                                                     handleClose()
                                                     handleUnassignModal()
                                                 }}
                                             >
                                                 <ListItemIcon>
                                                     <ArrowBackIosIcon fontSize='small' />
                                                 </ListItemIcon>
                                                 Unassign {currTable}
                                             </MenuItem>
                                         </>
                                     ) : (
                                         <></>
                                     )}
                                 </Menu>
                             </Grid>
                         </Grid>
                         {/* </Grid> */}
                         <Box width='100%' height='100%'>
                             <DataGrid
                                 // experimentalFeatures={{ newEditingApi: true }}
                                 rows={displayData}
                                 columns={displayTable}
                                 autoHeight='true'
                                 density='compact'
                                 // checkboxSelection
                                 disableSelectionOnClick
                                 disableColumnMenu
                                 components={{
                                     Toolbar: GridToolbar,
                                 }}
                             />
                         </Box>
                     </Paper>
                 </CenterContainer>
             </PageDrawer>
   </>
    )
}

export default PartManager
