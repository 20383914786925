import React, { useState, useEffect } from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material'
import {Add} from "@mui/icons-material";
// import {axiosInstance} from "../../Utility";

export function CreateProjectModal({setUpdate, deptUpdate}) {
  const [open, setOpen] = useState(false)
  const [depts, setDepts] = useState([])
  const [allProjects, setAllProjects] = useState([])
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const validationSchema = yup.object({
    name: yup.string('Enter project name')
      .required('Project name required')
      .test('unique', 'Project name must be unique',
        function(value) {
          return !allProjects.find(p=>p.name === value)
        }),
    dept_id: yup
      .number('Enter department')
      .required('Department required'),
    description: yup.string('Enter project description').required('Project description required')
  })

  const getAllProjects = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res=> {
        console.log('all projects: ', res.data)
        setAllProjects(res.data)
      })
      .catch(err => {
        console.log('Error getting proj list: ', err)
      })
  }
  useEffect(() => {
    getAllProjects()
  },[])


  const generateProjId = async () => {
    return axiosInstance.get(`${configData.BACKEND_ROOT}/project/latest`)
      .then(res => {
        let id_num = parseInt(res.data.id.replace(/\D/g, '')) + 1
        id_num = id_num.toString().padStart(3, '0')
        console.log("id num: ", id_num)
        return  "SRP-" + id_num
      })
      .catch(err => {
        console.log("Error retrieving latest project: ", err)
      })
  }
  const handleCreateProject = async (values) => {
    console.log('creating...')
    const proj_id = await generateProjId()
    const proj = {id: proj_id, archived: false, ...values}
    console.log('Creating project with values: ', proj)
    await axiosInstance.post(`${configData.BACKEND_ROOT}/projects`,proj).then(res => {
      console.log("project created successfully: ", res)
    }).catch(err => {
      console.log("Error creating project: ", err)
    });
    axiosInstance.post(`${configData.BACKEND_ROOT}/keyComponent`, {name: 'Overall', proj_id: proj_id}).then(res => {
      console.log("default overall key component created!")
      setUpdate({dept_id: values.dept_id})

      // Make sure next project doesnt have dup name
      getAllProjects();
      setOpen(!open)
    }).catch( err => {
      console.log("Error creating key component: ", err )
    })

  }

  const getDepartments = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/departments`)
      .then(res => {
        console.log('Getting departments...')
        setDepts(res.data)
      })
      .catch(err => {
        console.log("Error getting departments: ", err)
      })
  }
  useEffect(() => {if (depts.length < 1) getDepartments()}, [])
  useEffect(() => {if (deptUpdate) getDepartments()}, [deptUpdate])

  const formik = useFormik({
    initialValues: {name: '', description: '', dept_id: 1},
      validationSchema: validationSchema,
      validateOnChange: false,
      enableReinitialize: true,
      onSubmit: (values) => {
        handleCreateProject(values)
      },
  })

  return (
    <>
      <Dialog open={open} onClose={()=> setOpen(!open)}>
        <DialogTitle>Create new project</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Project Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.name &&
                    Boolean(formik.errors.name)
                  }
                  helperText={
                    formik.touched.name &&
                    formik.errors.name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Project Department</InputLabel>
                  <Select
                    id="dept_id"
                    name="dept_id"
                    label="Project Department"
                    value={formik.values.dept_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dept_id &&
                      Boolean(formik.errors.dept_id)
                    }
                    helperText={
                      formik.touched.dept_id &&
                      formik.errors.dept_id
                    }
                  >
                    {
                      depts.map((d, idx) => {
                        return (<MenuItem key={idx} value={d.id}>{d.name}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  id="description"
                  name="description"
                  label="Project Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description &&
                    formik.errors.description
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Create project
            </Button>
            <Button variant="contained" onClick={() => setOpen(!open)}>
              Cancel
            </Button>
          </DialogActions>
        </form>

      </Dialog>
      <Button variant="contained" onClick={() => setOpen(!open)}  startIcon={<Add />}>
        New Project
      </Button>
    </>
  )
}