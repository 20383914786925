/**
 * Finance Page
 *
 * Display graphs and charts necessary for better viewing of team finances
 */

import React from 'react'
import PageDrawer from '../Components/PageDrawer'
import CenterContainer from '../Components/CenterContainer'
import { useParams } from 'react-router-dom'
const Finances = () => {
    const { vehicle_id } = useParams()
    return (
        <PageDrawer page="Part Status" opTool opLogin id={vehicle_id}>
            <CenterContainer size="md">
                <h1>Finance (IN PROGRESS)</h1>
            </CenterContainer>
        </PageDrawer>
    )
}

export default Finances
