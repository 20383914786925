/**
 * FormContainer
 *
 * Container for form input boxes, provide consistent styling
 *
 * Parameters:
 * - children: JS default keyword to allow for children
 */

import { Box, Container } from '@mui/material'
import Paper from '@mui/material/Paper'

const FormContainer = ({ children }) => {
    return (
        <Container
            sx={{
                padding: '2vw 0px 2vw 0px',
                borderRadius: '10px',
            }}
        >
            <Paper elevation={12} sx={{ padding: '2vw', borderRadius: '20px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItem: 'center',
                        textAlign: 'center',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    {children}
                </Box>
            </Paper>
        </Container>
    )
}
export default FormContainer
