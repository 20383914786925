import React, { useState, useEffect } from 'react';
import {
  CheckCircle,
  Warning,
  Loop,
  Pending,
} from '@mui/icons-material'
import {
  Chip,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Paper,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import axios from 'axios';
import configData from '../config.js';

const ExpandedRowContent = ({ orderId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [procurements, setProcurements] = useState(null);

  const getChipProps = (status) => {
    if (status === 'To Order') {
        return {
            icon: <Pending sx={{ fill: 'info' }} />,
            label: status,
            color: 'info'
        }
    } else if (status === 'On Order') {
        return {
            icon: <Loop sx={{ fill: 'warning' }} />,
            label: status,
            color: 'warning'
        }
    } else if (status === 'In Stock') {
        return {
            icon: <CheckCircle sx={{ fill: 'success' }} />,
            label: status,
            color: 'success',
        }
    } else if (status === 'Declined') {
        return {
            icon: <Warning sx={{ fill: 'error' }} />,
            label: status,
            color: 'error'
        }
    }
}

  useEffect(() => {
    const axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const orderResponse = await axiosInstance.get(`${configData.BACKEND_ROOT}/orders/procurement/${orderId}`);
        setProcurements(orderResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId]);

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : procurements ? (
        // If data is received, display it
        <TableContainer component={Paper} sx={{ maxWidth: '90vw' }}>
      <Table className="border-collapse">
        <TableHead>
          <TableRow>
            <TableCell>Part Name</TableCell>
            <TableCell>Part Number</TableCell>
            <TableCell>QTY Ordered</TableCell>
            <TableCell>Total Cost ($)</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {procurements.map((proc) => (
            <TableRow key={proc.id}>
              <TableCell>{proc.part_details.name}</TableCell>
              <TableCell>{proc.part_details.id}</TableCell>
              <TableCell>{proc.part_details.stock_qty}</TableCell>
              <TableCell>{proc.part_details.stock_qty * proc.part_details.cost_per_unit}</TableCell>
              <TableCell><Chip variant='outlined' {...getChipProps(proc.status)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      ) : (
        // If no data is available, display a message
        <h1>No data available</h1>
      )}
    </div>
  );
};

const CollapsibleTable = ({ rows, columns }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  console.log(rows)
  const handleRowExpand = (rowId) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows([...expandedRows, rowId]);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '90vw' }}>
      <Table className="border-collapse">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {columns.map((column) =>
              column.field !== 'id' ? (
                <TableCell
                  key={column.field}
                  align={column.align}
                  sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
                >
                  {column.headerName}
                </TableCell>
              ) : null
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow className="border">
                <TableCell align="center">
                  <IconButton
                    onClick={() => handleRowExpand(index)}
                    className="text-gray-500"
                  >
                    {expandedRows.includes(index) ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
                {columns.map((column) => (
                  column.field !== 'id' ? (
                    <TableCell key={column.field} align={column.align}>
                      {column.field !== 'actions' ? (
                        row[column.field]
                      ) : (
                        column.renderCell({ row })
                      )}
                    </TableCell>
                  ) : null
                ))}
              </TableRow>
              {expandedRows.includes(index) && (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <ExpandedRowContent orderId={row['id']} />
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
