/**
 * EditModals.jsx
 *
 * Collection of modals which are used to edit data into the database via the backend
 *
 * Parameters:
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 */

import React from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Alert,
} from '@mui/material'

//Removes any fields that were left empty //IT NO WORK
const emptyFieldRemoval = (obj) => {
    let reduceObj = {}
    const entries = Object.entries(obj)
    for (const [key, value] of entries) {
        if (value !== '') {
            reduceObj[key] = value
        }
    }
    return reduceObj
}
const replaceItem = (replObj, state, stateUpdate) => {
    //Find the item with the same id
    let new_state = state.filter((obj) => {
        return obj.id !== replObj.id
    })
    stateUpdate([new_state, replObj])
}

//Supplier Edit Modal
export const EditSupplierModal = ({
    open,
    closeFunc,
    state,
    stateUpdate
}) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const validationSchema = yup.object({
        id: yup.string('Enter supplier id')
            .matches(
                '(^SUPP_[0-9]+$|None)',
                'Have to match the format: SUPP_[number] or "None"'
            )
            .required('Supplier id required'),
        name: yup.string('Enter supplier name'),
        contact: yup.string('Enter supplier contact'),
        email: yup.string('Enter supplier email'),
        desc: yup.string('Enter any descriptions/comments')
    })

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            contact_num: '',
            supp_email: '',
            comments: ''
        },
        validationSchema: validationSchema,
        // validateOnChange: true,
        onSubmit: (values) => {
            handleEditSupplier(values)
        }
    })

    const [error, setError] = React.useState('')

    //Handle editting supplier in the database
    const handleEditSupplier = async (values) => {
        try {
            let edit_supp = {
                name: values.name,
                contact_num: values.contact_num,
                supp_email: values.supp_email,
                comments: values.comments,
            }
            edit_supp = emptyFieldRemoval(edit_supp)
            const res = await axiosInstance.patch(
                `${configData.BACKEND_ROOT}/suppliers/${values.id}`,
                edit_supp
            )
            setError('')
            closeFunc()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Edit Supplier</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please input the details to be editted
                        </DialogContentText>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{marginTop: '1em'}}
                        >
                            <TextField
                                fullWidth
                                id="id"
                                name="id"
                                label="Supplier ID"
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id &&
                                    Boolean(formik.errors.id)
                                }
                                helperText={
                                    formik.touched.id && formik.errors.id
                                }
                            />
                            <TextField
                                fullWidth
                                id="name"
                                label="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                            <TextField
                                fullWidth
                                id="contact_num"
                                name="contact_num"
                                label="Contact Number"
                                value={formik.values.contact}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.contact_num &&
                                    Boolean(formik.errors.contact_num)
                                }
                                helperText={
                                    formik.touched.contact_num && formik.errors.contact_num
                                }
                            />
                            <TextField
                                fullWidth
                                // required
                                id="supp_email"
                                name="supp_email"
                                label="Email Address"
                                value={formik.values.supp_email}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.supp_email &&
                                    Boolean(formik.errors.supp_email)
                                }
                                helperText={
                                    formik.touched.supp_email && formik.errors.supp_email
                                }
                            />
                            <TextField
                                placeholder="Comments"
                                multiline
                                id="comments"
                                name="comments"
                                label="Comments"
                                rows={10}
                                value={formik.values.comments}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.comments &&
                                    Boolean(formik.errors.comments)
                                }
                                helperText={
                                    formik.touched.comments && formik.errors.comments
                                }
                            />
                            {error === '' ? (
                                <></>
                            ) : (
                                <Alert severity="error">ERROR — {error}</Alert>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            Edit Supplier
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}



//Material Edit Modal
export const EditMaterialModal = ({ open, closeFunc, state, stateUpdate }) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    //Material fields
    const [error, setError] = React.useState('')

    const validationSchema = yup.object({
        id: yup.string('Enter material id').required('Material id requried'),
        name: yup.string('Enter material name'),
        desc: yup.string('Enter material description')
    })

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            desc: ''
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values=>{
            handleEditMaterial(values)
        })
    })

    //Handle editting material in the database
    const handleEditMaterial = async (values) => {

        try {
            let edit_matr = {
                name: values.name,
                desc: values.desc,
            }
            edit_matr = emptyFieldRemoval(edit_matr)
            const res = await axiosInstance.patch(
                `${configData.BACKEND_ROOT}/materials/${values.id}`,
                edit_matr
            )

            setError('')
            closeFunc()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Edit Material</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please input the details to be editted
                        </DialogContentText>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{marginTop: '1em'}}
                        >
                            <TextField
                                fullWidth
                                id="id"
                                name="id"
                                label="Material ID"
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id &&
                                    Boolean(formik.errors.id)
                                }
                                helperText={
                                    formik.touched.id && formik.errors.id
                                }
                            />
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                            <TextField
                                placeholder="Properties"
                                multiline
                                rows={10}
                                id="desc"
                                name="desc"
                                label="Properties"
                                value={formik.values.desc}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.desc &&
                                    Boolean(formik.errors.desc)
                                }
                                helperText={
                                    formik.touched.desc && formik.errors.desc
                                }
                            />
                            {error === '' ? (
                                <></>
                            ) : (
                                <Alert severity="error">ERROR — {error}</Alert>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            Edit Material
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

//Location Edit Modal
export const EditLocationModal = ({ open, closeFunc, state, stateUpdate }) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const validationSchema = yup.object({
        id: yup.string('Enter location id').required('Location id required'),
        name: yup.string('Enter location name'),
        desc: yup.string('Enter location description')
    })
    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            desc: ''
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleEditLocation(values)
        },
    })
    const [error, setError] = React.useState('')

    //Handle editting location in the database
    const handleEditLocation = async (values) => {
        try {
            let edit_loc = {
                name: values.name,
                desc: values.desc,
            }
            edit_loc = emptyFieldRemoval(edit_loc)
            const res = await axiosInstance.patch(
                `${configData.BACKEND_ROOT}/locations/${values.id}`,
                edit_loc
            )
            setError('')
            closeFunc()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Edit Location</DialogTitle>
                    <form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            <DialogContentText>
                                Please input the details to be editted
                            </DialogContentText>
                            <Stack
                                direction="column"
                                spacing={2}
                                sx={{ marginTop: '1em' }}
                            >
                                <TextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="Location ID"
                                    value={formik.values.id}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.id &&
                                        Boolean(formik.errors.id)
                                    }
                                    helperText={
                                        formik.touched.id && formik.errors.id
                                    }
                                />
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Location Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name && formik.errors.name
                                    }
                                />
                                <TextField
                                    placeholder="Description"
                                    multiline
                                    rows={10}
                                    id="desc"
                                    name="desc"
                                    label="Location description"
                                    value={formik.values.desc}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.desc &&
                                        Boolean(formik.errors.desc)
                                    }
                                    helperText={
                                        formik.touched.desc && formik.errors.desc
                                    }
                                />
                                {error === '' ? (
                                    <></>
                                ) : (
                                    <Alert severity="error">ERROR — {error}</Alert>
                                )}
                            </Stack>
                        </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            Edit Location
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

//Part Edit Modal
export const EditPartModal = ({
    open,
    closeFunc,
    vehicle_id,
    state,
    stateUpdate,
}) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const validationSchema = yup.object({
        id: yup.string('Enter part number').required('Part number required'),
        name: yup.string('Enter part name'),
        bom_type: yup.string('Choose a BOM type'),
        source: yup.string('Choose a source number'),
        drawing: yup.string('Choose drawing state'),
        desc: yup.string('Enter part description'),
        loc_id: yup
            .string('Enter part location')
            .matches(
                '(^LOC_[0-9]+$|None)',
                'Have to match the format: LOC_[number] or "None"'
            ),
        supp_id: yup
            .string('Enter part supplier')
            .matches(
                '(^SUPP_[0-9]+$|None)',
                'Have to match the format: SUPP_[number] or "None"'
            ),
        matr_id: yup
            .string('Enter part material')
            .matches(
                '(^MATR_[0-9]+$|None)',
                'Have to match the format: MATR_[number] or "None"'
            ),
        cost_per_unit: yup.number('Enter part cost'),
        stock_qty: yup.number('Enter part stock').min(0, 'Cannot be negative'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            desc: '',
            bom_type: '',
            source: '',
            drawing: '',
            stock_qty: 0,
            loc_id: '',
            supp_id: '',
            matr_id: '',
            cost_per_unit: 0.0,
            id: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleEditPart(values)
        },
    })

    const [error, setError] = React.useState('')

    //Handle editing part in the backend
    const handleEditPart = async (values) => {
        //Selective creation of the obj would be good
        try {
            let edit_part = {
                name: values.name,
                desc: values.desc,
                source: values.source,
                drawing: values.drawing,
                stock_qty: values.stock_qty,
                loc_id: values.loc_id,
                supp_id: values.supp_id,
                matr_id: values.matr_id,
                cost_per_unit: values.cost_per_unit,
            }
            edit_part = emptyFieldRemoval(edit_part)
            const res = await axiosInstance.patch(
                `${configData.BACKEND_ROOT}/parts/${vehicle_id}/vehicles/${values.id}/part`,
                edit_part
            )
            setError('')
            closeFunc()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>Edit Part</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please input the details of the part to edit
                        </DialogContentText>
                        <DialogContentText>
                            ANYTHING LEFT BLANK WILL REMAIN THE SAME
                        </DialogContentText>
                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{ marginTop: '1em' }}
                        >
                            <TextField
                                fullWidth
                                id="id"
                                name="id"
                                label="Part Number"
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id &&
                                    Boolean(formik.errors.id)
                                }
                                helperText={
                                    formik.touched.id && formik.errors.id
                                }
                            />
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Part Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />

                            <FormControl fullWidth>
                                <InputLabel>BOM Tree</InputLabel>
                                <Select
                                    id="bom_type"
                                    name="bom_type"
                                    label="BOM Type"
                                    value={formik.values.bom_type}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.bom_type &&
                                        Boolean(formik.errors.bom_type)
                                    }
                                    helperText={
                                        formik.touched.bom_type &&
                                        formik.errors.bom_type
                                    }
                                >
                                    <MenuItem value={'Installation'}>
                                        Installation
                                    </MenuItem>
                                    <MenuItem value={'Assembly'}>
                                        Assembly
                                    </MenuItem>
                                    <MenuItem value={'Component'}>
                                        Component
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <InputLabel>Source</InputLabel>
                                <Select
                                    id="source"
                                    name="source"
                                    label="Source"
                                    value={formik.values.source}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.source &&
                                        Boolean(formik.errors.source)
                                    }
                                    helperText={
                                        formik.touched.source &&
                                        formik.errors.source
                                    }
                                >
                                    <MenuItem value={'External'}>
                                        External
                                    </MenuItem>
                                    <MenuItem value={'Internal'}>
                                        Internal
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {formik.values.source === 'External' ? (
                                <FormControl fullWidth>
                                    <InputLabel>Drawing Completed</InputLabel>
                                    <Select
                                        id="drawing"
                                        name="drawing"
                                        label="Drawing"
                                        value={formik.values.drawing}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.drawing &&
                                            Boolean(formik.errors.drawing)
                                        }
                                        helperText={
                                            formik.touched.drawing &&
                                            formik.errors.drawing
                                        }
                                    >
                                        <MenuItem value={'None'}>None</MenuItem>
                                        <MenuItem value={'Partial'}>
                                            Partial
                                        </MenuItem>
                                        <MenuItem value={'Complete'}>
                                            Complete
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                <></>
                            )}
                            <TextField
                                fullWidth
                                id="stock_qty"
                                name="stock_qty"
                                label="Stock Quantity"
                                type="number"
                                value={formik.values.stock_qty}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.stock_qty &&
                                    Boolean(formik.errors.stock_qty)
                                }
                                helperText={
                                    formik.touched.stock_qty &&
                                    formik.errors.stock_qty
                                }
                            />
                            <TextField
                                fullWidth
                                id="loc_id"
                                name="loc_id"
                                label="Location ID"
                                value={formik.values.loc_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.loc_id &&
                                    Boolean(formik.errors.loc_id)
                                }
                                helperText={
                                    formik.touched.loc_id &&
                                    formik.errors.loc_id
                                }
                            />
                            <TextField
                                fullWidth
                                id="supp_id"
                                name="supp_id"
                                label="Supplier ID"
                                value={formik.values.supp_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.supp_id &&
                                    Boolean(formik.errors.supp_id)
                                }
                                helperText={
                                    formik.touched.supp_id &&
                                    formik.errors.supp_id
                                }
                            />
                            <TextField
                                fullWidth
                                id="matr_id"
                                name="matr_id"
                                label="Material ID"
                                value={formik.values.matr_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.matr_id &&
                                    Boolean(formik.errors.matr_id)
                                }
                                helperText={
                                    formik.touched.matr_id &&
                                    formik.errors.matr_id
                                }
                            />
                            <TextField
                                fullWidth
                                id="cost_per_unit"
                                name="cost_per_unit"
                                label="Cost per unit"
                                type="number"
                                value={formik.values.cost_per_unit}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.cost_per_unit &&
                                    Boolean(formik.errors.cost_per_unit)
                                }
                                helperText={
                                    formik.touched.cost_per_unit &&
                                    formik.errors.cost_per_unit
                                }
                            />
                            <TextField
                                fullWidth
                                multiline
                                rows={10}
                                id="desc"
                                name="desc"
                                label="Engineering Comments"
                                value={formik.values.desc}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.desc &&
                                    Boolean(formik.errors.desc)
                                }
                                helperText={
                                    formik.touched.desc && formik.errors.desc
                                }
                            />
                            {error === '' ? (
                                <></>
                            ) : (
                                <Alert severity="error">ERROR — {error}</Alert>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" type="submit">
                            Edit Part
                        </Button>
                        <Button variant="contained" onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
