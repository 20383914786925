import React, { useState, useEffect } from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem} from "@mui/material";
import {Archive} from "@mui/icons-material";
import axios from "axios";
import configData from "../../config.js";
import {useNavigate, useParams} from "react-router-dom";
// import {axiosInstance} from "../../Utility";

export function ArchiveProjectModal({project,handleClose, setUpdateProject}) {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const {vehicle_id, project_id} = useParams()
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });
  // TODO
  const handleArchiveProject = () => {
    handleClose()
    axiosInstance.patch(`${configData.BACKEND_ROOT}/projects/${project.id}`, {...project, archived: !project.archived})
      .then((res) => {
        console.log('successfully archived project', res)
        setUpdateProject(true)
        handleClose()
        navigate(`/DepartmentProjects/${vehicle_id}`)
      }
    ).catch((err) => {
      console.log('Error while archiving project: ', err)
    });

  }
  return (
    <>
      <Dialog open={open}
              onClose={()=> setOpen(!open)}
              fullWidth
              sx={{ padding: '1em' }}>
        <DialogTitle>Confirm {project.archived ? "unarchive" : "archive"} {project.name} ({project.id})</DialogTitle>
        <DialogContent>
          Are you sure you wish to {project.archived ? "unarchive" : "archive"} this project? It will be moved {project.archived ? "back to the projects list." : 'to the "Archived" section and you will be unable to make further changes to it.'}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArchiveProject} variant="contained">Confirm</Button>
          <Button onClick={() => setOpen(!open)} variant="contained">Cancel</Button>
        </DialogActions>
      </Dialog>
      <MenuItem onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Archive/>
        </ListItemIcon>
        {project.archived ? "Unarchive project" : "Archive project"}
      </MenuItem>
    </>
  )
}
