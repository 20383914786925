import {
    CheckCircle,
    Warning,
    Add,
    Loop,
    MoreVert,
    Pending,
} from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';

import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

import React, { useEffect, useState } from 'react'
import CenterContainer from '../Components/CenterContainer.jsx'
import {
    Alert,
    Box,
    Button,
    Chip,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Stack,
} from '@mui/material'
import axios from 'axios'
import configData from '../config.js'
import {DataGrid, GridToolbar, useGridApiRef, GridRowParams} from '@mui/x-data-grid'

import { useParams } from 'react-router-dom'
import PageDrawer from '../Components/PageDrawer'
import { CreateBatchProcurementModal } from '../Components/ProcurementModals/CreateBatchProcurementModal'
import { CreateProcurementModal } from '../Components/ProcurementModals/CreateProcurementModal'
import { DeleteProcurementModal } from '../Components/ProcurementModals/DeleteProcurementModal'
import { ProcurementInfoModal } from '../Components/ProcurementModals/ProcurementInfoModal'
import { ExistingModal } from '../Components/ProcurementModals/ExistingModal'
import {EditProcurementModal} from "../Components/ProcurementModals/EditProcurementModal";
import { CreateOrderModal } from '../Components/OrderManagerModals/CreateOrderModal'

export function PartProcurement () {
    const { vehicle_id } = useParams()
    const apiRef = useGridApiRef()

    const [displayData, setDisplayData] = useState([])
    const [error, setError] = useState('')

    const [targetId, setToUpdateId] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const [updateDisplayData, setUpdateDisplayData] = useState(false)
    const [selectedDisplayData, setSelectedDisplayData] = useState([])
    const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)


    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const [actionAnchorEl, setActionAnchorEl] = useState(null)
    const actionMenuOpen = Boolean(actionAnchorEl)
    const statusMenuOpen = Boolean(anchorEl)

    const [newProcurementModalOpen, setNewProcurementModalOpen] =
        useState(false)
    const [newBatchProcurementModalOpen, setNewBatchProcurementModalOpen] =
        useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [infoModalOpen, setInfoModalOpen] = useState(false)
    const [existModalOpen, setExistModalOpen] = useState(false)

    const [existPartId, setExistPartId] = useState('')


    const [orderPage, setOrderPage] = useState(false)
    const ActionMenu = () => {
        return (
            <Menu
                id='action-menu'
                anchorEl={actionAnchorEl}
                open={actionMenuOpen}
                onClose={handleActionMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleActionMenuClose()
                        setInfoModalOpen(!infoModalOpen)
                    }}
                >
                    Info
                </MenuItem>
                <MenuItem
                  onClick={() => {
                      handleActionMenuClose()
                      setEditModalOpen(!editModalOpen)
                  }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleActionMenuClose()
                        setDeleteModalOpen(!deleteModalOpen)
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        )
    }

    const StatusMenu = () => {
        return (
            <Menu
                id='status-menu'
                anchorEl={anchorEl}
                open={statusMenuOpen}
                onClose={handleStatusMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => {
                        updateStatus('Declined')
                    }}
                >
                    <ListItemIcon>
                        <Warning color='error' />
                    </ListItemIcon>
                    Declined
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('On Order')
                    }}
                >
                    <ListItemIcon>
                        <Loop color='warning' />
                    </ListItemIcon>
                    On order
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('In Stock')
                    }}
                >
                    <ListItemIcon>
                        <CheckCircle color='success' />
                    </ListItemIcon>
                    In Stock
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        updateStatus('To Order')
                    }}
                >
                    <ListItemIcon>
                        <Pending color='info' />
                    </ListItemIcon>
                    To Order
                </MenuItem>
            </Menu>
        )
    }

    const getOrders = () => {
        const axiosInstance = axios.create({
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                    'access_token'
                )}`,
            },
        })
        axiosInstance
            .get(`${configData.BACKEND_ROOT}${'/procurement'}`)
            .then(res => {
                // console.log('all order data: ', res.data)
                setDisplayData(res.data)
            })
            .catch(err => {
                setError(err.response.data.detail)
            })
    }

    useEffect(() => {
        getOrders()
    }, [])

    useEffect(() => {
        if (updateDisplayData) {
            getOrders()
            setUpdateDisplayData(false)
        }
    }, [updateDisplayData])

    const getChipProps = params => {
        if (params.value === 'To Order') {
            return {
                icon: <Pending sx={{ fill: 'info' }} />,
                label: params.value,
                color: 'info',
                onClick: e => {
                    setAnchorEl(e.currentTarget)
                    setToUpdateId(params.row.id)
                },
            }
        } else if (params.value === 'On Order') {
            return {
                icon: <Loop sx={{ fill: 'warning' }} />,
                label: params.value,
                color: 'warning',
                onClick: e => {
                    setAnchorEl(e.currentTarget)
                    setToUpdateId(params.row.id)
                },
            }
        } else if (params.value === 'In Stock') {
            return {
                icon: <CheckCircle sx={{ fill: 'success' }} />,
                label: params.value,
                color: 'success',
                onClick: e => {
                    setAnchorEl(e.currentTarget)
                    setToUpdateId(params.row.id)
                },
            }
        } else if (params.value === 'Declined') {
            return {
                icon: <Warning sx={{ fill: 'error' }} />,
                label: params.value,
                color: 'error',
                onClick: e => {
                    setAnchorEl(e.currentTarget)
                    setToUpdateId(params.row.id)
                },
            }
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'string',
            hide: true,
            flex: 1,
        },
        {
            field: 'Part Number',
            headerName: 'Part Number',
            type: 'string',
            flex: 1,
            valueGetter: params => {
                return params.row.part_details.id
            },
        },
        {
            field: 'Part Name',
            headerName: 'Part Name',
            flex: 1,
            valueGetter: params => {
                return params.row.part_details.name
            },
        },
        {
            field: 'comments',
            headerName: 'Comments',
            flex: 1,
        },
        {
            field: 'quantity',
            headerName: 'QTY ordered',
            type: 'number',
            flex: 1,
            valueGetter: params => {
                return params.row.part_details.stock_qty
            },
        },
        {
            field: 'Unit Cost',
            headerName: 'Unit Cost ($)',
            type: 'number',
            flex: 1,
            valueGetter: params => {
                return parseFloat(
                    params.row.part_details.cost_per_unit.toFixed(2)
                );
            },
        },
        {
            field: 'Total Cost',
            headerName: 'Total Cost ($)',
            type: 'number',
            flex: 1,
            valueGetter: params => {
                return parseFloat(
                    params.row.part_details.stock_qty *
                        params.row.part_details.cost_per_unit
                )
            },
        },
        {
            field: 'status',
            flex: 1,
            headerName: 'Status',
            renderCell: params => {
                return <Chip variant='outlined' {...getChipProps(params)} />
            },
        },
        {
            field: 'actions',
            maxWidth: 80,
            headerName: 'Actions',
            renderCell: params => {
                return (
                    <IconButton
                        aria-label='actions'
                        size='large'
                        onClick={event => {
                            setActionAnchorEl(event.currentTarget)
                            setToUpdateId(params.row.id)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                )
            },
        },
    ]

    const handleNewOrder = () => {
        setNewProcurementModalOpen(!newProcurementModalOpen)
    }

    const handleNewBatchOrder = () => {
      setNewBatchProcurementModalOpen(!newBatchProcurementModalOpen)
    }
    const handleOrderClose = () => {
        setCheckoutModalOpen(!checkoutModalOpen)
    }

    const handleStatusMenuClose = () => {
        setAnchorEl(null)
    }
    const handleActionMenuClose = () => {
        setActionAnchorEl(null)
    }

    const handleDeleteProcurementClose = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }

    const handleEditProcurementClose = () => {
        setEditModalOpen(false)
    }
    const handleEditProcurementUpdate = (updated_procurement) => {
        getOrders()
        handleEditProcurementClose()
    }

    const handleInfoClose = () => {
        setInfoModalOpen(!infoModalOpen)
    }

    const handleExistModalClose = () => {
        setExistModalOpen(!existModalOpen)
    }

    // who should be able to update the status???? change this
    const updateStatus = newStatus => {
        // Close status menu
        handleStatusMenuClose()

        // TODO updated date changed
        // QUESTION, does backend update date changed at all?

        const body = { status: newStatus }
        axiosInstance
            .patch(
                `${configData.BACKEND_ROOT}/procurement/status/${targetId}`,
                body
            )
            .then(() => {
                const newData = displayData.filter(item => {
                    if (item.id === targetId) item.status = newStatus
                    return item
                })
                setDisplayData(newData)
            })
            .catch(err => setError(err.response.data.detail))
    }
    const handleOrderPage = () => {
        setOrderPage(true)

    }
    const handleCheckoutOrder = () => {
        setCheckoutModalOpen(!checkoutModalOpen)
        setOrderPage(false)
    }

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        const newSelectedData = displayData.filter(data => newRowSelectionModel.includes(data.id));
        setSelectedDisplayData(newSelectedData)
    };


    const handleCancelOrder = () => {
        setSelectedDisplayData([])
        setOrderPage(false)
    }

    return (
        <>
            {existPartId !== '' ? (
                <CreateProcurementModal
                    open={newProcurementModalOpen}
                    closeFunc={handleNewOrder}
                    state={displayData}
                    stateUpdate={setDisplayData}
                    vehicle_id={vehicle_id}
                    setError={setError}
                    existPartId={existPartId}
                />
            ) : (
                <></>
            )}
            <CreateOrderModal
                open={checkoutModalOpen}
                closeFunc={handleOrderClose}
                state={selectedDisplayData}
                setSelectedDisplayData={setSelectedDisplayData}
                vehicle_id={vehicle_id}
                setError={setError}
                existPartId={existPartId}
            />
            <CreateBatchProcurementModal
                open={newBatchProcurementModalOpen}
                closeFunc={handleNewBatchOrder}
                state={displayData}
                stateUpdate={setDisplayData}
                setUpdate={setUpdateDisplayData}
                vehicle_id={vehicle_id}
                setError={setError}
                existPartId={existPartId}
            />
            <ExistingModal
                open={existModalOpen}
                closeFunc={handleExistModalClose}
                setState={setExistPartId}
                nextModalOpen={handleNewOrder}
                vehicle_id={vehicle_id}
            />
            <DeleteProcurementModal
                open={deleteModalOpen}
                closeFunc={handleDeleteProcurementClose}
                state={displayData}
                stateUpdate={setDisplayData}
                targetId={targetId}
                setError={setError}
            />
            <EditProcurementModal
                open={editModalOpen}
                closeFunc={handleEditProcurementClose}
                updateFunc={handleEditProcurementUpdate}
                targetId={targetId}
                setError={setError}
            />
            <ProcurementInfoModal
                open={infoModalOpen}
                closeFunc={handleInfoClose}
                targetId={targetId}
                data={displayData.filter(item => item.id === targetId)}
                setError={setError}
            />
            {error === '' ? (
                <></>
            ) : (
                <Alert severity='error'>ERROR — {error}</Alert>
            )}
            <PageDrawer page='Parts Procurement' opTool opLogin id={vehicle_id}>
                <CenterContainer size='xl'>
                    {orderPage === true
                        ? <>
                            <div
                                style={{
                                    color:"red",
                                    fontWeight:"bold",
                                    letterSpacing: "1px",
                                    fontSize: "20px",
                                }}
                            >
                                Select the Procurements to Order
                            </div>
                            <br/>
                        </>

                        : <></>
                    }
                    <Paper
                        elevation={8}
                        sx={{ width: '100%', margin: '1em auto' }}
                    >
                        <Stack
                            direction='row'
                            justifyContent='flex-end'
                            paddingY='1em'
                            paddingRight="30px"
                        >
                            <Button
                                variant='contained'
                                startIcon={<Add />}
                                onClick={handleNewBatchOrder}
                            >
                                Add procurement
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {orderPage === true && selectedDisplayData.length !== 0
                            ?  <>
                                    <Button
                                        variant='contained'
                                        startIcon={<CloseIcon />}
                                        onClick={handleCancelOrder}
                                        sx={{
                                            backgroundColor:"#FF6667",
                                            '&:hover': {
                                                backgroundColor: "#FF4444",
                                            },
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        variant='contained'
                                        startIcon={<AttachMoneyIcon />}
                                        onClick={handleCheckoutOrder}
                                        sx={{
                                            backgroundColor:"#83FF98",
                                            '&:hover': {
                                                backgroundColor: "#60d77d",
                                            },
                                        }}
                                    >
                                        Checkout
                                    </Button>
                                </>
                            : orderPage === true && selectedDisplayData.length === 0  ?
                            <>
                                <Button
                                    variant='contained'
                                    startIcon={<CloseIcon />}
                                    onClick={handleCancelOrder}
                                    sx={{
                                        backgroundColor:"#FF6667",
                                        '&:hover': {
                                            backgroundColor: "#FF4444",
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    variant='contained'
                                    startIcon={<AttachMoneyIcon />}
                                    sx={{
                                        backgroundColor:"#83FF98",
                                        '&:hover': {
                                            backgroundColor: "#60d77d",
                                        },
                                    }}
                                    disabled
                                >
                                    Checkout
                                </Button>
                            </>
                            : <Button
                                variant='contained'
                                startIcon={<LocalGroceryStoreIcon />}
                                onClick={handleOrderPage}
                            >
                                Order
                            </Button>
                            }
                        </Stack>
                        {orderPage === true
                        ? <Box width='100%' height='100%'>
                            <StatusMenu />
                            <ActionMenu />
                            <DataGrid
                                rows={displayData}
                                columns={columns}
                                autoHeight='true'
                                density='compact'
                                disableSelectionOnClick
                                disableColumnMenu
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                apiRef={apiRef}
                                checkboxSelection
                                isRowSelectable={(params: GridRowParams) => params.row.status === 'To Order'}
                                selectedDisplayData={selectedDisplayData}
                                onRowSelectionModelChange={handleRowSelectionModelChange}
                                // keepNonExistentRowsSelected
                            />
                        </Box>
                        : <Box width='100%' height='100%'>
                            <StatusMenu />
                            <ActionMenu />
                            <DataGrid
                                rows={displayData}
                                columns={columns}
                                autoHeight='true'
                                density='compact'
                                disableSelectionOnClick
                                disableColumnMenu
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                apiRef={apiRef}
                            />
                        </Box>
                        }

                    </Paper>
                </CenterContainer>
            </PageDrawer>
        </>
    )
}
