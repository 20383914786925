/**
 * Registration page
 *
 * Allow user to register as a member of the team
 */

import React from 'react'
import axios from 'axios'
import configData from '../config.js'
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    Alert,
    TextField,
} from '@mui/material'

import PageDrawer from '../Components/PageDrawer'

import FormContainer from '../Components/FormContainer'
import { DEPT, DISC } from '../Constants.jsx'

import CenterContainer from '../Components/CenterContainer'
import { useNavigate } from 'react-router-dom'

import * as yup from 'yup'
import { useFormik } from 'formik'

const Registration = () => {
    const [error, setError] = React.useState('')
    const navigate = useNavigate()
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });


    const handleRegister = async (new_member) => {
        try {
            const res = await axiosInstance.post(
                `${configData.BACKEND_ROOT}/members`,
                new_member
            )
            setError('')
            sessionStorage.setItem('curr_zid', res.data.id)
            navigate(`/Vehicles`)
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    const validationSchema = yup.object({
        // id: yup
        //     .number('Enter your zid')
        //     .min(1000000, 'zID has to be 7 digits')
        //     .max(9999999, 'zID has to be 7 digits')
        //     .required('zID is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required'),
        // password: yup
        //     .string('Enter your password')
        //     .min(8, 'Password should be of minimum 8 characters length')
        //     .required('Password is required'),
        // confirm_password: yup
        //     .string('Confirm your password')
        //     .oneOf([yup.ref('password'), null], 'Passwords must match')
        //     .required('Confirming your password is required'),
        firstname: yup
            .string('Enter your first name')
            .required('First name is required'),
        lastname: yup
            .string('Enter your last name')
            .required('Last name is required'),
        startYear: yup
            .number('Enter your start year')
          .min(new Date().getFullYear() -10 )
          .max(new Date().getFullYear() + 1 )
            .required('Start year is required'),
        startTerm: yup
            .number('Enter your start term')
            .required('Start Term is required'),
        acadDisc: yup
            .string('Enter your academic discipline')
            .required('Academic Discipline is required'),
        dept: yup
            .string('Enter your department')
            .required('Department is required'),
    })

    const formik = useFormik({
        initialValues: {
            email: sessionStorage.getItem('temp_email'),
            firstname: '',
            lastname: '',
            startYear: 0,
            startTerm: 1,
            acadDisc: '',
            dept: '',
            id: sessionStorage.getItem('curr_zid'),
            password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleRegister(values)
        },
    })

    return (
        <>
            <PageDrawer page="Registration Page">
                    <CenterContainer>
                        <FormContainer>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2">
                                            Complete Sign Up
                                        </Typography>
                                    </Grid>
            
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="firstname"
                                            name="firstname"
                                            label="First Name"
                                            value={formik.values.firstname}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.firstname &&
                                                Boolean(formik.errors.firstname)
                                            }
                                            helperText={
                                                formik.touched.firstname &&
                                                formik.errors.firstname
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="lastname"
                                            name="lastname"
                                            label="Last Name"
                                            value={formik.values.lastname}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.lastname &&
                                                Boolean(formik.errors.lastname)
                                            }
                                            helperText={
                                                formik.touched.lastname &&
                                                formik.errors.lastname
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="id"
                                            name="id"
                                            label="zID"
                                            type="number"
                                            disabled={true}
                                            value={formik.values.id}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.id &&
                                                Boolean(formik.errors.id)
                                            }
                                            helperText={
                                                formik.touched.id &&
                                                formik.errors.id
                                            }
                                        />
                                    </Grid>
            
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.email &&
                                                Boolean(formik.errors.email)
                                            }
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                        />
                                    </Grid>
            
                                    {/*<Grid item xs={6}>*/}
                                    {/*    <TextField*/}
                                    {/*        fullWidth*/}
                                    {/*        id="password"*/}
                                    {/*        name="password"*/}
                                    {/*        label="Password"*/}
                                    {/*        type="password"*/}
                                    {/*        value={formik.values.password}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*        error={*/}
                                    {/*            formik.touched.password &&*/}
                                    {/*            Boolean(formik.errors.password)*/}
                                    {/*        }*/}
                                    {/*        helperText={*/}
                                    {/*            formik.touched.password &&*/}
                                    {/*            formik.errors.password*/}
                                    {/*        }*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={6}>*/}
                                    {/*    <TextField*/}
                                    {/*        fullWidth*/}
                                    {/*        id="confirm_password"*/}
                                    {/*        name="confirm_password"*/}
                                    {/*        label="Confirm Password"*/}
                                    {/*        type="password"*/}
                                    {/*        value={formik.values.confirm_password}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*        error={*/}
                                    {/*            formik.touched.confirm_password &&*/}
                                    {/*            Boolean(*/}
                                    {/*                formik.errors.confirm_password*/}
                                    {/*            )*/}
                                    {/*        }*/}
                                    {/*        helperText={*/}
                                    {/*            formik.touched.confirm_password &&*/}
                                    {/*            formik.errors.confirm_password*/}
                                    {/*        }*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
            
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="startYear"
                                            name="startYear"
                                            label="Start Year"
                                            type="Number"
                                            value={formik.values.startYear}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.startYear &&
                                                Boolean(formik.errors.startYear)
                                            }
                                            helperText={
                                                formik.touched.startYear &&
                                                formik.errors.startYear
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl required fullWidth>
                                            <InputLabel>Start Term</InputLabel>
                                            <Select
                                                id="startTerm"
                                                name="startTerm"
                                                label="Start Term"
                                                value={formik.values.startTerm}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.startTerm &&
                                                    Boolean(formik.errors.startTerm)
                                                }
                                                helperText={
                                                    formik.touched.startTerm &&
                                                    formik.errors.startTerm
                                                }
                                                style={{ textAlign: 'left' }}
                                            >
                                                <MenuItem value={1}>
                                                    Term 1
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Term 2
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    Term 3
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl required fullWidth>
                                            <InputLabel>
                                                Academic Discipline
                                            </InputLabel>
                                            <Select
                                                id="acadDisc"
                                                name="acadDisc"
                                                label="Academic Discipline"
                                                value={formik.values.acadDisc}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.acadDisc &&
                                                    Boolean(formik.errors.acadDisc)
                                                }
                                                helperText={
                                                    formik.touched.acadDisc &&
                                                    formik.errors.acadDisc
                                                }
                                                style={{ textAlign: 'left' }}
                                            >
                                                {Object.keys(DISC).map(
                                                    (key, value) => {
                                                        return (
                                                            <MenuItem value={key}>
                                                                {DISC[key].title}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl required fullWidth>
                                            <InputLabel>Department</InputLabel>
                                            <Select
                                                label="Department"
                                                id="dept"
                                                name="dept"
                                                value={formik.values.dept}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.dept &&
                                                    Boolean(formik.errors.dept)
                                                }
                                                helperText={
                                                    formik.touched.dept &&
                                                    formik.errors.dept
                                                }
                                                style={{ textAlign: 'left' }}
                                            >
                                                {Object.keys(DEPT).map(
                                                    (key, value) => {
                                                        return (
                                                            <MenuItem value={key}>
                                                                {DEPT[key].name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {error === '' ? (
                                            <></>
                                        ) : (
                                            <Alert severity="error">
                                                ERROR — {error}
                                            </Alert>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit">
                                            Register
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContainer>
                    </CenterContainer>
            </PageDrawer>
        </>
    )
}

export default Registration
