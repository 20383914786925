import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {CalendarMonth, Dehaze, MoreHoriz, Person} from "@mui/icons-material";
import {getRequirementStatus, RequirementStatusMenu} from "../Assets/ProjectRequirementData";
import axios from "axios";
import configData from "../config.js";
import { TabularItem } from "./TabularItem";

export function RequirementVerification({active, verification, setUpdate, updateVerification, setUpdateVerification, archived}) {
  const [engineerData, setEngineerData] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  })

  const handleStatusOpen = (event) => {
    console.log('clicked, ', archived)
    if (!archived) setStatusAnchorEl(event.currentTarget)
  }
  const handleStatusClose = () => {
    setStatusAnchorEl(null)
  }
  const updateStatus = (status) => {
    axiosInstance.patch(`${configData.BACKEND_ROOT}/verification/${verification.id}`, {status: status}).then(res=>{
      console.log('success')
      setUpdate(true)
      setStatusAnchorEl(null)
    }).catch(err=> {
      console.log('error update status: ', err)
      setStatusAnchorEl(null)
    })
  }

  const getEngineerDetails = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/members/${verification.engineer_id}`).then(res => {
      console.log('Verifying engineer: ', res.data)
      setEngineerData(res.data)
    }).catch(err=> console.log('Error getting engineer data: ', err))
  }

  useEffect(() => {
    if (active === 2 && verification && !engineerData) {
        getEngineerDetails()
    }
  }, [active, verification])

  useEffect(() => {
    if (updateVerification) {
      console.log('Updating live...')
      getEngineerDetails()
      setUpdateVerification(false)
    }
  }, [updateVerification])

  return verification && (
    <Grid container sx={{ fontSize: '12px' }}>
      <TabularItem
        icon={<Person />}
        label="Verifying Engineer"
        value={`${engineerData?.firstname} ${engineerData?.lastname} (z${verification.engineer_id})`}
        zid={verification.engineer_id}
      />
      <TabularItem icon={<Dehaze />} label="Type" value={verification.type} />
      <TabularItem
        icon={<MoreHoriz />}
        label="Status"
        value={getRequirementStatus(verification.status)}
        onClick={handleStatusOpen}
      />
      <TabularItem
        icon={<CalendarMonth />}
        label="Date Updated"
        value={new Date(verification.date).toLocaleDateString()}
      />
      <TabularItem
        icon={<Dehaze />}
        label="Verification Notes"
        value={verification.description}
        multiline
      />
      <RequirementStatusMenu
        anchorEl={statusAnchorEl}
        handleClose={handleStatusClose}
        updateStatus={updateStatus}
      />
    </Grid>
  )  
}