import React, { useState, useEffect } from 'react'
import {Edit} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  Grid, InputLabel, ListItemIcon, MenuItem, Select,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import {useFormik} from "formik";
import configData from "../../config.js";
import axios from "axios";
// import {axiosInstance} from "../../Utility";



export function EditProjectModal({project, handleClose, setUpdateProject}) {
  const [open, setOpen] = useState(false)
  const [depts, setDepts] = useState()
  const [allProjects, setAllProjects] = useState([])
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const validationSchema = yup.object({
    name: yup.string('Enter project name')
      .required('Project name required')
      .test('unique', 'Project name must be unique',
        function(value) {
          return !allProjects.find(p=>p.name === value) || value === project.name
        }),
    dept_id: yup
      .number('Enter department')
      .required('Department required'),
    description: yup.string('Enter project description').required('Project description required'),
  })
  const formik = useFormik({
    initialValues: {name: project.name, dept_id: project.dept_id, description: project.description},
    validationSchema: validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEditProject(values)
    },
  })

  // TODO Duplicated function (also in CreateProject)to be refactored
  const getAllProjects = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/projects`)
      .then(res=> {
        setAllProjects(res.data)
      })
      .catch(err => {
        console.log('Error getting proj list: ', err)
      })
  }

  const getDepartments = () => {
    axiosInstance.get(`${configData.BACKEND_ROOT}/departments`).then(res=> {
      console.log("retreived departments: ", res.data)
      setDepts(res.data)
    })
      .catch(err => {
        console.log('error getting departments: ', err)
      })
  }
  useEffect(() => {
    if(open) {
      console.log('editing project: ', project)
      getAllProjects()
      getDepartments()
    }
  }, [open])

  // TODO integrate and test with backend
  const handleEditProject = (values) => {
    console.log('Editing project with values: ', values)
    axiosInstance.patch(`${configData.BACKEND_ROOT}/projects/${project.id}`,{...values, archived: false}).then(res=> {
     console.log('Successfully edited project: ', res.data)
      setUpdateProject(true)
      handleClose()
    }).catch(err => {
      console.log("Error editing project: ", err)
    });
  }

  return (
    <>
      <Dialog open={open}
              fullWidth
              onClose={() => setOpen(!open)}
              sx={{padding: '1em'}}>
        <DialogTitle>Editing {project.name} - {project.id}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Project Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.name &&
                      Boolean(formik.errors.name)
                    }
                    helperText={
                      formik.touched.name &&
                      formik.errors.name
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Project Department</InputLabel>
                    <Select
                      id="dept_id"
                      name="dept_id"
                      label="Department"
                      value={formik.values.dept_id}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.dept_id &&
                        Boolean(formik.errors.dept_id)
                      }
                      helperText={
                        formik.touched.dept_id &&
                        formik.errors.dept_id
                      }
                    >
                      { depts &&
                        depts.map((d, idx) => {
                          return (<MenuItem key={idx} value={d.id}>{d.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={10}
                    id="description"
                    name="description"
                    label="Project description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description &&
                      formik.errors.description
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type="submit">
                Save changes
              </Button>
              <Button variant="contained" onClick={() => {
                setOpen(!open)
                handleClose()
              }}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <MenuItem  onClick={() => {
        setOpen(!open)
      }}>
        <ListItemIcon>
          <Edit/>
        </ListItemIcon>
        Edit project
      </MenuItem>
    </>
  )
}
