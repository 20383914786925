/**
 * CreateBatchProcurementModals.jsx
 *
 * Modal for creating a new procurement order process
 *
 * Parameters:
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import configData from '../../config.js'
// import {useNavigate} from "react-router-dom";
import * as yup from 'yup'
// import { Context, useContext } from '../../context';
import { useFormik } from 'formik';
import { ExistingModal } from './ExistingModal';
// import { CreateProcurementModal } from './CreateProcurementModal';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    MenuItem,
    Grid,
    Select,
    Switch,
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    TableFooter,
    IconButton,
    FormControlLabel,
} from '@mui/material'

import {
    Add,
} from '@mui/icons-material'

import { BiHide } from 'react-icons/bi'
import { IoIosRemove } from 'react-icons/io'
import DeleteIcon from '@mui/icons-material/Delete'

// const qtyTypes = [
//     {
//         value: 'Components',
//         label: 'Components',
//     },
//     {
//         value: 'Packs',
//         label: 'Packs',
//     },
// ]

export const CreateBatchProcurementModal = ({
    open,
    closeFunc,
    vehicle_id,
    state,
    stateUpdate,
    setUpdate,
    setError,
}) => {
    // const navigate = useNavigate()
    // const { getters, setters } = useContext(Context)

    const validationSchema = yup.object({
        parts: yup.array().of(
            yup.object().shape({
                part_name: yup
                    .string('Enter part name')
                    .required('* Required Field'),
                part_number: yup
                    .string('Enter part number')
                    .required('* Required Field'),
                part_desc: yup
                    .string('Enter part description')
                    .required('* Required Field'),
                bom_type: yup
                    .string('Enter BOM Type')
                    .required('BOM Type required'),
                qty_to_order: yup
                    .number('Enter quantity to order')
                    .required('* Required Field')
                    .integer('Must be a valid positive integer')
                    .min(1, 'Must be a valid positive integer'),
                total_cost: yup
                    .number('Enter Total Cost')
                    .required('* Required Field')
                    .min(0.01, 'Must exceed $0'),
                qty_type: yup
                    .string('Enter quantity type')
                    .required('* Required Field'),
                unit_per_pack: yup.number('Total units per pack'),
                proc_comments: yup
                    .string(
                        'Enter brief explanation of what procurement is for'
                    )
                    .required('* Required Field'),
            })
        ).required('Parts required').min(1, 'At least one part must be added'),
    })
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    //Calculates the cost per unit for submission, written to be extendable
    const get_cost_per_unit = (
        qty_type,
        total_cost,
        qty_to_order,
        unit_per_pack
    ) => {
        if (qty_type === 'Single Unit') {
            return total_cost / qty_to_order
        } else if (qty_type === 'Packs') {
            return total_cost / (qty_to_order * unit_per_pack)
        }
    }

    const [totalCost, setTotalCost] = useState(0)

    //Calculates the total order cost, written to be extendable
    const get_total_cost = () => {
        let tc = 0
        for (const part of formik.values.parts) {
            if (parseInt(part.qty_to_order) === 0 || parseFloat(part.total_cost) === 0)
                continue
            console.log('adding total cost of ', part.total_cost)
            tc += parseFloat(part.total_cost)
        }
        return parseFloat(tc).toFixed(2)
    }

    const calculateTotalCost = () => {
        const total_cost = get_total_cost()
        setTotalCost(total_cost)
    }

    // Check if part number is already in the system to avoid creating duplicate copy
    // const checkPartNumber = async (partNumber) => {
    //     // TODO check part number dne before inserting new procurement req -- use old otherwise
    //     // TODO apparently also check the part name... ew
    //     await axiosInstance
    //         .get(`${configData.BACKEND_ROOT}/procurement`)
    //         .then((res) => {
    //             console.log('res from get all is: ', res)
    //             console.log('res data: ', res.data)
    //         })
    //         .catch((err) => {
    //             setError(err.response.data.detail)
    //             console.log(
    //                 'error caught while checking part number before new insert: ',
    //                 err
    //             )
    //             // setters.setSnackbarMessage(err.message)
    //             // setters.setSnackbarSeverity('error')
    //         })
    // }

    // const checkOrderNumber = async (orderNumber) => {
    //     // TODO check order number dne before inserting new procurement req -- use old otherwise
    //     // TODO apparently also check the order name... ew
    //     await axiosInstance
    //         .get(`${configData.BACKEND_ROOT}/orders`)
    //         .then((res) => {
    //             console.log('res from get all is: ', res)
    //             console.log('res data: ', res.data)
    //         })
    //         .catch((err) => {
    //             setError(err.response.data.detail)
    //             console.log(
    //                 'error caught while checking order number before new insert: ',
    //                 err
    //             )
                // setters.setSnackbarMessage(err.message)
                // setters.setSnackbarSeverity('error')
    //         })
    // }

    const get_total_unit = (qty_type, qty_to_order, unit_per_pack) => {
        if (qty_type === 'Single Unit') {
            return qty_to_order
        } else if (qty_type === 'Packs') {
            return qty_to_order * unit_per_pack
        }
    }

    //Handle adding procurement request
    const handleCreateBatchProcurement = async (values) => {

        try {
            for (const part of formik.values.parts) {
                const part_details = {
                    name: part.part_name,
                    desc: part.part_desc,
                    bom_type: part.bom_type,
                    source: 'External',
                    drawing: 'None',
                    revision: 0,
                    stock_qty: get_total_unit(
                        part.qty_type,
                        part.qty_to_order,
                        part.unit_per_pack
                    ),
                    loc_id: 'None',
                    matr_id: 'None',
                    supp_id: 'None',
                    vehicle_id: vehicle_id,
                    cost_per_unit: get_cost_per_unit(
                        part.qty_type,
                        part.total_cost,
                        part.qty_to_order,
                        part.unit_per_pack
                    ),
                    id: part.part_number,
                }

                const new_proc = {
                    requester_zid: parseInt(sessionStorage.getItem('curr_zid')),
                    qty_requested: part_details.stock_qty,
                    date_created: new Date().toUTCString(),
                    date_changed: new Date().toUTCString(),
                    status: 'To Order',
                    comments: part.proc_comments,
                    part_details: part_details,
                }
                axiosInstance
                    .post(`${configData.BACKEND_ROOT}/procurement`, new_proc)
                    .catch(err => {
                        console.log("Error creating procurement with part number: ", part.part_number, err)
                        // setters.setSnackbarMessage(err.message)
                        // setters.setSnackbarSeverity('error')
                    })
            }
            setError('')
            setUpdate(true)
            closeFunc()
            formik.resetForm()
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    const [initData] = useState({
        parts: [
        ],
    })

    const addPart = () => {
        const newPart = {
            part_name: '',
            part_number: '',
            part_desc: '',
            bom_type: 'Installation',
            qty_to_order: 0,
            total_cost: 0,
            qty_type: 'Single Unit',
            unit_per_pack: 0,
            proc_comments: '',
            order_placed: false,
            procurement_id: 0
        }
        formik.setFieldValue('parts', [...formik.values.parts, newPart])
    }
    const addExistingPart = () => {
        setExistModalOpen(!existModalOpen)


    }

    const removePart = (index) => {
        const updatedParts = [...formik.values.parts]
        setTotalCost(parseFloat(parseFloat(totalCost) - formik.values.parts[index].total_cost).toFixed(2))
        updatedParts.splice(index, 1)
        formik.setFieldValue('parts', updatedParts)
    }

    const removeAllParts = (index) => {
        formik.setFieldValue('parts', [])
    }

    const handlePartChange = (e, index) => {
        const { name, value } = e.target
        formik.setFieldValue(`parts[${index}].${name}`, value)
    }

    const formik = useFormik({
        initialValues: initData,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleCreateBatchProcurement(values)
        },
    })

    const [dense, setDense] = React.useState(false)
    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }
    // new
    const handleExistModalClose = () => {
        setExistModalOpen(!existModalOpen)
    }
    const handleNewOrder = () => {
        setNewProcurementModalOpen(!newProcurementModalOpen)
    }
    const [existPartId, setExistPartId] = useState('')
    const [existModalOpen, setExistModalOpen] = useState(false)
    const [newProcurementModalOpen, setNewProcurementModalOpen] = useState(false);
    // const [displayData, setDisplayData] = useState([])

    useEffect(() => {
        if (existPartId !== "") {
            axiosInstance.get(`${configData.BACKEND_ROOT}/parts/${vehicle_id}/vehicles/${existPartId}/part`)
          .then(res => {
                const newPart = {
                    part_name: res.data.name,
                    part_number: existPartId,
                    part_desc: res.data.desc,
                    bom_type: res.data.bom_type,
                    drawing: res.data.drawing,
                    qty_to_order: 0,
                    total_cost: 0,
                    qty_type: 'Single Unit',
                    unit_per_pack: 0,
                    proc_comments: '',
                    order_id: null
                };
                formik.setFieldValue('parts', [...formik.values.parts, newPart])
          }).catch(err => {
              setError(err.response.data.detail)

          })
        };

    }, [existPartId])

    return (
        <>
            <ExistingModal
                open={existModalOpen}
                closeFunc={addExistingPart}
                setState={setExistPartId}
                nextModalOpen={handleNewOrder}
                vehicle_id={vehicle_id}
            />
            {/* <CreateProcurementModal
                open={newProcurementModalOpen}
                closeFunc={handleNewOrder}
                state={displayData}
                stateUpdate={setDisplayData}
                vehicle_id={vehicle_id}
                setError={setError}
                existPartId={existPartId}
            /> */}
            <Dialog open={open} onClose={closeFunc} fullScreen>
                <DialogTitle>
                    New Procurement Order Request
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={closeFunc}
                        aria-label="close"
                        style={{
                            position: 'absolute',
                            right: '20px',
                            top: '10px',
                        }}
                    >
                        <BiHide />
                    </IconButton>
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Add procurement requests:
                        </DialogContentText>
                        <Grid container spacing={2} sx={{ paddingTop: '32px' }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table
                                        size={dense ? 'small' : 'medium'}
                                        style={{ borderCollapse: 'collapse' }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Name
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Number
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Part Description
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    BOM Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Quantity to Order
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Total Cost ($)
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Quantity Type
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Units per Pack
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    Procurement Comments
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        fontWeight: 'bold',
                                                        borderBottom:
                                                            '1px solid #ccc',
                                                    }}
                                                >
                                                    {formik.values.parts
                                                        .length > 0 && (
                                                        <IconButton
                                                            onClick={() => {
                                                                    removeAllParts()
                                                                    setTotalCost(parseFloat(0).toFixed(2))
                                                                }
                                                            }
                                                            aria-label="delete"
                                                            color="primary"
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formik.values.parts.map(
                                                (part, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`part_name`}
                                                                name={`part_name`}
                                                                value={
                                                                    part.part_name
                                                                }
                                                                placeholder="Name"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_name
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]?.part_name
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`part_number`}
                                                                name={`part_number`}
                                                                value={
                                                                    part.part_number
                                                                }
                                                                placeholder="Number"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_number
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.part_number
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`part_desc`}
                                                                name={`part_desc`}
                                                                value={
                                                                    part.part_desc
                                                                }
                                                                placeholder="Description"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.part_desc
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]?.part_desc
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed,

                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                disabled={part.order_placed}
                                                            >
                                                                <Select
                                                                    id={`bom_type`}
                                                                    name={`bom_type`}
                                                                    value={
                                                                        part.bom_type
                                                                    }
                                                                    placeholder="BOM Type"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handlePartChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    error={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        Boolean(
                                                                            formik
                                                                                .errors
                                                                                .parts &&
                                                                                formik
                                                                                    .errors
                                                                                    .parts[
                                                                                    index
                                                                                ]
                                                                                    ?.bom_type
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                        formik
                                                                            .errors
                                                                            .parts[
                                                                            index
                                                                        ]
                                                                            ?.bom_type
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            '120px',
                                                                    }}
                                                                    disableUnderline
                                                                >
                                                                    <MenuItem value="Installation">
                                                                        Installation
                                                                    </MenuItem>
                                                                    <MenuItem value="Assembly">
                                                                        Assembly
                                                                    </MenuItem>
                                                                    <MenuItem value="Component">
                                                                        Component
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`qty_to_order`}
                                                                name={`qty_to_order`}
                                                                type="number"
                                                                value={
                                                                    part.qty_to_order
                                                                }
                                                                placeholder="Quantity to Order"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.qty_to_order
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.qty_to_order
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`total_cost`}
                                                                name={`total_cost`}
                                                                type="number"
                                                                value={
                                                                    part.total_cost
                                                                }
                                                                placeholder={part.qty_type === "Single Unit" ? "Unit Cost ($)" : "Batch Cost ($)"}
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                onBlur={() =>
                                                                    calculateTotalCost()
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.total_cost
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.total_cost
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                disabled={part.order_placed}
                                                            >
                                                                <Select
                                                                    id={`qty_type`}
                                                                    name={`qty_type`}
                                                                    value={
                                                                        part.qty_type
                                                                    }
                                                                    placeholder="Quantity Type"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handlePartChange(
                                                                            e,
                                                                            index
                                                                        )
                                                                    }
                                                                    error={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        Boolean(
                                                                            formik
                                                                                .errors
                                                                                .parts &&
                                                                                formik
                                                                                    .errors
                                                                                    .parts[
                                                                                    index
                                                                                ]
                                                                                    ?.qty_type
                                                                        )
                                                                    }
                                                                    helperText={
                                                                        formik
                                                                            .touched
                                                                            .parts &&
                                                                        formik
                                                                            .touched
                                                                            .parts[
                                                                            index
                                                                        ] &&
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                        formik
                                                                            .errors
                                                                            .parts[
                                                                            index
                                                                        ]
                                                                            ?.qty_type
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            '120px',
                                                                    }}
                                                                    disableUnderline
                                                                >
                                                                    <MenuItem value="Single Unit">
                                                                        Single
                                                                        Unit
                                                                    </MenuItem>
                                                                    <MenuItem value="Packs">
                                                                        Packs
                                                                    </MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                disabled={
                                                                    part.qty_type ===
                                                                    'Single Unit'
                                                                }
                                                                type="number"
                                                                id={`unit_per_pack`}
                                                                name={`unit_per_pack`}
                                                                value={
                                                                    part.unit_per_pack
                                                                }
                                                                placeholder="Units per pack"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.unit_per_pack
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.unit_per_pack
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <TextField
                                                                fullWidth
                                                                size={
                                                                    dense
                                                                        ? 'small'
                                                                        : 'medium'
                                                                }
                                                                id={`proc_comments`}
                                                                name={`proc_comments`}
                                                                value={
                                                                    part.proc_comments
                                                                }
                                                                placeholder="Comments"
                                                                onChange={(e) =>
                                                                    handlePartChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .parts &&
                                                                            formik
                                                                                .errors
                                                                                .parts[
                                                                                index
                                                                            ]
                                                                                ?.proc_comments
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .touched
                                                                        .parts &&
                                                                    formik
                                                                        .touched
                                                                        .parts[
                                                                        index
                                                                    ] &&
                                                                    formik
                                                                        .errors
                                                                        .parts &&
                                                                    formik
                                                                        .errors
                                                                        .parts[
                                                                        index
                                                                    ]
                                                                        ?.proc_comments
                                                                }
                                                                InputProps={{
                                                                    readOnly: part.order_placed
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid #ccc',
                                                            }}
                                                        >
                                                            <IconButton
                                                                onClick={() =>{
                                                                    removePart(
                                                                        index
                                                                    )
                                                                }}
                                                                aria-label="delete"
                                                            >
                                                                <IoIosRemove />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell
                                                    colSpan={1}
                                                    align="left"
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={dense}
                                                                onChange={
                                                                    handleChangeDense
                                                                }
                                                            />
                                                        }
                                                        label="Dense padding"
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    colSpan={12}
                                                    align="right"
                                                >
                                                    <b>
                                                        Total
                                                        Cost:&nbsp;&nbsp;&nbsp;$
                                                        {totalCost}
                                                    </b>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Grid container spacing={10} sx={{ paddingTop: '32px' }}>
                            <Grid item xs={6} sm={10}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Add />}
                                    onClick={addPart}
                                >
                                    New Part
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    variant='contained'
                                    startIcon={<Add />}
                                    onClick={handleExistModalClose}
                                >
                                    Existing Part
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions
                        sx={{ marginLeft: 'auto', marginRight: '16px' }}
                    >
                        <Button variant="contained" type="submit">
                            Submit new request
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                closeFunc()
                                formik.resetForm()
                                setTotalCost(parseFloat(0).toFixed(2))
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
