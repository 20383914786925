import React, {useEffect, useState} from 'react'
import axios from "axios";
import configData from "../../config.js";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import MemberCard from '../MemberCard.jsx';

export const ProcurementInfoModal = ({open, closeFunc, targetId, setError}) => {
  const [data, setData] = useState(null)

  const [engineer, setEngineer] = useState(null)
  const [supplier, setSupplier] = useState(null)

  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const getProcurementInfo = async () => {
    console.log("RUN AGAIN")
    await axiosInstance.get(`${configData.BACKEND_ROOT}/procurement/${targetId}`)
      .then((res) => {
        setData(res.data)
        console.log('rs from info is: ', res.data)
      })
      .catch(err => setError(err.response.data.detail))
  }

  const getPartDetails = async () => {
    await axiosInstance.get(`${configData.BACKEND_ROOT}/members/${data.requester_zid}`)
      .then(res=>{
         setEngineer(res.data.firstname + ' ' + res.data.lastname)
      }).catch(err => setError(err.response.data.detail))
    axiosInstance.get(`${configData.BACKEND_ROOT}/suppliers/${data.part_details.supp_id}`)
      .then(res=> {
        setSupplier(res.data.name)
      })
      .catch(err => setError(err.response.data.detail))
  }

  useEffect(() => {
      if (data) {
        getPartDetails()
      } 
  }, [data])
  // Retrieve full data for specified order
  useEffect(()=>{
    if (open) getProcurementInfo()
  },[open])

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Order for {data ? data.part_details.id : 'N/A'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                {
                  data &&
                  <MemberCard 
                    label={`Requesting Engineer: ${data ? engineer + ' (z' + data.requester_zid + ')' : 'N/A' }`} 
                    zid={data ? data.requester_zid : 'n/a'}
                  />
                }
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  <strong>ID:</strong> {data ? data.id : 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Part Name:</strong> {data ? data.part_details.name : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Quantity:</strong> {data ? data.qty_requested : 0} {data ? data.qty_type : 'Components'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Total Cost:</strong> {data ? '$' + parseFloat(data.part_details.cost_per_unit * data.qty_requested) : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Cost per unit:</strong> {data ? '$' + parseFloat(data.part_details.cost_per_unit) : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Part Description:</strong> {data ? data.part_details.desc : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Part Supplier:</strong> {data ? data.part_details.supp_id : 'N/A'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Date requested:</strong> {data ? new Date(data.date_created).toLocaleDateString() : 'N/A' }
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Date Last Updated:</strong> {data ? new Date(data.date_changed).toLocaleDateString() : 'N/A'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {data ? data.comments : 'No recorded comments'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                { data ? data.engineer : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeFunc} variant='contained'>Close</Button>
      </DialogActions>
    </Dialog>
  )

}
