import React, {useEffect, useState} from 'react'
import axios from "axios";
import configData from "../../config.js";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";

export const OrderInfoModal = ({open, closeFunc, targetId, setError}) => {
  const [data, setData] = useState(null)

  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const [procurements, setProcurements] = useState([])
  const getOrderInfo = async () => {
    await axiosInstance.get(`${configData.BACKEND_ROOT}/orders/${targetId}`)
      .then((res) => {
        setData(res.data)
      })
      .catch(err => setError(err.response.data.detail))
    
    console.log(`target id is ${targetId}`)
    await axiosInstance.get(`${configData.BACKEND_ROOT}/procurement/order/${targetId}`)
      .then((res) => {
        setProcurements(res.data)
      })
      .catch(err => setError(err.response.data.detail))
  }

  // Retrieve full data for specified order
  useEffect(()=>{
    if (open) getOrderInfo()
  },[open])

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Order Number: {data ? data.number : 'N/A'}</DialogTitle>
      <DialogContent>
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        ID: {data ? data.id : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Order Name: {data ? data.name : 'N/A'}
      </Typography>
      <Typography variant="body1">
        Total Cost: {data ? `$${data.cost.toFixed(2)}` : 'N/A'}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Estimated Delivery Date: {data ? new Date(data.est_delivery).toLocaleDateString() : 'N/A'}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        Parts List:
      </Typography>
      {procurements &&
        procurements.map((proc) => (
          <Typography variant="body1" key={proc.id}>
            - {proc.part_details.name} (ID: {proc.part_details.id})
          </Typography>
        ))}
    </Grid>
  </Grid>
</DialogContent>

      <DialogActions>
        <Button onClick={closeFunc} variant='contained'>Close</Button>
      </DialogActions>
    </Dialog>
  )

}
