
// will be a relation in the database
import {Chip, IconButton, ListItem, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import {CheckCircle, Error, Loop, MoreVert, Pending, Warning} from "@mui/icons-material";
import React from "react";
import List from "@mui/material/List";



// will be a type in the database
export const req_types = [
  'Functional',
  'Interfacing',
  'Performance',
  'Reliability',
  'External'
]
export const verification_types = [
  'Track day',
  'Workshop',
  'Analysis'
]


export const getRequirementStatus = status => {
    if (status === 'Not Verified') {
      return <Chip variant="outlined"
                   icon={<Pending sx={{ fill: 'info' }} />}
                   label={status}
                   color="warning"
      />
    }
    else if (status === 'Verified') {
      return <Chip variant="outlined"
                   icon={<CheckCircle sx={{ fill: 'success' }} />}
                   label={status}
                   color="success"
      />
    }
    else if (status === 'Failed') {
      return <Chip variant="outlined"
                   icon={<Error sx={{ fill: 'error' }} />}
                   label={status}
                   color="error"
      />
    }

}

export const projectRequirementColumns = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'string',
    hide: true,
    flex: 1
  },
  {
    field: 'number',
    headerName: 'Requirement ID',
    type: 'string',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
  },
  {
    field: 'component',
    headerName: 'Key Component',
    type: 'string',
    flex: 1,
    renderCell: (params) => {
      const current_pc = params.row.project_components.find(pc => pc.proj_id === params.row.current_project)
      if (current_pc == null) return "N/A"
      return current_pc.component
    }
  },
  {
    field: 'associated',
    headerName: 'Associated Projects',
    type: 'string',
    flex: 1,
    renderCell: (params) => {
      if (params.row.project_components.length <= 1) return "N/A"
      const rel = params.row.projects.filter(p=> p.id !== params.row.current_project).map(p=>p.name + " (" + p.id + ")")
      return rel.join(', ')
    }
  },
  {
    field: 'status',
    headerName: 'Verification Status',
    type: 'number',
    flex: 1,
    renderCell: (params) => {
      return getRequirementStatus(params.value)
    }
  },
]

export function RequirementStatusMenu ({anchorEl, handleClose, updateStatus}) {
  return (
    <Menu
      id="status-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={()=> {updateStatus('Not Verified')}}>
        <ListItemIcon>
          <Pending color='warning' />
        </ListItemIcon>
        Unverify
      </MenuItem>
      <MenuItem onClick={()=> {updateStatus('Verified')}}>
        <ListItemIcon>
          <CheckCircle color='success' />
        </ListItemIcon>
        Verify
      </MenuItem>
      <MenuItem onClick={()=> {updateStatus('Failed')}}>
        <ListItemIcon>
          <Error color='error' />
        </ListItemIcon>
        Fail
      </MenuItem>
    </Menu>
  )
}