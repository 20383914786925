import React, { useState, useEffect } from 'react'
import {Delete} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ListItemIcon,
  MenuItem,
  TextField
} from "@mui/material";
import axios from "axios";
import configData from '../../config.js'
import {useNavigate, useParams} from "react-router-dom";
import { checkPerms} from "../../Utility";


export function DeleteProjectModal({proj_name, proj_id, handleClose}) {
  const [open, setOpen] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const navigate = useNavigate()
  const {vehicle_id, project_id} = useParams()
  const axiosInstance = axios.create( {
    headers: {
      Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
    }
  });

  const handleDeleteProject = () => {
    axiosInstance.delete(`${configData.BACKEND_ROOT}/projects/${proj_id}`).then(res=> {
      console.log('deleted')
      navigate(`/DepartmentProjects/${vehicle_id}`)
      handleClose()

    }).catch(err=> {
      console.log('error deleting project: ', err)
    });
  }

  return (
    <>
      <Dialog open={open}
              onClose={()=> setOpen(!open)}
              fullWidth
              sx={{ padding: '1em' }}>
        <DialogTitle>Confirm delete {proj_name} - {proj_id}</DialogTitle>
        <DialogContent>
          Are you sure you wish to delete this project? Deleting a project is permanet and cannot be undone.
          <br/>
          Type <i>'confirm delete {proj_name}'</i> into the textbox below if you are certain you want to proceed with deletion.
          <br/>
          <br/>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={"confirm delete " + proj_name}
          onChange={e=> {
          if (e.target.value === "confirm delete " + proj_name) setConfirmed(true);
          else (setConfirmed(false));
        }}></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProject} variant="contained" disabled={!confirmed}>Confirm</Button>
          <Button onClick={() => {
            setOpen(!open)
            handleClose()
          }}>Cancel</Button>
        </DialogActions>
      </Dialog>
      { checkPerms(['SLT', 'Admin', 'Dev']) &&
      <MenuItem onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Delete/>
        </ListItemIcon>
        Delete project
      </MenuItem>
      }
    </>
  )
}
