/**
 * Profile page
 *
 * Display all the details of the currently logged in user
 * Conveniently contains all the information of things the user have ordered
 */

import React from 'react'
import PageDrawer from '../Components/PageDrawer'
import CenterContainer from '../Components/CenterContainer'
import {
    Alert,
    Autocomplete,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import configData from '../config.js'

// Row Component for the table
const AssignmentRow = data => {
    const cardData = data.data
    let bgcolor
    switch (cardData.bom_type) {
        case 'Installation':
            bgcolor = '#DAE8FC'
            break
        case 'Assembly':
            bgcolor = '#F8CECC'
            break
        case 'Component':
            bgcolor = '#FFF2CC'
            break
        default:
            bgcolor = 'white'
            break
    }
    return (
        <TableRow sx={{ backgroundColor: bgcolor }}>
            <TableCell align='center'>{cardData.id}</TableCell>
            <TableCell align='right'>{cardData.name}</TableCell>
            <TableCell align='right'>{cardData.bom_type}</TableCell>
            <TableCell align='right'>{cardData.parent_id}</TableCell>
            <TableCell align='center'>{cardData.assign_qty}</TableCell>
            <TableCell align='center'>
                <Button
                    variant='contained'
                    onClick={() => {
                        alert('In Progress')
                    }}
                >
                    Info
                </Button>
            </TableCell>
        </TableRow>
    )
}

const PartAssignment = () => {
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const [partData, setPartData] = React.useState([])
    const [assignments, setAssignments] = React.useState([])
    const [error, setError] = React.useState('')
    const { vehicle_id } = useParams()
    let searchId = ''

    const getPartData = () => {
        axiosInstance
            .get(`${configData.BACKEND_ROOT}/parts/${vehicle_id}/vehicles`)
            .then(res => {
                //   console.log('data from get request is: ', res.data)
                const data = res.data.map(part => {
                    return { label: part.name, id: part.id }
                })
                setPartData(data)
            })
            .catch(err => console.log('Err: ', err))
    }

    const getAssignments = async id => {
        const res = await axiosInstance.get(
            `${configData.BACKEND_ROOT}/getPartHierarchy/${id}/${vehicle_id}`
        )
        setAssignments(res.data)
    }

    React.useEffect(() => {
        getAssignments(`${vehicle_id}_001`)
        getPartData()
    }, [])

    const handleSearch = async () => {
        try {
            let url
            if (searchId !== '') {
                url = `${configData.BACKEND_ROOT}/getPartHierarchy/${searchId}/${vehicle_id}`
            } else {
                url = `${configData.BACKEND_ROOT}/getPartHierarchy/${vehicle_id}_001/${vehicle_id}`
            }
            const res = await axiosInstance.get(url)
            setError('')
            setAssignments(res.data)
        } catch (err) {
            setError(err.response.data.detail)
        }
    }

    return (
        <PageDrawer page='Part Assignment' opTool opLogin id={vehicle_id}>
            <CenterContainer size='100vw'>
                <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    justifyContent='center'
                    sx={{ margin: '1em', maxWidth: '90vw' }}
                    rowSpacing={2}
                >
                    <Grid item xs={10}>
                        {/* <TextField
                            fullWidth
                            label="Part Filter"
                            onChange={(e)=>{
                                searchId = e.target.value
                            }}
                        /> */}

                        <Autocomplete
                            label='Looking for specific details'
                            disablePortal
                            options={partData}
                            fullWidth
                            // defaultValue={locationData[0]}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label='Part'
                                    defaultValue=''
                                />
                            )}
                            onChange={(e, value) => {
                                searchId = value.id
                            }}
                            // onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {error !== '' ? (
                            <Alert severity='error'>ERROR — {error}</Alert>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ maxWidth: '90vw' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>
                                    Part Number
                                </TableCell>
                                <TableCell align='right'>
                                    Part Name
                                </TableCell>
                                <TableCell align='right'>
                                    Part BOM Type
                                </TableCell>
                                <TableCell align='right'>
                                    Parent Part
                                </TableCell>
                                <TableCell align='center'>
                                    Assignment Quantity
                                </TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignments.map((data, id) => {
                                return (
                                    <AssignmentRow key={id} data={data} />
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CenterContainer>
        </PageDrawer>
    )
}

export default PartAssignment
