/**
 * AddModals.jsx
 *
 * Collection of modals which are used to add data into the database via the backend
 *
 * Parameters:
 * - open: Boolean of modal "open" state
 * - closeFunc: The function which close the modal
 * - state: The dataset/array which is to be updated locally on success
 * - stateUpdate: The useState setState function used to update the local state
 */

import React from 'react'
import axios from 'axios'
import configData from '../../config.js'

import * as yup from 'yup'
import { useFormik } from 'formik'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Alert,
    Divider,
    Autocomplete,
} from '@mui/material'


//Supplier Add Modal Component
export const AddSupplierModal = ({ open, closeFunc, state, stateUpdate }) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });
    const validationSchema = yup.object({
        id: yup
          .string('Enter supplier id')
          .matches(
            '(^SUPP_[0-9]+$|None)',
            'Have to match the format: SUPP_[number] or "None"'
          )
          .required('Supplier id required'),
        name: yup
          .string('Enter supplier name')
          .required('Supplier name required'),
        contact_num: yup
          .string('Enter supplier contact')
          .required('Supplier contact number required'),
        supp_email: yup
          .string('Enter supplier email')
          .required('Supplier email required'),
        comments: yup
          .string('Enter any descriptions/comments')
          .required('Supplier comments required'),
    })

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            contact_num: '',
            supp_email: '',
            comments: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleAddSupplier(values)
        },
    })

    //Supplier Modal Error Flag
    const [error, setError] = React.useState('')

    //Handle Adding Supplier to Database
    const handleAddSupplier = async (values) => {
        const new_supp = {
            id: values.id,
            name: values.name,
            comments: values.comments,
            contact_num: values.contact_num,
            supp_email: values.supp_email,
        }

        try {
            //Add new supplier to database
            const res = await axiosInstance.post(
              `${configData.BACKEND_ROOT}/suppliers`,
              new_supp
            )

            //On success, add supplier object locally
            stateUpdate([...state, new_supp])
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    return (
      <>
          <Dialog open={open} onClose={closeFunc} fullWidth>
              <DialogTitle>New Supplier</DialogTitle>
              <form onSubmit={formik.handleSubmit}>
                  <DialogContent>
                      <DialogContentText>
                          Please input the details of the new supplier
                      </DialogContentText>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: '1em' }}
                      >
                          <TextField
                            fullWidth
                            id="id"
                            name="id"
                            label="Supplier ID"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.id &&
                              Boolean(formik.errors.id)
                            }
                            helperText={
                              formik.touched.id && formik.errors.id
                            }
                          />
                          <TextField
                            fullWidth
                            id="name"
                            label="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.name &&
                              Boolean(formik.errors.name)
                            }
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                          />
                          <TextField
                            fullWidth
                            id="contact_num"
                            name="contact_num"
                            label="Contact Number"
                            value={formik.values.contact}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.contact_num &&
                              Boolean(formik.errors.contact_num)
                            }
                            helperText={
                              formik.touched.contact_num &&
                              formik.errors.contact_num
                            }
                          />
                          <TextField
                            fullWidth
                            // required
                            id="supp_email"
                            name="supp_email"
                            label="Email Address"
                            value={formik.values.supp_email}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.supp_email &&
                              Boolean(formik.errors.supp_email)
                            }
                            helperText={
                              formik.touched.supp_email &&
                              formik.errors.supp_email
                            }
                          />
                          <TextField
                            placeholder="Comments"
                            multiline
                            id="comments"
                            name="comments"
                            label="Comments"
                            rows={10}
                            value={formik.values.comments}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.comments &&
                              Boolean(formik.errors.comments)
                            }
                            helperText={
                              formik.touched.comments &&
                              formik.errors.comments
                            }
                          />
                          {error === '' ? (
                            <></>
                          ) : (
                            <Alert severity="error">ERROR — {error}</Alert>
                          )}
                      </Stack>
                  </DialogContent>
                  <DialogActions>
                      <Button variant="contained" type="submit">
                          Add Supplier
                      </Button>
                      <Button variant="contained" onClick={closeFunc}>
                          Cancel
                      </Button>
                  </DialogActions>
              </form>
          </Dialog>
      </>
    )
}

//Material Add Modal Component
export const AddMaterialModal = ({ open, closeFunc, state, stateUpdate }) => {
    //Material fields
    const validationSchema = yup.object({
        id: yup.string('Enter your id').required('ID is required'),
        name: yup
          .string('Enter material name')
          .required('Material name is required'),
        desc: yup
          .string('Enter material description')
          .required('Material name is required'),
    })

    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    //Material Error flag
    const [error, setError] = React.useState('')

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            desc: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleAddMaterial(values)
        },
    })

    //Handle adding Material to database
    const handleAddMaterial = async (values) => {
        const new_matr = {
            id: values.id,
            name: values.name,
            desc: values.desc,
        }
        try {
            //Add material to database
            const res = await axiosInstance.post(
              `${configData.BACKEND_ROOT}/materials`,
              new_matr
            )
            //On success, add material object locally
            stateUpdate([...state, new_matr])
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    return (
      <>
          <Dialog open={open} onClose={closeFunc} fullWidth>
              <DialogTitle>New Material</DialogTitle>
              <form onSubmit={formik.handleSubmit}>
                  <DialogContent>
                      <DialogContentText>
                          Please input the details of the new material
                      </DialogContentText>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: '1em' }}
                      >
                          <TextField
                            fullWidth
                            id="id"
                            name="id"
                            label="Material ID"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.id &&
                              Boolean(formik.errors.id)
                            }
                            helperText={
                              formik.touched.id && formik.errors.id
                            }
                          />
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Material Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.name &&
                              Boolean(formik.errors.name)
                            }
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                          />
                          <TextField
                            fullWidth
                            id="desc"
                            name="desc"
                            label="Material Properties"
                            value={formik.values.desc}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.desc &&
                              Boolean(formik.errors.desc)
                            }
                            helperText={
                              formik.touched.desc && formik.errors.desc
                            }
                          />
                          {error === '' ? (
                            <></>
                          ) : (
                            <Alert severity="error">ERROR — {error}</Alert>
                          )}
                      </Stack>
                  </DialogContent>
                  <DialogActions>
                      <Button variant="contained" type="submit">
                          Add New Material
                      </Button>
                      <Button variant="contained" onClick={closeFunc}>
                          Cancel
                      </Button>
                  </DialogActions>
              </form>
          </Dialog>
      </>
    )
}

//Location Add Modal Component
export const AddLocationModal = ({ open, closeFunc, state, stateUpdate }) => {
    //Location fields
    const validationSchema = yup.object({
        id: yup.string('Enter location id').required('Location id required'),
        name: yup.string('Enter location name').required('Location name'),
        desc: yup
          .string('Enter location description')
          .required('Location desc'),
    })

    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            desc: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleAddLocation(values)
        },
    })

    //Location error flag
    const [error, setError] = React.useState('')

    //Handles adding new location
    const handleAddLocation = async (values) => {
        const new_loc = {
            id: values.id,
            name: values.name,
            desc: values.desc,
        }

        try {
            //Add locations to database
            const res = await axiosInstance.post(
              `${configData.BACKEND_ROOT}/locations`,
              new_loc
            )

            //On success, add location object locally
            stateUpdate([...state, new_loc])
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    return (
      <>
          <Dialog open={open} onClose={closeFunc} fullWidth>
              <DialogTitle>New Location</DialogTitle>
              <form onSubmit={formik.handleSubmit}>
                  <DialogContent>
                      <DialogContentText>
                          Please input the details of the new location
                      </DialogContentText>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: '1em' }}
                      >
                          <TextField
                            fullWidth
                            id="id"
                            name="id"
                            label="Location ID"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.id &&
                              Boolean(formik.errors.id)
                            }
                            helperText={
                              formik.touched.id && formik.errors.id
                            }
                          />
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Location Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.name &&
                              Boolean(formik.errors.name)
                            }
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                          />
                          <TextField
                            placeholder="Description"
                            multiline
                            rows={10}
                            id="desc"
                            name="desc"
                            label="Location description"
                            value={formik.values.desc}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.desc &&
                              Boolean(formik.errors.desc)
                            }
                            helperText={
                              formik.touched.desc && formik.errors.desc
                            }
                          />
                          {error === '' ? (
                            <></>
                          ) : (
                            <Alert severity="error">ERROR — {error}</Alert>
                          )}
                      </Stack>
                  </DialogContent>
                  <DialogActions>
                      <Button variant="contained" type="submit">
                          Add Location
                      </Button>
                      <Button variant="contained" onClick={closeFunc}>
                          Cancel
                      </Button>
                  </DialogActions>
              </form>
          </Dialog>
      </>
    )
}

//Part Add Modal Component
export const AddPartModal = ({
                                 open,
                                 closeFunc,
                                 vehicle_id,
                                 state,
                                 stateUpdate,
                             }) => {

    const [materialData, setMaterialData] = React.useState([])
    const [supplierData, setSupplierData] = React.useState([])
    const [locationData, setLocationData] = React.useState([])
    const [partData, setPartData] = React.useState([])
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    });

    const getMaterialData = () => {
        axiosInstance.get(`${configData.BACKEND_ROOT}/materials`)
          .then(res => {
              //   console.log('data from get request is: ', res.data)
              const data = res.data.map(material => {
                  return {label: material.name, id: material.id}
              })
              setMaterialData(data)
          })
          .catch(err=>console.log('Err: ', err))
    }

    const getSupplierData = () => {
        axiosInstance.get(`${configData.BACKEND_ROOT}/suppliers`)
          .then(res => {
              //   console.log('data from get request is: ', res.data)
              const data = res.data.map(supplier => {
                  return {label: supplier.name, id: supplier.id}
              })
              setSupplierData(data)
          })
          .catch(err=>console.log('Err: ', err))
    }

    const getLocationData = () => {
        axiosInstance.get(`${configData.BACKEND_ROOT}/locations`)
          .then(res => {
              //   console.log('data from get request is: ', res.data)
              const data = res.data.map(location => {
                  return {label: location.name, id: location.id}
              })
              setLocationData(data)
          })
          .catch(err=>console.log('Err: ', err))
    }

    const getPartData = () => {
        axiosInstance.get(`${configData.BACKEND_ROOT}/parts/${vehicle_id}/vehicles`)
          .then(res => {
              //   console.log('data from get request is: ', res.data)
              const data = res.data.map(part => {
                  return {label: part.name, id: part.id}
              })
              setPartData(data)
          })
          .catch(err=>console.log('Err: ', err))
    }

    React.useEffect(() => {
        getMaterialData()
        getSupplierData()
        getLocationData()
        getPartData()
    }, [])

    React.useEffect(() => {
        console.log(materialData, locationData, supplierData)
        }, [materialData, locationData, supplierData])

    const validationSchema = yup.object({
        id: yup.string('Enter part number').required('Part number required'),
        name: yup.string('Enter part name').required('Part name required'),
        bom_type: yup.string('Choose a BOM type').required('BOM Type required'),
        source: yup
          .string('Choose a source number')
          .required('Source required'),
        drawing: yup.string('Choose drawing state'),
        desc: yup
          .string('Enter part description')
          .required('Part description required'),
        loc_id: yup
          .string('Enter part location')
          .required('Part location required')
          .matches(
            '(^LOC_[0-9]+$|None)',
            'Have to match the format: LOC_[number] or "None"'
          ),
        supp_id: yup
          .string('Enter part supplier')
          .required('Part supplier required')
          .matches(
            '(^SUPP_[0-9]+$|None)',
            'Have to match the format: SUPP_[number] or "None"'
          ),
        matr_id: yup
          .string('Enter part material')
          .required('Part material required')
          .matches(
            '(^MATR_[0-9]+$|None)',
            'Have to match the format: MATR_[number] or "None"'
          ),
        cost_per_unit: yup
          .number('Enter part cost')
          .required('Part cost required'),
        stock_qty: yup
          .number('Enter part stock')
          .required('Part stock required')
          .min(0, 'Cannot be negative'),
        parent_id: yup.string('Enter parent ID').required('Part ID required'),
        assign_qty: yup
          .number('Enter quantity of part assigned to parent part')
          .required('Quantity required')
          .min(0, 'Cannot be negative'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            desc: '',
            bom_type: 'Installation',
            source: 'External',
            drawing: 'None',
            revision: 0,
            stock_qty: 0,
            loc_id: 'None',
            supp_id: 'None',
            matr_id: 'None',
            cost_per_unit: 0.0,
            vehicle_id: vehicle_id,
            id: '',
            parent_id: vehicle_id + '_001',
            assign_qty: 0,
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: (values) => {
            handleAddPart(values)
        },
    })

    //Part error flag
    const [error, setError] = React.useState('')

    const handleAddPart = async (values) => {
        console.log("AHHHHHHHH", values)
        const new_part = {
            name: values.name,
            desc: values.desc,
            bom_type: values.bom_type,
            source: values.source,
            drawing: values.drawing,
            revision: 0,
            stock_qty: values.stock_qty,
            loc_id: values.loc_id,
            supp_id: values.supp_id,
            matr_id: values.matr_id,
            cost_per_unit: values.cost_per_unit,
            vehicle_id: vehicle_id,
            id: values.id,
        }
        try {
            //Adding part to the database
            const res = await axiosInstance.post(
              `${configData.BACKEND_ROOT}/parts`,
              new_part
            )
            setError('')
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }

        const new_assignment = {
            parent_id: values.parent_id,
            child_id: values.id,
            assign_qty: values.assign_qty,
            vehicle_id: vehicle_id,
        }
        try {
            //Adding part to the database
            const res = await axiosInstance.post(
              `${configData.BACKEND_ROOT}/parts/assign`,
              new_assignment
            )
            //On success, add part object locally
            stateUpdate([...state, new_part])
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    const setLabelId = (field, id) => {
        console.log('AHHHHHHHHHH', field, id)
        formik.setFieldValue(field, id)
    }
    return (
      <>
          <Dialog open={open} onClose={closeFunc} fullWidth>
              <DialogTitle>New Part</DialogTitle>
              <form onSubmit={formik.handleSubmit}>
                  <DialogContent>
                      <DialogContentText>
                          Please input the details of the new part
                      </DialogContentText>
                      <Stack
                        direction="column"
                        spacing={2}
                        sx={{ marginTop: '1em' }}
                      >
                          <TextField
                            fullWidth
                            id="id"
                            name="id"
                            label="Part Number"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.id &&
                              Boolean(formik.errors.id)
                            }
                            helperText={
                              formik.touched.id && formik.errors.id
                            }
                          />
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Part Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.name &&
                              Boolean(formik.errors.name)
                            }
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                          />

                          <FormControl fullWidth>
                              <InputLabel>BOM Tree</InputLabel>
                              <Select
                                id="bom_type"
                                name="bom_type"
                                label="BOM Type"
                                value={formik.values.bom_type}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.bom_type &&
                                  Boolean(formik.errors.bom_type)
                                }
                                helperText={
                                  formik.touched.bom_type &&
                                  formik.errors.bom_type
                                }
                              >
                                  <MenuItem value={'Installation'}>
                                      Installation
                                  </MenuItem>
                                  <MenuItem value={'Assembly'}>
                                      Assembly
                                  </MenuItem>
                                  <MenuItem value={'Component'}>
                                      Component
                                  </MenuItem>
                              </Select>
                          </FormControl>
                          <FormControl fullWidth>
                              <InputLabel>Source</InputLabel>
                              <Select
                                id="source"
                                name="source"
                                label="Source"
                                value={formik.values.source}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.source &&
                                  Boolean(formik.errors.source)
                                }
                                helperText={
                                  formik.touched.source &&
                                  formik.errors.source
                                }
                              >
                                  <MenuItem value={'External'}>
                                      External
                                  </MenuItem>
                                  <MenuItem value={'Internal'}>
                                      Internal
                                  </MenuItem>
                              </Select>
                          </FormControl>
                          {formik.values.source === 'Internal' ? (
                            <FormControl fullWidth>
                                <InputLabel>Drawing Completed</InputLabel>
                                <Select
                                  id="drawing"
                                  name="drawing"
                                  label="Drawing"
                                  value={formik.values.drawing}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.drawing &&
                                    Boolean(formik.errors.drawing)
                                  }
                                  helperText={
                                    formik.touched.drawing &&
                                    formik.errors.drawing
                                  }
                                >
                                    <MenuItem value={'None'}>None</MenuItem>
                                    <MenuItem value={'Partial'}>
                                        Partial
                                    </MenuItem>
                                    <MenuItem value={'Complete'}>
                                        Complete
                                    </MenuItem>
                                </Select>
                            </FormControl>
                          ) : (
                            <></>
                          )}
                          <TextField
                            fullWidth
                            id="stock_qty"
                            name="stock_qty"
                            label="Stock Quantity"
                            type="number"
                            value={formik.values.stock_qty}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.stock_qty &&
                              Boolean(formik.errors.stock_qty)
                            }
                            helperText={
                              formik.touched.stock_qty &&
                              formik.errors.stock_qty
                            }
                          />
                          {/* <TextField
                                fullWidth
                                id="loc_id"
                                name="loc_id"
                                label="Location ID"
                                value={formik.values.loc_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.loc_id &&
                                    Boolean(formik.errors.loc_id)
                                }
                                helperText={
                                    formik.touched.loc_id &&
                                    formik.errors.loc_id
                                }
                            /> */}
                          <Autocomplete
                            id="loc_id"
                            name="loc_id"
                            disablePortal
                            options={locationData}
                            fullWidth
                            // defaultValue={lmcationData[0]}
                            renderInput={(params) => <TextField {...params} label="Location" defaultValue="None" />}
                            onChange={(e, value) => setLabelId('loc_id', value.id)}
                            // onChange={formik.handleChange}
                          />
                          {/* <TextField
                                fullWidth
                                id="supp_id"
                                name="supp_id"
                                label="Supplier ID"
                                value={formik.values.supp_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.supp_id &&
                                    Boolean(formik.errors.supp_id)
                                }
                                helperText={
                                    formik.touched.supp_id &&
                                    formik.errors.supp_id
                                }
                            /> */}
                          { formik.values.source === 'External' && (<Autocomplete
                            id="supp_id"
                            name="supp_id"
                            disablePortal
                            options={supplierData}
                            fullWidth
                            // defaultValue={supplierData[0]}
                            renderInput={(params) => <TextField {...params} label="Supplier" />}
                            onChange={(e, value) => setLabelId('supp_id', value.id)}
                            // onChange={formik.handleChange}
                          />) }
                          {/* <TextField
                                fullWidth
                                id="matr_id"
                                name="matr_id"
                                label="Material ID"
                                value={formik.values.matr_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.matr_id &&
                                    Boolean(formik.errors.matr_id)
                                }
                                helperText={
                                    formik.touched.matr_id &&
                                    formik.errors.matr_id
                                }
                            /> */}
                          <Autocomplete
                            id="matr_id"
                            name="matr_id"
                            disablePortal
                            options={materialData}
                            fullWidth
                            // defaultValue={materialData[0]}
                            renderInput={(params) => <TextField {...params} label="Material" />}
                            onChange={(e, value) => {
                                console.log(value)
                                setLabelId('matr_id', value.id)
                            }}
                            // onChange={formik.handleChange}
                          />
                          <TextField
                            fullWidth
                            id="cost_per_unit"
                            name="cost_per_unit"
                            label="Cost per unit"
                            type="number"
                            value={formik.values.cost_per_unit}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.cost_per_unit &&
                              Boolean(formik.errors.cost_per_unit)
                            }
                            helperText={
                              formik.touched.cost_per_unit &&
                              formik.errors.cost_per_unit
                            }
                          />
                          <TextField
                            fullWidth
                            multiline
                            rows={10}
                            id="desc"
                            name="desc"
                            label="Engineering Comments"
                            value={formik.values.desc}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.desc &&
                              Boolean(formik.errors.desc)
                            }
                            helperText={
                              formik.touched.desc && formik.errors.desc
                            }
                          />
                          <Divider />
                          {/* <TextField
                                fullWidth
                                id="parent_id"
                                name="parent_id"
                                label="Parent Part ID"
                                value={formik.values.parent_id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.parent_id &&
                                    Boolean(formik.errors.parent_id)
                                }
                                helperText={
                                    formik.touched.parent_id &&
                                    formik.errors.parent_id
                                }
                            /> */}
                          <Autocomplete
                            id="parent_id"
                            name="parent_id"
                            label="Parent Part ID"
                            disablePortal
                            options={partData}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Parent Part" />}
                            onChange={(e, value) => setLabelId('parent_id', value.id)}
                            // onChange={formik.handleChange}
                          />
                          <TextField
                            fullWidth
                            id="assign_qty"
                            name="assign_qty"
                            label="Assignment Quantity"
                            type="number"
                            value={formik.values.assign_qty}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.assign_qty &&
                              Boolean(formik.errors.assign_qty)
                            }
                            helperText={
                              formik.touched.assign_qty &&
                              formik.errors.assign_qty
                            }
                          />
                          {error === '' ? (
                            <></>
                          ) : (
                            <Alert severity="error">ERROR — {error}</Alert>
                          )}
                      </Stack>
                  </DialogContent>
                  <DialogActions>
                      {/*<Button variant="contained" type="submit">*/}
                      {/*    Add Part*/}
                      {/*</Button>*/}
                      <Button variant="contained" onClick={() => handleAddPart(formik.values)}>
                          Add Part
                      </Button>
                      <Button variant="contained" onClick={closeFunc}>
                          Cancel
                      </Button>
                  </DialogActions>
              </form>
          </Dialog>
      </>
    )
}