/**
 * Vehicle Page
 *
 * Display and manage all the vehicles currently in development
 */

import React from 'react'
import '../output.css';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    TextField,
    Alert,
} from '@mui/material'
import VehicleCard from '../Components/VehicleCard'
import { Container, Box } from '@mui/material'

import sr4 from '../Assets/sr_4.png'

import PageDrawer from '../Components/PageDrawer'

import configData from '../config.js'
import axios from 'axios'

import * as yup from 'yup'
import { useFormik } from 'formik'

const NewVehicleModal = ({ open, closeFunc, state, setState }) => {
    const [error, setError] = React.useState('')

    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const handleNewVehicle = async new_vehicle => {
        // Creates a new vehicle
        try {
            await axiosInstance.post(
                `${configData.BACKEND_ROOT}/vehicles`,
                new_vehicle
            )
            setState([...state, new_vehicle])
            setError('')
        } catch (err) {
            setError(err.response.data.detail)
        }

        // Creates a new installation for vehicle as well
        const new_part = {
            name: new_vehicle.name,
            desc: new_vehicle.desc,
            bom_type: 'Installation',
            source: 'Internal',
            drawing: 'None',
            revision: 1,
            stock_qty: 1,
            loc_id: 'None',
            supp_id: 'None',
            matr_id: 'None',
            vehicle_id: new_vehicle.id,
            cost_per_unit: 0.0,
            id: new_vehicle.id + '_001',
        }
        try {
            //Adding part to the database
            await axiosInstance.post(
                `${configData.BACKEND_ROOT}/parts/`,
                new_part
            )
            setError('')
            closeFunc()
        } catch (err) {
            //On error, set error from backend response
            setError(err.response.data.detail)
        }
    }

    const validationSchema = yup.object({
        id: yup.string('Enter vehicle ID').required('Vehicle ID is required'),
        name: yup
            .string('Enter vehicle name')
            .required('Vehicle name is required'),
        start_date: yup.date('Enter vehicle start date'),
        desc: yup
            .string('Enter vehicle description')
            .required('Vehicle description is required'),
    })

    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
            start_date: '',
            desc: '',
        },
        validationSchema: validationSchema,
        validateOnChange: false,
        onSubmit: values => {
            handleNewVehicle(values)
        },
    })

    return (
        <>
            <Dialog open={open} onClose={closeFunc} fullWidth>
                <DialogTitle>New Project</DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>
                            Please input the details of the new project
                        </DialogContentText>

                        <Stack
                            direction='column'
                            spacing={2}
                            sx={{ marginTop: '1em' }}
                        >
                            <TextField
                                fullWidth
                                id='id'
                                name='id'
                                label='Vehicle ID'
                                value={formik.values.id}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.id &&
                                    Boolean(formik.errors.id)
                                }
                                helperText={
                                    formik.touched.id && formik.errors.id
                                }
                            />
                            <TextField
                                fullWidth
                                id='name'
                                name='name'
                                label='Vehicle Name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                            <TextField
                                fullWidth
                                id='start_date'
                                name='start_date'
                                label='Start Date'
                                type='date'
                                value={formik.values.start_date || new Date().toISOString().split('T')[0]}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.start_date &&
                                    Boolean(formik.errors.start_date)
                                }
                                helperText={
                                    formik.touched.start_date &&
                                    formik.errors.start_date
                                }
                            />
                            <TextField
                                fullWidth
                                id='desc'
                                name='desc'
                                label='Description'
                                value={formik.values.desc}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.desc &&
                                    Boolean(formik.errors.desc)
                                }
                                helperText={
                                    formik.touched.desc && formik.errors.desc
                                }
                                multiline
                                rows={10}
                            />
                            {error === '' ? (
                                <></>
                            ) : (
                                <Alert severity='error'>ERROR — {error}</Alert>
                            )}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' type='submit'>
                            Create New Project
                        </Button>
                        <Button variant='contained' onClick={closeFunc}>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

const Vehicles = () => {
    const [modalOpen, setModalOpen] = React.useState(false)
    const axiosInstance = axios.create({
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
    })

    const handleModal = () => {
        setModalOpen(!modalOpen)
    }

    const [vehicles, setVehicles] = React.useState([])

    const getVehicles = async () => {
        const res = await axiosInstance.get(
            `${configData.BACKEND_ROOT}/vehicles`
        )
        const data = res.data
        setVehicles(data)
    }

    React.useEffect(() => {
        getVehicles()
    }, [])

    return (
        <>
            <PageDrawer opLogin>
                <NewVehicleModal
                    open={modalOpen}
                    closeFunc={handleModal}
                    state={vehicles}
                    setState={setVehicles}
                />
                <Container maxWidth='lg'>
                    <Box
                        sx={{ margin: '3vw 0vw 3vw 0vw', display: 'block' }}
                    >
                        <Box className='flex gap-3 justify-center items-center flex-col md:flex-row mt-[75px] sm:mt-[100px]'>
                            {vehicles.map((vehicle, idx) => {
                                return (
                                    <Grid item xs={4} sx={{ maxWidth: '100vw' }}>
                                        <VehicleCard
                                            id={vehicle.id}
                                            title={vehicle.name}
                                            desc={vehicle.desc}
                                            img={sr4}
                                        />
                                    </Grid>
                                )
                            })}
                            <Button
                                variant='contained'
                                onClick={handleModal}
                                className='h-[50px] w-[50px]'
                            >
                                <h1 style={{ margin: 0 }}>+</h1>
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </PageDrawer>
        </>
    )
}

export default Vehicles

// Move projects into TEST.jsx
