import React from 'react'

import { Navigate } from 'react-router-dom'
import axios from 'axios'
import configData from '../config.js'

export const isValidId = async (zid) => {
    const axiosInstance = axios.create( {
        headers: {
            Authorization : `Bearer ${sessionStorage.getItem("access_token")}`
        }
    })

    const res = await axiosInstance.get(`${configData.BACKEND_ROOT}/members/${zid}`)

    if (res.status !== 200) {
        return false
    }
    return true
}

const ProtectedRoute = ({ children }) => {
    const curr_zid = sessionStorage.getItem('curr_zid')
    if ((curr_zid === null) || !isValidId(curr_zid) || (sessionStorage.getItem('access_token') == null)) {
        return <Navigate to="/Login" replace />
    }
    return children
}

export default ProtectedRoute
